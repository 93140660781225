app.service('productPromotionsListModal', function (modal, loading) {

	function show(productId, outletCategoryId) {
		load(productId, outletCategoryId);
	}

	function load(productId, outletCategoryId) {
		loading.start();
		fetchPromotionsList(productId, outletCategoryId)
			.then(html => loadSuccess(html))
			.fail(() => modal.alert('Błąd pobierania modala z listą promocji.'))
			.always(loading.stop);
	}

	function loadSuccess(html) {
		modal.generate({
			id: 'promotions-list-modal',
			content: html
		});
	}

	function fetchPromotionsList(productId, outletCategoryId) {
		return $.ajax({
			url: '/web-cached/cms-promotion-blocks.ltr',
			data: {
				productId,
				outletCategoryId
			}
		});
	}

	return {
		show
	};
});