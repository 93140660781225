app.service('savedShopsManager', function(console) {
	var savedShops;
	
	function selectShops(){
    		if (savedShops === undefined) getShops();
		
		var $productStocksFilter=$('#filter-stocks');
		var $clearSelectedShops = $('.js-clear-selcted-shops', $productStocksFilter);
		var $productStocksFilterItem=$('.js-filter-stocks-shop', $productStocksFilter);

    		if( savedShops.length !== 0 ){
    			$.each(savedShops , function(i, val) {
    				var inputIns = $productStocksFilterItem.filter(".js-filter-stocks-shop-" + val).find("input");
    				if( inputIns.length ){
    					inputIns.prop("checked", true);
					$clearSelectedShops.addClass("visible");
    				}
    			});
    		}
    	}
	
	function showShops(productId){ 
		if (savedShops === undefined) getShops();
		
		if( savedShops.length !== 0 ){
			$.each(savedShops , function(i, val) {
				checkStock( savedShops[i], productId  );
			});
		}
	}
	
	function getShops(){
		savedShops = JSON.parse(localStorage.getItem('rememberShops')) || [];
	}
	
	function checkStock(shopId, productId){
		var $productBlock = $('#products .best-voucher[data-id="' + productId + '"]' );
		$productBlock.find('.product-stocks-box .js-stocks-shop-'+shopId).addClass('visible');
		$productBlock.find(".js-stock-city").addClass('hidden');
	}

	return {
		show:showShops,
		select:selectShops
	};
});
