/**
 * Moduł obsługuje formularz powiadomienia o dostępności produktu
 * @type module
 */
app.service('availabilityNotification', function (loading, modal, validate, captchaService) {

	function send($form){
		const product = $form.data('product');
		const email = $form.find('input[type=email]').val();
		const captcha = $form.find('.g-recaptcha-response').val();
		const place = $form.find('[data-toggle="captcha"]').data('place');
		sendData(product, email, captcha)
			.then(() => {
				$form.resetForm();
				captchaService.hidePlace(place);
				captchaService.init();
			});
	}

	function sendData(productId, email, captcha) {
		loading.start();
		return $.ajax({
			url: '/notify-product-availability.ltr',
			type: 'POST',
			data: {
				productId,
				email,
				"g-recaptcha-response": captcha
			},
			success: function(json) {
				UA.pageview('/akcje/zapisano-na-powiadomienie/premiera-produktu');
				modal.alert(json.messages[0]);
			},
			error:function(){
				modal.alert('Wystąpił problem z zapisem na powiadomienie. Proszę spróbować później.');
			},
			complete:function(){
				loading.stop();
			}
		});
	}

	function validateAvailabilityNotificationForm() {
		$('.js-product-availability-form').each(function (_, element) {
			validate.task('availabilityNotificationForm', {
				form: $(element),
				callback: function (form) {
					const $form = $(form);
					send($form);
				}
			});
		})
	}

	function init() {
		validateAvailabilityNotificationForm();
	}

	return {
		init: init,
		sendData,
		validateAvailabilityNotificationForm
	};
});
