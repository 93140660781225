app.service('filterBar', function() {

	function init() {
		
	}

	return {
		init: init
	};

});
