/**
 * Moduł obsługujący schowek, ustawia i usuwa cookies, zapewnia API dla modułu
 * /euro/app-modules/clipboardProducts.js
 * @type module
 */
app.service('productClipboard',function(cookie, console, loading, callback, front, log, account, productInteractionListener, modal) {
	var $clipboardButtons;
	var maxClipboardProductsCount = 150;
	var cookieName=(front.euro || front.katalog)?'euroClipboardProducts':'oleClipboardProducts';

	function setElements(){
		$clipboardButtons = $('.js-add-to-clipboard');
	}
	function setEvents(){

	}

	function getElements() {
		var elements=[];
		if(!cookie.isEmpty(cookieName)){
			var cookieTmp=cookie.split(cookieName);
			var updateCookie = false;
			$(cookieTmp).each(function(i,n){
				// pomijanie elementów które są zestawami
				if(n.indexOf('s') > -1){
					updateCookie = true;
				} else {
					elements.push(n);
				}
			});
			if(updateCookie){
				save(elements);
			}
		}
		return elements;
	}
	function addElement(element){
		var elements=getElements();
		var timestamp=new Date().getTime();
		var elementWithTimestamp=element+':'+timestamp;
		elements.push(elementWithTimestamp);
		changeClass(element, true);
		save(elements);
		if(!front.katalog){
			synchronize();
		}
	}
	function remove($element){
		removeElement(findData($element),true);
	}
	function removeElement(element,reload){
		var elements=getElements();
		var elementsData=[];
		elements.forEach((item)=> {
			var itemId = item.split(':')[0];
			var itemTimestamp = item.split(':')[1];
			elementsData.push({
				id: itemId,
				timestamp: itemTimestamp
			});
		});
		var index = elementsData.findIndex(el => el.id === element);
		if(index>-1){
			elements.splice(index,1);
			changeClass(element, false);
			save(elements);
			sendRemove([element],reload);
			console.log('usunięto '+element);
		}else{
			console.log('nieudana próba usunięcia produktu: ' +element);
		}
	}
	function clearAll(){
		save([]);
	}
	function sendRemove(list,reload){
		if(reload){
			loading.start();
		}
		$.ajax({
			url:'/watched-products-remove.ltr',
			method:'POST',
			data:{
				products:list
			},
			success:function(){
				if(reload){
					loading.reload();
				}
			}
		});
	}
	function synchronize(){
		$.ajax({
			url:'/clipboard-synchronize.ltr'
		});
	}
	function save(elements){
		if(elements.length){
			cookie.set(cookieName, elements.join("::"),{path:'/', expires:30});
		}else{
			cookie.removeGlobal(cookieName);
		}
	}
	function findData($element){
		return $element.data('product').toString();
	}
	function changeClass(data,add){
		var product=data.toString();
		var $inputs=$clipboardButtons.filter('[data-product="'+product+'"]');

		if(add){
			$inputs.addClass('clipboard-button-active');
		}else{
			$inputs.removeClass('clipboard-button-active');
		}
	}
	function click($element) {
		var plu = $element.data('plu');
		var productId = $element.data('product');
		var element=findData($element);
		console.log('KLIK - PRODUKT DO OBSERWOWANYCH - '+element);
		var elements=getElements(); // tablica stringów w postaci ["productId:timestamp"]
		if (elements.length > maxClipboardProductsCount) {
			modal.generate({
				id: 'max-clipboard-products-message',
				header: 'Osiągnięto maksymalną liczbę produktów w obserwowanych.',
				content: `<p>W obserwowanych znajduje się już ${elements.length} produktów.</p>
					<p>Jeżeli chcesz dodać kolejny przejdź do obserwowanych i usuń niepotrzebne produkty.</p>`,
				buttons: [
					{
						text: "Przejdź do obserwowanych",
						cssClass: 'button-next',
						click: function () {
							loading.redirect('/konto-sledzone-produkty.bhtml');
						}
					},
					{
						text: "Wróć",
						cssClass: 'button-back',
						click: function () {
							modal.close();
						}
					}
				]
			});
			return;
		}
		if((elements.findIndex(el => el.split(':')[0] == element))==-1){
			productInteractionListener.onProductAddToClipboard(productId);
			addElement(element);
			UA.pageInteraction('Schowek', 'Do Schowka');
			log.event({
				id: 'add-to-clipboard',
				f03: plu,
				f04: front.page(),
				f05: front.katalog?'':account.user().frontUserLogged.toString(),
				f06: window.location.href
			});
			log.recommendationsForAWS({
				productPlu: plu,
				eventCategory: 'Schowek',
				eventAction: 'Do Schowka'
			});
			if(front.page() === "leaf"){
				UA.doubleClick(plu,'dc-clipboard-ls');
			}else if(front.page() === "product-card"){
				UA.doubleClick(plu,'dc-clipboard-kp');
			}

		}else{
			removeElement(element,false);
			UA.pageInteraction('Schowek', 'Usuń ze Schowka');
		}
	}

	function checkClipboard(){
		setElements();
		var elements=getElements();
		$clipboardButtons.each(function(i,element){
			var data=findData($(element));
			changeClass(data, elements.findIndex(el => el.split(':')[0] == data) > -1);
		});
	}
	function init() {
		setElements();
		setEvents();
		checkClipboard();

		$(document).on('pageChange',function(){
			checkClipboard();
		});
	}
	return {
		init,
		click,
		remove,
		getElements,
		checkClipboard,
		clearAll
	};
});
