app.service('searchRelatedKeywordsCarousel', function (_, searchRelatedKeywordsApi, productsListConfig) {

	let $self;
	let $carousel;
	let $navigation;

	let frameWidth = 0;
	let innerElementsWidth = 0;
	let currentSlide = 0;

	function init() {

		const {keyword} = productsListConfig.getData();
		if (!keyword) return;

		setElements();
		setEvents();

		loadSearchRelatedKeywords(keyword)
			.then(response => {

				if (response.isEmpty) {
					$self.addClass('is-hidden');
					return;
				}

				$carousel.prepend(response.html);
				setupCarousel();
				render();
				setUA();
			});
	}

	function loadSearchRelatedKeywords(keyword) {

		return searchRelatedKeywordsApi.get(keyword)
			.then(response => ({
				isEmpty: response.trim() === "",
				html: response
			}))
			.fail(() => {
				$self.addClass('is-hidden');
				console.error('[searchRelatedKeywordsCarousel] Błąd ładowania danych.');
			})
			.always(() => {
				$self.removeClass('loading');
			});
	}

	function setElements() {
		$self = $('#search-related-keywords');
		$carousel = $self.find('.carousel');
		$navigation = $('#search-related-keywords-nav');
	}

	function setEvents() {

		$(window).resize(_.debounce(render, 250));

		$navigation
			.on('click', '.prev', function handleCarouselPrevClick() {
				prev();
			})
			.on('click', '.next', function handleCarouselNextClick() {
				next();
			});
	}

	function setupCarousel() {
		let $frame = $carousel.find('ul');
		frameWidth = $frame.width();
		innerElementsWidth = $frame.children()
			.map((i, el) => $(el).width())
			.toArray()
			.reduce((total, width) => total + width, 0);
	}

	function next() {
		currentSlide = currentSlide + 1;
		render();
	}

	function prev() {
		currentSlide = currentSlide - 1;
		render();
	}

	function render() {

		let $frame = $carousel.find('ul');

		setupCarousel();

		if (innerElementsWidth <= frameWidth) { // elementy mieszczą się na widoku
			resetCarousel($frame);
			return;
		}

		const isLast = (currentSlide + 1) * frameWidth >= innerElementsWidth;
		const offset = getOffset(isLast);

		$navigation.find('.prev').attr('hidden', currentSlide === 0);
		$navigation.find('.next').attr('hidden', isLast);

		$frame.css('transform', `translate3d(${offset}px, 0, 0)`);
	}

	function resetCarousel($frame) {
		currentSlide = 0;
		$frame.css('transform', `translate3d(0, 0, 0)`);
		$navigation.find('.prev').attr('hidden', true);
		$navigation.find('.next').attr('hidden', true);
	}

	function getOffset(isLast) {

		const offset = parseInt(-1 * currentSlide * frameWidth);

		if (!isLast) {
			return offset;
		}

		const prevOffset = parseInt(-1 * (currentSlide - 1) * frameWidth);
		return -1 * Math.ceil(innerElementsWidth + offset) + prevOffset;
	}

	function setUA() {

		$self.on('click', 'a', function () {
			UA.searchInteraction('Wyniki wyszukiwania', 'Klienci szukali rowniez', 'Klikniecie', $(this).text().trim());
		});
	}

	return {
		init
	};
});