/**
 * Obsługa belki ostatnio oglądane
 * dodawanie, usuwanie, aktualizacja licznika
 */
app.service('lastProducts',function(cookie, html, console, loading){
	var $self, $counts, $showMore, $lastProductsList, $lastProductsListLis, $lastWatchedWrapper, $lastWatchedPopup,$modal, $simpleList,
		lastWatchedLimit=3,
		simpleProducts = [], detailedProducts = [];

	function setElements() {
		$self = $('#last-watched');
		$counts = $('.js-last-watched-count');
		$simpleList = $('#last-watched-list', $self);
		$showMore = $('.js-show-more', $self);
		$lastProductsList = $('#last-products-list');
		$lastWatchedWrapper = $('#last-watched-wrapper');
		$lastWatchedPopup = $('#last-watched-modal');
		$modal=$lastWatchedPopup.remodal();
	}

	function loadSimpleSuccess(index, html) {
		var products = getProducts();
		console.log('lastProducts.loadSimpleSuccess: '+products[index]);

		$('li',$simpleList).eq(index).html(html);

		checkRequestContent(html, products[index]);
	}

	function loadDetailedSuccess(index, html) {
		var products = getProducts();

		$lastProductsListLis.eq(index).html(html).data('product', products[index]);

		checkRequestContent(html, products[index]);
	}

	function openModal() {
		var products = getProducts();

		renderLastProductsList();

		$(products).each(function (index, productId) {
			loadDetailed(index, productId);
		});

		$modal.open();
	}

	function closeModal() {
		$modal.close();
	}

	function addEvents() {
		$showMore.click(function () {
			UA.interaction('Belka', 'Ostatnio oglądane', 'Więcej');
			openModal();
		});
		$lastWatchedPopup.on('click', '.js-add-to-clipboard' , function () {
			UA.interaction('Belka', 'Warstwa Ostatnio oglądane', 'Do schowka');
		});
		$lastWatchedPopup.on('click', '.js-delete-product' , function () {
			UA.interaction('Belka', 'Warstwa Ostatnio oglądane', 'Usuń');
			deleteProduct($(this).data('product'));
		});
		$lastWatchedPopup.on('click', '.js-show-more' , function () {
			UA.interaction('Belka', 'Warstwa Ostatnio oglądane', 'Przejdź do Karty Produktu');
		});
		$lastWatchedPopup.on('click', '.js-show-as-listing' , function () {
			UA.interaction('Belka', 'Warstwa Ostatnio oglądane', 'Pokaż jako listing');
		});
		$lastWatchedPopup.on('click', '.note a' , function () {
			UA.interaction('Belka', 'Warstwa Ostatnio oglądane', 'Zarejestruj się');
		});
	}
	function clear(){
		loading.start();
		$.ajax({
			url: '/last-viewed-products-updater.ltr',
			method:'POST',
			data:{
				action: 'removeAll'
			}, complete: function () {
				loading.stop();
				deleteSuccess();
			}
		});
	}
	function checkRequestContent(html, productId) {
		if (!$.trim(html).length) {
			deleteProduct(productId);
		}
	}

	function loadDetailed(index, productId) {
		if (productId in detailedProducts) {
			loadDetailedSuccess(index, detailedProducts[productId]);
		} else {
			$.ajax({
				url: '/products-bottom-bar.ltr',
				data: {
					productId: productId,
					detailed: true
				},
				success: function (result) {
					detailedProducts[productId] = result;

					loadDetailedSuccess(index, result);
				}
			});
		}
	}

	function loadSimple(index, productId) {
		if (productId in simpleProducts) {
			loadSimpleSuccess(index, simpleProducts[productId]);
		} else {
			$.ajax({
				url: '/products-bottom-bar.ltr',
				data:{
					productId: productId
				},
				success: function(result){
					simpleProducts[productId] = result;

					loadSimpleSuccess(index, result);
				}
			});
		}
	}

	function deleteProduct(productNumber) {
		if ($lastProductsListLis) {
			$lastProductsListLis.filter(function() {
				return $(this).data("product") == productNumber;
			}).addClass('deleting');
		}

		$.ajax({
			url: '/last-viewed-products-updater.ltr',
			method:'POST',
			data:{
				ids: productNumber,
				action: 'remove'
			}, success: function () {
				deleteSuccess();
			}
		});
	}

	function deleteSuccess() {
		updateCount();

		var products = getProducts();

		if (!products.length) {
			closeModal();
			html.remove('bottom-bar-visible');
		}

		renderSimpleProducts();
	}

	function updateCount() {
		var products = getProducts();
		$counts.text(products.length);
	}

	function getProducts() {
		var cookieString = cookie.get('lastViewedProducts'); //product-top

		if(!cookie.isEmpty('lastViewedProducts')) {
			var products = cookieString.split('a');
			var actualProductId = $('#product-top').data('product') || false;

			if (actualProductId) {
				var actualProductIndex = products.indexOf(String(actualProductId));
				products.splice(actualProductIndex, 1);
			}

			return products;
		} else {
			return [];
		}
	}

	function renderSimpleProducts() {
		var products = getProducts();

		$simpleList.html('');

		$(products.slice(0,lastWatchedLimit)).each(function (index, productId) {
			$simpleList.append('<li class="js-UA-product"></li>');
			loadSimple(index, productId);
		});

		if (!products.length) {
			$self.removeClass('visible');
		}
	}

	function renderLastProductsList() {
		var products = getProducts();

		$lastProductsList.html('');

		$(products).each(function () {
			$lastProductsList.append('<li class="js-UA-product"><div class="loading"></div></li>');
		});

		$lastProductsListLis = $('li', $lastProductsList);
	}

	function getLastProducts() {
		var products = getProducts();

		if (products.length) {
			renderSimpleProducts();
			updateCount();
			$self.addClass('visible');
		}
	}

	function init() {
		setElements();
		addEvents();
		getLastProducts();
	}
	return {
		init:init,
		getProducts:getProducts,
		clear: clear
	};
});
