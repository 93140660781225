app.service('warrantyQuestionnaire',function(localStorage, pageConfig, $global){

	function clearWarrantyQuestionnaire() {
		localStorage.removeItem('warrantyQuestionnaire');
	}
	function clearProductWarrantyQuestion(plu) {
		var warrantyQuestionnaireData = getWarrantyQuestionnaire();
		var answersData = warrantyQuestionnaireData.filter(function(answer) {
			return +answer.plu !== +plu;
		});
		if(answersData.length != 0) {
			localStorage.setItem('warrantyQuestionnaire', JSON.stringify(answersData));
		}else{
			clearWarrantyQuestionnaire();
		}
	}
	function getWarrantyQuestionnaire() {
		var warrantyQuestionnaireStorage = localStorage.getItem('warrantyQuestionnaire');
		return warrantyQuestionnaireStorage !== null ? JSON.parse(warrantyQuestionnaireStorage) : [];
	}
	function setWarrantyQuestionnaire(newAnswer) {
		var warrantyQuestionnaireData = getWarrantyQuestionnaire();
		//sprawdza czy odpowiedziano wczesniej na to pytanie
		var answeredBefore = warrantyQuestionnaireData.filter(function(answer) {
			return +answer.plu === +newAnswer.plu && +answer.questionId === +newAnswer.questionId;
		});

		if(!answeredBefore) {
			//brak wczesniejszej odpowiedzi na aktualne pytanie
			warrantyQuestionnaireData.push(newAnswer);
		}else{
			//zwraca liste bez aktualnego pytania
			var withoutAnswerBefore = warrantyQuestionnaireData.filter(function(answer) {
				return answer !== answeredBefore[0];
			});
			if(withoutAnswerBefore) {
				//zapisuje nowa odpowiedz na aktualne pytanie
				withoutAnswerBefore.push(newAnswer);
				warrantyQuestionnaireData = withoutAnswerBefore;
			}
		}
		localStorage.setItem('warrantyQuestionnaire', JSON.stringify(warrantyQuestionnaireData));
	}
	function markAnswers(modalId){
		var answersData = getWarrantyQuestionnaire();
		var $questions = modalId ? $(modalId + ' .warranties-questionnaire .warranty-question:visible') : $('.warranties-questionnaire .warranty-question:visible');
		if ($questions.length) {
			$questions.each(function(){
				var $radios = $(this).find('input[type="radio"]');
				var radioProductCode = $radios.eq(0).data('product-code');
				var matchedProducts = answersData.filter(function(item) {
					return +item.plu === +radioProductCode
				});
				if(matchedProducts.length) {
					$radios.each(function(){
						var radioData = {
							questionId:$(this).data('question-id'),
							answer:$(this).val()
						};
						var match = matchedProducts.filter(function(item) {
							return +item.questionId === +radioData.questionId
							&& item.answer === radioData.answer;
						});
							$(this).prop('checked', match.length != 0);
					});
				}
			});
		}
	}
	function init(){
		$(document).on('cartRefresh',function(){
			markAnswers();
		});
		markAnswers();
		$('body').on('click', '.warranties-questionnaire:not(.oss-questionnaire) input[type="radio"]', function(){
			var newAnswer = {
				plu:$(this).data('product-code'),
				questionId:$(this).data('question-id'),
				answer:$(this).val(),
			}
			setWarrantyQuestionnaire(newAnswer);
		});
		$('body').on('click', '.warranty-question.error input[type="radio"]', function(){
			$(this).closest('.warranty-question').removeClass('error');
		});
		$(document).on('oss-set-warranty-questionnaire',function(event, newAnswer){
			setWarrantyQuestionnaire(newAnswer);
		});

	}
	$global('getWarrantyQuestionnaire', getWarrantyQuestionnaire);
	return {
		init,
		getWarrantyQuestionnaire,
		clearWarrantyQuestionnaire,
		clearProductWarrantyQuestion,
		markAnswers
	};
});
