/*
	Obsługa modala wyświetlającego terminy dostawy.
 */
app.service('modalDelivery', function (modal, stackedModal, console, loading, validate, cookie, userPreferences, modalShops, front, pageConfig, productCardTest, broker) {

	var $self, $deliveryTabs, activeTab;

	let productData = {
		productId: null,
		outletCategoryId: null
	};

	function setElements() {
		$self = $('#delivery-modal');
		$deliveryTabs=$('#delivery-tabs');
		activeTab=$deliveryTabs.data('tab');
	}

	function setEvents() {
		$('.js-change-zip-code', $self).on('click', function () {
			const productId = $(this).data('product');
			showDelivery({productId, force: true});
		});

		$('.js-delivery-calculator', $self).on('click', function () {
			activeTab=$(this).data('tab');
			$deliveryTabs.removeAttr('class').addClass('show-tab-'+activeTab);
		});

		$('.js-day-pick', $self).on('click', function () {
			var day = $(this).data('day');
			var $container = $(this).closest('.content-box');
			var $activeDay = $container.find('.day' + day);

			$container.find('.js-day-pick').removeClass('active');
			$(this).addClass('active');
			$container.find('.days-list').removeClass('active');
			$activeDay.addClass('active');

			if($activeDay.find('li.group').length){
				$container.find('.delivery-time').addClass('active');
			} else {
				$container.find('.delivery-time').removeClass('active');
			}
		});
		$('.js-add-to-cart-from-calculator', $self).on('click', function () {
			modal.close();
			const productId = $(this).data('product');
			var data = {
				productId: productId,
				delivery:activeTab
			};

			if (productCardTest.enabled) {
				broker.trigger('ADD_TO_CART_WITHOUT_INSTALMENT', [data]);
				return;
			}

			const outletCategoryId =  $(this).data('outlet-category');

			if (outletCategoryId) {
				data.outletCategoryId = outletCategoryId;
			}
			addToCartManager(data);
		});
		$('.js-change-shop', $self).on('click', function () {

			const productId = $(this).data('product');
			var zipCode = $(this).data('zip-code');

			if(productId){
				userPreferences.setShopLocation(zipCode);
				modalShops.showModal(productId, {calledFrom: 'calculator'});
			}

		});

	}

	function initCalendar() {
		$('.content-box').each(function () {
			$(this).find('.js-day-pick')[0].click();
		});
	}


	function initCityForm() {

		$.cityAutocomplete('#zip-code-delivery', '#city-name-delivery');
		$('#zip-code-delivery', $self).mask2('99-999').keyfilter(/[\d\-]/);

		$('input', $self).on('keydown', function(){
			resetForm();
		});

		validate.task('pickDeliveryCity', {
			submit: function (form) {
				submitCityForm(form);
			}
		});
	}

	function resetForm(){
		$('.validation-message', $self).remove();
	}

	function submitCityForm(form) {
		var zipCode = $(form).find('#zip-code-delivery').val();
		var cityName = $(form).find('#city-name-delivery').val();

		userPreferences.setDelivery(zipCode, cityName);
		if(productData.productId){
			showDelivery(productData);
		}
	}

	function openSuccess() {
		setElements();
		setEvents();
		initCalendar();
		initCityForm();
	}

	function open(html) {
		const modalInstance = modal.generate({
			id: 'delivery-modal',
			content: html,
			cssClass: 'delivery-modal',
			callbackOpen: function () {
				openSuccess();
			}
		});

		stackedModal.push(modalInstance);
	}

	function show(html) {
		if (modal.isExist('delivery-modal') && modal.isOpen('delivery-modal')) {
			$('.delivery-box', $self).replaceWith(html);
			openSuccess();
		} else {
			open(html);
		}
	}

	function load(action, ajaxData) {
		return $.ajax({
			url: action,
			data: ajaxData,
			beforeSend: function () {
				loading.start();
			},
			success: function (html) {
				show(html);
			},
			error: function (response) {
				console.log('load delivery info ERROR');
			},
			complete: function () {
				loading.stop();
			}
		});
	}

	function showDelivery(params) {

		productData = {
			productId: params.productId,
			outletCategoryId: params.outletCategoryId
		};

		var options = $.extend(true, {
			force: false,
			tab: null
		}, params);
		var url = '/delivery-city-selection.ltr';
		var data = {
			productId: params.productId
		};

		var cityData = userPreferences.getDelivery();

		if (cityData && !options.force) {
			data.zipCode = cityData.zipCode;
			data.cityName = cityData.cityName;
		}

		if (front.katalog) {
			data.wcShopCode = pageConfig.getData('wc').shopCode;
			data.cityName = pageConfig.getData('wc').shopPostalCity;
		}

		if(options.tab) {
			data.activeTab = options.tab;
		}

		if(options.outletCategoryId) {
			data.outletCategoryId = options.outletCategoryId;
		}

		load(url, data);
	}

	function init() {
		$('body').on('click', '.js-home-delivery', function () {

			UA.pageInteraction('CTA', 'Sprawdź termin dostawy');
			var params = {
				productId: $(this).data('product'),
				outletCategoryId: $(this).data('outlet-category')

			};
			var shopId = $(this).data('shop');

			if(shopId){
				userPreferences.setShop(shopId);
				params.tab = 'shop';
			}

			showDelivery(params);

			return false;
		});
	}

	return {
		init: init,
		showModal: showDelivery
	};
});
