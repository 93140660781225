app.service('text', function() {
    
    //sprzatanie tekstu ze znacznikow html
    function clean(text) {
        return text.replace(/(<([^>]+)>)/g, '');
    }

    return {
        clean
    }
})