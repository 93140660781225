app.service('searchRelatedKeywordsApi', function (developSearchModeTest) {

	function get(keyword) {
		return $.ajax({
			url: '/web-cached/search-suggestions/related-keywords.ltr',
			data: {
				keyword,
				developSearchMode: developSearchModeTest.isEnabled()
			}
		});
	}

	return {
		get
	};
});