/**
 * załadowanie dolnej belki i uruchomienie: ostatnio oglądanych, schowka i porównania
 */

app.service('bottomBar',function(lastProducts, html, compareProducts, clipboardProducts, front, account, console){
	var $self;
	/**
	 * Cache elementu DOM
	 */
	function setElements(){
		$self = $('#bottom-bar');
	}

	function setEvents(){

	}

	function load() {
		$.ajax({
			url: '/products-bottom-bar.ltr',
			success: function(result){
				loadSuccess(result);
			},
			error:function(){

			}
		});
	}

	function loadSuccess(result) {
		console.log('bottomBar.loadSuccess');
		$('body').append(result);
		html.add('show-bottom-bar');
		lastProducts.init();
		compareProducts.init();
		clipboardProducts.init();
		$('.js-wc-clear-lastWatched').click(function(){
			compareProducts.clear();
			lastProducts.clear();
		});
		$(window).resize();
	}
	function initOnLoad(){
		account.onLoad(function(){
			init();
		});
	}
	function init() {
		if (!$('#cart, body.authorization, #account, #wc-login, .iframe-error-form, body.order-finalize, .order.pz').length || $('#watchedForm, #deleteFormComparison').length){
			if(front.katalog || (typeof(isPrint)!=="undefined" && isPrint!='preview')){
				setElements();
				setEvents();
				load();
			}
		}
	}
	return {
		init:initOnLoad
	};
});
