app.service('message',function(modal) {

	function get(data){
		if(!data){
			reject();
		}
		if(typeof data === 'string'){
			data={
				code: data
			}
		}
		if (data.code.startsWith('msg:')) {
			data.code = data.code.substr(4, data.code.length - 4);
		}
		return new Promise(function(resolve, reject) {
			$.ajax({
				url: '/message-alert.ltr',
				data: data,
				success:function(html){
					resolve(html);
				},
				error:function(){
					modal.alert('Błąd pobierania komunikatu');
					reject();
				}
			});
		});
	}
	function alert(data, callback){
		get(data).then(function(text){
			modal.alert(text,callback);
		}).catch(function () { 
			console.log('brak komunikatu: ' + data);
			callback()
		});
	}

	return {
		get, alert
	};
});
