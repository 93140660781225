app.service('promotionBar',function(cookie, front, html, callback, sticky, localStorage){
	var id=false;
	var limit=2;
	var $self;

	function show(){
		html.add('promotion-bar-show');
		callback.run('promotion-bar-show',id);
	}
	function hide(){
		html.remove('promotion-bar-show');
		sticky.rebuild('promotionBar');
		$self.remove();
		callback.run('promotion-bar-close',id);
		localStorage.setItem('TPB'+id,100,30);
		$(window).trigger('promotion-bar-close');
	}
	function setEvents(){
		$('body').on('click','.js-close-promotion-bar' ,function(){
			hide();
		});
	}

	function setElements(){
		$self=$('#promotion-bar');
		if($self.length){
			$self.find('.item').each(function(){
				var itemId=$(this).data('id');
				if(!cookie.isEmpty('TPB'+itemId)) {  //'cookie' do usunięcia po 30 dniach od wdrożenia
					localStorage.setItem('TPB'+id,cookie.get('TPB'+itemId),30);
					cookie.remove('TPB'+itemId);
				}
				if(!id && localStorage.getItem('TPB'+itemId)<limit){
					id=itemId;
					localStorage.setItem('TPB'+id,(localStorage.getItem('TPB'+itemId)/1+1),30);
				}else if(!id && localStorage.empty('TPB'+itemId)){
					id=itemId;
					localStorage.setItem('TPB'+id,1,30);
				}else{
					$(this).remove();
				}
			});
			if(id){
				show();
			}
		}
	}

	function init() {
		setElements();
		setEvents();
	}
	return {
		init:init
	};
});
