app.service('mainPageTopSlider', function(lazyLoad, viewPoint, mainPageInteraction) {
	let $self;
	let $item = 1;
	let isInit = false;
	let $all = 1;
	let time = 10000;
	let type = '';
	let name = '';
	let timestamp = false;
	let animated = true;
	let carousels = [];
	let hoverDelay = 200;
	let hoverTimeout;

	let $buttons, $marker, $nextButton, $prevButton;

	function setItem(item, anim) {
		animated = anim;
		$item = item;
		var $element = $('.slider-item:nth-child('+item+')', $self);
		type = $element.data('type');
		name = $('.slider-button:nth-child('+item+')', $self).text().trim();
		$self.attr('data-item', $item);
		clearTimeout(timestamp);
		lazyLoad.update();
		$marker.removeClass('animated');
		$('.slider-item:nth-child('+item+') .product-inner.has-many-advertisingPlacements', $self).each(function(){
			$(this).css('min-height',$(this).height());
		});
		if(type == 'promotion'){
			initPromotionCarousel();
		}else if(type == 'category' && $('.slider-item:nth-child('+item+') .has-category-cms', $self).length){
			initCategoryCarousel(0);
		}

		if(animated){
			timestamp = setTimeout(function(){
				next();
			}, time);
			setTimeout(function(){
				$marker.addClass('animated');
			}, 50);
		}
	}
	function initPromotionCarousel(){
		let $promotions = $('.slider-item-promotion', $self);
		if(!$promotions.hasClass('init')){
			$promotions.addClass('init');
			var length = $promotions.find('.promotion-inner').length;
			if((window.innerWidth > 1230 && length > 4) || (window.innerWidth <= 1230 && length > 3)) {
				carousels[$item] = new PreviewGallery({
					selector: $promotions.find(".promotions-carousel"),
					perPage: $('.promotion-box-first', $promotions).length ? {
						0: 3.5,
						1231: 4.5
					} : {
						0: 4.5,
						1231: 5.5
					},
					navigation: $promotions.find(".promotions-carousel-nav"),
					onInit: function onInit() {
						lazyLoad.update();
					},
					onChange: function onInit() {
						lazyLoad.update();
					},
					swipe: true
				});
			}else{
				$promotions.addClass('no-carousel');
			}
		}
	}
	function initCategoryCarousel(timeout=0){
		let $box = $('.slider-item:nth-child('+$item+')', $self);
		let $categories = $('.categories-row', $box);
		if($categories.length && !$categories.hasClass('init')){
			$box.addClass('full-rotator');
			$categories.addClass('init');
			var length = $categories.find('.category-inner').length;
			var firstCategories = $('.categories-box-first', $categories).length;
			var initLength = window.innerWidth > 1230 ? 6 : 5;
			initLength = firstCategories ? initLength-1 : initLength;

			if(length >= initLength){
				setTimeout(function(){
					carousels[$item] = new PreviewGallery({
						selector: $categories.find(".categories-carousel"),
						perPage: firstCategories ? {
							0: 3.5,
							1231: 4.5
						} : {
							0: 4.5,
							1231: 5.5
						},
						navigation: $categories.find(".categories-carousel-nav"),
						onInit: function onInit() {
							lazyLoad.update();
						},
						onChange: function onInit() {
							lazyLoad.update();
						},
						swipe: true
					});
				}, timeout);
			}else{
				$categories.addClass('no-carousel');
			}
		}
	}
	function next(){
		const i = $item+1;
		setItem(i <= $all ? i : 1, animated);
	}

	function enter(){
		clearTimeout(timestamp);
		animated=false;
		$marker.removeClass('animated');
	}

	function leave(){
		setItem($item, true);
	}

	function initProductCarousel(timeout=300){
		let $box = $('.slider-item:nth-child('+$item+')', $self);
		let $products = $('.products-row', $box);
		$box.addClass('full-rotator');
		if($products.length){
			$products.addClass('init');
			setTimeout(function(){
				var length = $products.find('.product-inner').length;
				if((window.innerWidth > 1230 && length > 5) || (window.innerWidth <= 1230 && length > 4)){
					carousels[$item] = new PreviewGallery({
						selector: $products.find(".products-carousel"),
						perPage: {
							0: 4.25,
							1231: 5.5
						},
						navigation: $products.find(".products-carousel-nav"),
						onInit: function onInit() {
							lazyLoad.update();
						},
						onChange: function onInit() {
							lazyLoad.update();
						},
						swipe: true
					});
				}else{
					$products.addClass('no-carousel');
				}
			},timeout);
		}
	}
	function init() {
		$self = $('#main-page-top-slider');
		if($self.length){
			$all = $self.data('all') / 1;
			carousels = new Array($all);
			time = $self.data('time') * 1000 || time;
			$marker = $('.slider-marker', $self);
			$buttons = $('.slider-button', $self);
			$nextButton = $('.slider-next button', $self);
			$list = $('.slider-list', $self);

			$self.on('mouseenter', function(){
				enter();
			}).on('mouseleave', function(){
				leave();
			});

			$prevButton =  $('.slider-prev button', $self);

			$buttons.on('click', function() {
				let index = $(this).index() + 1;
				setItem(index, false);
			}).on('mouseenter', function() {
				let index = $(this).index() + 1;
				clearTimeout(hoverTimeout);
				hoverTimeout = setTimeout(function(){
					setItem(index, false);
				}, hoverDelay);
			}).on('mouseleave', function() {
				clearTimeout(hoverTimeout);
			});
			$nextButton.on('click', function() {
				setItem($item < $all ? $item + 1 : 1, false);
				mainPageInteraction.set('Rotator - klikniecie', 'Slider|następny');
			});
			$prevButton.on('click', function() {
				setItem($item > 1 ? $item - 1 : $all, false);
				mainPageInteraction.set('Rotator - klikniecie', 'Slider|poprzedni');
			});
			$('.products-row .js-carousel-init', $self).on('click', function() {
				mainPageInteraction.set('Rotator - klikniecie',  $item+'|'+name+'|'+type+'|Strzalka rozwiniecia' );
				initProductCarousel();
				$(this).remove();
			});
			$('.categories-row .js-carousel-init', $self).on('click', function() {
				mainPageInteraction.set('Rotator - klikniecie',  $item+'|'+name+'|'+type+'|Strzalka rozwiniecia' );
				initCategoryCarousel(300);
				$(this).remove();
			});
			$self.on('click','.redirect-url', function() {
				mainPageInteraction.set('Rotator - klikniecie',  $item+'|'+name+'|'+type+'|Zolty button' );
			})
			.on('click','.slider-image a', function() {
				mainPageInteraction.set('Rotator - klikniecie',  $item+'|'+name+'|'+type+'|Grafika' );
			})
			.on('click','.product-box', function(event) {
				if($(this).find('.product-last-box').length){
					mainPageInteraction.set('Rotator - klikniecie',  $item+'|'+name+'|'+type+'|Przewiniety CTA' );
				}else{
					mainPageInteraction.set('Rotator - klikniecie',  $item+'|'+name+'|'+type+'|Box produktu' );
				}
				let url = $(this).data('url');
				if(url && event.target.nodeName != 'A'){
					document.location.href=url;
				}
			})
			.on('click','.category-inner', function() {
				if($(this).find('.category-last-box').length){
					mainPageInteraction.set('Rotator - klikniecie',  $item+'|'+name+'|'+type+'|Przewiniety CTA' );
				}else{
					mainPageInteraction.set('Rotator - klikniecie',  $item+'|'+name+'|'+type+'|Box kategorii' );
				}
			})
			.on('click','.promotion-box', function() {
				if($(this).find('.promotion-last-box').length){
					mainPageInteraction.set('Rotator - klikniecie',  $item+'|'+name+'|'+type+'|Przewiniety CTA' );
				}else{
					mainPageInteraction.set('Rotator - klikniecie',  $item+'|'+name+'|'+type+'|Box promocji' );
				}
			});

			viewPoint.set({
				element: $self,
				height: 100,
				callback: function(){
					isInit = true;
					setItem($item, true);
				}
			})
			viewPoint.set({
				element: $self,
				height: 0.6*$self.height(),
				callback: function(){
					mainPageInteraction.set('Rotator - wyswietlenie');
				}
			});
		}
	}

	return {
		init
	};
});
