app.service('operatorPluCompare',function(console, cartBox, modal, loading, sessionStorage){
	var $self, $inputs, $basket;

	function toggleView(){
		$self.toggleClass('active');
	}

	function setElements(){
		$self = $('#operator-plu-compare');
		$inputs = $('.js-operator-plu', $self);
		$basket = $('#operator-basket', $self);
	}

	function setEvents(){
		$('.js-toggle-compare', $self).on('click',function(){
			toggleView();
		});
		$inputs.keyfilter(/\d/);
		$inputs.on('blur',function(){
			setProductsListCookie(getProductsListFromInputs());
		});
		$('#operator-goto-compare', $self).on('click',function(){
			compare();
		});
		$('#operator-clear-compare', $self).on('click',function(){
			clearCompare();
		});
		$(document).on('cartReady',function(){
			refreshCartCounter();
		});
		refreshCartCounter();
	}

	function refreshCartCounter(){
		var cartCounter = cartBox.getCount();
		console.log('Odświeżam koszyk dla operatora');
		if(cartCounter>0){
			$basket.removeClass('empty').find('span').html(cartCounter);
		}
		else {
			$basket.addClass('empty');
		}
	}

	function compare(){
		var products=getProductsListFromInputs();
		var url='/porownanie.bhtml?';
		var params=[];
		if(products.length<2){
			modal.alert('Wpisz PLU co najmniej dwóch towarów');
		}else{
			$.each(products,function(i,n){
				params.push('plu'+(i+1)+'='+n);
			});
			loading.redirect(url+params.join('&'));
		}
	}

	function getProductsListFromInputs(){
		var products = [];
		$inputs.each(function(){
			var value = $(this).val();
			if(value > 0){
				products.push(value);
			}
		});
		return products;
	}

	function clearCompare(){
		$inputs.val('');
		setProductsListCookie();
	}

	function setProductsListCookie(products){
		products = products || [];
		sessionStorage.setItem('operatorPluCompareProducts',products.join('|'));
	}

	function restorePluFromCookie(){
		var products = sessionStorage.getItem('operatorPluCompareProducts');
		if(products){
			products = products.split('|');
			$.each(products,function(i,n){
				$inputs.filter('#compare-plu-'+(i+1)).val(n);
			});
		}
	}

	function init(){
		setElements();
		setEvents();
		restorePluFromCookie();
	}

	return {
		init:init
	};
});
