app.service('reportFriend',function(modal, front, cookie, loading, validate, account, log, captchaService) {
	var $self, $modal;

	function loadSuccess(){
		$self=$('#modal-friend');
		validate.task('friendForm',function(form){
			send(form);
		});
		var userInfo=account.user();
		if(userInfo.email){
			$('#friend-your-email').val(userInfo.email);
		}
		$('.select3-container input', $self).filter('.select3-input').select3();
		addAnswerCaptcha($self);
	}

	function addAnswerCaptcha($form) {
		var $captcha = $form.find('[data-toggle="captcha"]');
		if ($captcha.length) {
			captchaService.load($captcha.attr('id'), $captcha.data('place'));
		}
	}

	function load(){
		loading.start();
		$.ajax({
			url:'/report-friend.ltr',
			success:function(content){
				UA.interaction(front.page(), "Social", "Poleć znajomemu");
				$modal=modal.generate({
					id:'modal-friend',
					content: content,
					callbackOpen:function(){
						loadSuccess();
					}
				});
			},
			complete:function(){
				loading.stop();
			}
		});
	}
	function send($form){
		var params = {
			emailId: 'CMS_NOTIFY_FRIEND',
			cms_input_plu: $('.js-notify-friend').data('plu') || undefined,
			cms_input_document_href: window.location.href
		}
		$form.addClass('loading');
		$form.serializeArray().forEach(element => {
			params[element.name] = element.value;
		});

		$.ajax({
			url: '/cmsPageFormSubmit.ltr',
			method:'POST',
			data:params,
			success:function(json){
				if(json.status == 'SUCCESS') {
					modal.alert('Wiadomość została wysłana');
				} else {
					modal.alert(json.messages !='' ? json.messages : 'Wystapił błąd wysyłania formularza. Prosimy spróbować później', function(){
						$modal.open();
					});
				}
			},error:function(){
				modal.alert('Wystapił błąd wysyłania formularza. Prosimy spróbować później');
			},complete:function(){
				$form.removeClass('loading');
			}
		});
	}

	function setEvents(){
		$('body').on('click','.js-report-friend',function(){
			load();
			log.recommendationsForAWS({
				productPlu: $('#product-top').data('plu') || $('.js-notify-friend').data('plu'),
				eventCategory: 'Poleć znajomemu',
				eventAction: 'Poleć znajomemu'
			});
		});
	}
	function init(){
		setEvents();
	}
	return {
		init:init
	};
});
