app.service('weeklyPromotions', function (lazyLoad, viewPoint, mainPageInteraction) {
	let $self;

	function init() {
		$self = $('#weekly-promotions-section');

		if (!$self.length) return false;

		new PreviewGallery({
			selector: $self.find("#weekly-promotions-carousel"),
			perPage: {
				0: 4.5,
				1231: 5.5
			},
			endOffset: 20,
			navigation: $self.find('.weekly-promotions-carousel-nav'),
			onInit: function onInit() {
				lazyLoad.update();
			},
			onResize() {
				lazyLoad.update();
			},
			onChange: function onChange() {
				lazyLoad.update();
			},
			swipe: true
		});

		viewPoint.set({
			element: $self,
			height: 0.6*$self.height(),
			callback: function(){
				mainPageInteraction.set('Sekcja promocje tygodnia - wyswietlenie');
			}
		});

		$self.on('click', '.js-carousel-next', function(){
			mainPageInteraction.set('Sekcja promocje tygodnia - klikniecia', 'Strzalka|Nastepny');
		});
		$self.on('click', '.js-carousel-prev', function(){
			mainPageInteraction.set('Sekcja promocje tygodnia - klikniecia', 'Strzalka|Poprzedni');
		});
		$self.on('click', '.promotion-item', function(){
			mainPageInteraction.set('Sekcja promocje tygodnia - klikniecia', 'Box reklamowy|'+($(this).index()+1));
		});
	}

	return {init};
});
