app.service('mainPageRecommendations', function(lastProducts, localStorage, front, viewPoint, mainPageInteraction, logData) {
	var $self, $list, $slider;
	var type, activeSlider = 0,
		products;
	var localStorageName = 'main-page-recommendations-collapse';

	function setElements() {
		if (lastProducts.getProducts().length) {
			$self = $('#see-also-main-page-carousel');
			$slider = $('.slider', $self);
			type = 'full';
		} else {
			$self = $('#see-also-main-page-empty');
			type = 'simple';
		}
		$list = $('.list', $self);
		if (localStorage.getItem(localStorageName) === "true") {
			$self.addClass('collapse');
		}
		$self.addClass('visible');
	}

	function setSlider(active) {
		if (active < products.length && active > -1) {
			activeSlider = active;
			$slider.attr('data-active', activeSlider);
			if (activeSlider + 1 == products.length) {
				$slider.addClass('end');
			} else {
				$slider.removeClass('end');
			}
			if(logData.showRecommendationsFromAWSonMainPage()) {
				loadRecommendationsfromAWS({
					'productId': products[activeSlider]
				});
			} else {
				loadRecommendations({
					'product-id': products[activeSlider]
				});
			}
		}
	}

	function runSlider() {
		$('.js-next-recommendations', $slider).click(function() {
			setSlider(activeSlider + 1);
		});
		$('.js-prev-recommendations', $slider).click(function() {
			setSlider(activeSlider - 1);
		});
		setSlider(0);
	}

	function loadSingleLastVisited(productId, position, $li) {
		$.ajax({
			url: '/last-viewed-single-product.ltr',
			data: {
				productId: productId,
				mainPage: true// ,				position: position		czasowo zabrane przekazywanie pozycji
			},
			success: function(html) {
				$li.after(html);
				$li.remove();
			},
			complete: function() {

			}
		});
	}

	function loadLastVisited() {
		products = lastProducts.getProducts();
		var $ul = $('<ul/>').appendTo($slider);
		products.forEach(function(element, index) {
			var $li = $('<li/>').addClass('loading').appendTo($ul);
			loadSingleLastVisited(element, index + 1, $li);
		});
		// loadRecommendations({
		// 	'product-id': products[0]
		// });
	}

	function loadRecommendations(ajaxData = {}) {
		var data = $.extend({
		}, ajaxData);
		$list.addClass('loading');
		$.ajax({
			url: '/see-also-for-main-page.ltr',
			data: data,
			success: function(html) {
				$list.html('<ul>' + html + '</ul>').removeClass('loading');
			},
			complete: function() {
				$list.removeClass('loading');
			}
		});
	}

	function loadRecommendationsfromAWS(ajaxData = {}) {
		var data = $.extend({
			userId: logData.customerId()
		}, ajaxData);

		$list.addClass('loading');
		$.ajax({
			url: '/main-page-recommendations.ltr',
			data: data,
			success: function(html) {
				$list.html('<ul>' + html + '</ul>').removeClass('loading');
			},
			complete: function() {
				$list.removeClass('loading');
			}
		});
	}

	function setEvents() {
		$('.js-recommendations-button').click(function() {
			$self.toggleClass('collapse');
			var isCollapse = $self.hasClass('collapse');
			localStorage.setItem(localStorageName, isCollapse);
		});
		if(front.euro && test.getVar(front.getParam('sg_ab')) == 'A'){
			$self.on('click', '.last-product-list .simple-product-item', function() {
				mainPageInteraction.set('Sekcja rekomendacji - klikniecia', 'Produkt historyczny');
			}).on('click', '.recommendations-product-list .simple-product-item', function() {
				mainPageInteraction.set('Sekcja rekomendacji - klikniecia', 'Polecane');
			});
			if(type == 'simple'){
				$self.on('click', '.simple-product-item', function() {
					mainPageInteraction.set('Sekcja rekomendacji - klikniecia', 'Polecane');
				});
			}

			viewPoint.set({
				element: $self,
				height: 0.6*$self.height(),
				callback: function(){
					mainPageInteraction.set('Sekcja rekomendacji - wyswietlenie', type == 'simple' ? 'Polecane':'Produkt historyczny|Polecane');
				}
			});

		}
	}

	function init() {
		setElements();
		setEvents();
		if (type == 'simple') {
			if(logData.showRecommendationsFromAWSonMainPage()) {
				loadRecommendationsfromAWS();
			} else {
				loadRecommendations();
			}
		} else {
			loadLastVisited();
			runSlider();
		}
	}

	return {
		init: init
	};
});
