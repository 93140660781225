app.service('productsRecommendation', function() {
	var $self, $products;
	function setElements() {
		$self=$('#top-product-opinions');
		$products=$('.product', $self);
	}

	function setHeaderHeight() {
		$self.removeClass('extended-header');
		$products.each(function(i,element){
			if($(element).find('h2').height() >= 40){
				$self.addClass('extended-header');
			}
		});
	}
	function setEvents(){
		$(window).resize(function() {
			setHeaderHeight();
		});
	}
	function init() {
		setElements();
		setEvents();
		setHeaderHeight();
	}

	return {
		init:init
	};
});