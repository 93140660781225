app.service('operatorVouchers', function(console, front, savedShopsManager) {

	function load() {
		if (front.ole) {
			var uniqueIds = [];
			$('.allegro-voucher').each(function() {
				var id = $(this).data('id');
				if(uniqueIds.indexOf(id) === -1) {
					uniqueIds.push(id)
					addAllegroCodes(id);
				}
			});
		} else if (front.euro) {
			var uniqueIds = [];
			$('.best-voucher').each(function() {
				var id = $(this).data('id');
				if(uniqueIds.indexOf(id) === -1) {
					uniqueIds.push(id)
					addVoucherCodes(id, $(this).data('city-code'));
				}
			});
		}
	}

	function addVoucherCodes(productId, cityCode) {
		$.ajax({
			url: '/additionalProductInfo.ltr',
			data: {
				productId: productId,
				cityCode: cityCode
			},
			success: function(html) {
				$('.best-voucher[data-id="' + productId + '"]').html(html);
				savedShopsManager.show(productId);
			},
			error: function(e) {}
		});
	}

	function addAllegroCodes(productId) {
		$.ajax({
			url: '/allegroVoucherFinder.ltr',
			type: 'GET',
			data: {
				productId: productId
			},
			success: function(html) {
				$('.allegro-voucher[data-id="' + productId + '"]').html(html);
			},
			error: function(e) {}
		});
	}

	function init() {
		$(document).on('pageChange', function() {
			load();
		});
		load();
	}

	return {
		init: init
	};
});
