/*
    Moduł do zarządzania preferencjami użytkownika
 */
app.service("userPreferences", function(cookie, front, pageConfig, localStorage) {

	function getDelivery() {
		var cityData;
		if(front.katalog){
			var config=pageConfig.getData('wc');
			cityData = {
				'zipCode': config.cartZipCode,
				'cityName': config.shopPostalCity
			};
		}
		if (!cookie.isEmpty('deliveryInfo')) {                //'cookie' do usuniecia po 365 dniach od wdrożenia
			localStorage.setItem('deliveryInfo', cookie.get('deliveryInfo'), 365);
			cookie.remove('deliveryInfo');
		}
		if (!localStorage.empty('deliveryInfo')) {
			var deliveryInfo = localStorage.getItem('deliveryInfo').split('|');
			if (deliveryInfo[0] && deliveryInfo[1]) {
				cityData = {
					'zipCode': deliveryInfo[0],
					'cityName': deliveryInfo[1]
				};
			}
		}

		return cityData;
	}

	function setDelivery(zipCode, cityName) {
		if (zipCode && cityName) {
			var deliveryInfo = zipCode + '|' + cityName;
			localStorage.setItem('deliveryInfo', deliveryInfo, 365);
		}
	}

	function getShop() {
		return localStorage.getItem('userShop');
	}

	function setShop(shopId) {
		if (shopId) {
			localStorage.setItem('userShop', shopId, 90);
		}
	}
	function setWcShop(code){
		cookie.set('userWcShop', code, {
			path: '/',
			domain: front.getParam('cookieDomain'),
			expires: 365
		});
	}
	function getWcShop(){
		var cookieValue=cookie.get('userWcShop');
		if(front.katalog){
			return cookieValue || pageConfig.getData('wc').shopCode;
		}else{
			return cookieValue || false;
		}
	}
	function getShopLocation() {
		return localStorage.getItem('userShopLocation');
	}

	function setShopLocation(location) {
		if (location) {
			localStorage.setItem('userShopLocation', location, 90);
		}
	}

	return {
		getDelivery: getDelivery,
		setDelivery: setDelivery,
		getShop: getShop,
		setShop: setShop,
		getShopLocation: getShopLocation,
		setShopLocation: setShopLocation,
		getWcShop: getWcShop,
		setWcShop: setWcShop
	};
});
