/*
* Moduł obsługujacy formularz wyboru miasta
*/
app.service("selectCity", function(userPreferences, shopsDetails, validate, front, pageConfig) {

	var $self, $cities, $zipCode, $onlyAvailable, $wcShopName;
	var product, calledFrom, onlyAvailableToOrder;

	function setElements() {
		$self = $('#search-city');

		if($self.length){
			$cities = $('#city-select', $self);
			$('.select3-container input', $self).filter('.select3-input').select3();
			$zipCode = $('#zip-code', $self).val('');
			$onlyAvailable = $('#only-available', $self);
			$wcShopName=$('#shops-select', $self);
			product=$self.data('product');
			calledFrom=$self.data('called-from');
			onlyAvailableToOrder=($self.data('only-available-to-order')===true);
		}
	}

	function setEvents() {

		$cities.on("change", function(){
			var value=$(this).val().trim();
			if(value!==''){
				$zipCode.val('');
				$wcShopName.val('').change();
				changeShopLocation($(this).val());
			}
		});
		$zipCode.on('change', function(){
			var value=$(this).val().trim();
			if(value!==''){
				$wcShopName.val('').change();
				$cities.val('').change();
			}
		});
		if(front.katalog){
			$zipCode.on('focus', function(){
				$(this).val('');
			});
			$('.select3-filter',$self).on('focus',function(){
				$(this).val('');
			});
		}

		$wcShopName.on('change', function(){
			var value=$(this).val().trim();
			if(value!==''){
				$cities.val('').change();
				changeWcShopLocation($(this).val());
			}
		});

		$onlyAvailable.on('change', function(){
			shopsDetails.showShops(product, ajaxData());
		});
	}

	function initForm() {
		shopsDetails.init();
		$zipCode.mask2('99-999').keyfilter(/[\d\-]/);

		if(front.katalog){
			var wcShop = pageConfig.getData('wc').shopCode;
			if (wcShop && wcShop.length) {
				$wcShopName.val(wcShop).trigger('change');
			}
		}else{
			var city = userPreferences.getShopLocation();

			if (city){
				if(/^[0-9]{2}\-[0-9]{3}$/.test(city)) {
					$zipCode.val(city);
					shopsDetails.showShops(product, ajaxData());
				} else {
					$cities.val(city).trigger('change');
				}
			}else{
				shopsDetails.showShops(product, ajaxData());
			}
		}

		validate.task('pickShopDeliveryCity', {
			submit: function (form) {
				submitCityForm(form);
			}
		});
	}
	function ajaxData(){
		if(onlyAvailableToOrder){
			return {
				onlyAvailableToOrder: onlyAvailableToOrder
			};
		}else{
			return {
				onlyAvailable: $onlyAvailable.is(':checked'),
				calledFrom: calledFrom
			};
		}
	}
	function changeShopLocation(location){
		userPreferences.setShopLocation(location);
		shopsDetails.showShops(product,ajaxData());
	}
	function changeWcShopLocation(code){
		userPreferences.setWcShop(code);
		shopsDetails.showShops(product, $.extend(ajaxData(),{shopCode:code}));
	}

	function submitCityForm(form) {

		var zipCode = $zipCode.val();
		changeShopLocation(zipCode);
		$cities.val('').trigger('change');

	}

	function init() {
		setElements();
		if($self.length){
			setEvents();
			initForm();
		}
	}

	return {
		init: init
	};
});
