/**
 * Moduł obsługujący logowanie, rejestrację oraz przypomnienie hasła
 */
app.service('login', function (modal, loading, validate, agreementsSubmit, console, passwordInvalid, captchaService, emailInvalid) {
	var $loginBox, $loginForm, $registerForm;
	var userUtterance, userEmail, userCallback, waitForCheckNick;

	function registerFormSend(callback){
		var data=$registerForm.find('input').serializeArray();
		const token = $registerForm.find('[data-toggle="captcha"] .g-recaptcha-response').val();
		addRecaptchaToken(token, data);
		var $errorText=$registerForm.find('.errorText');
		var url='/layer-registration.ltr?user_utterance='+userUtterance;
		loading.start();
		$.ajax({
			url: url,
			type: 'POST',
			data: data,
			success:function(json){
				loading.stop();
				if(json.loginFailed && json.errorMessage!==''){
					$errorText.html(json.errorMessage);
					addRegistrationCaptcha();
				}
				if(json.loginSuccessful){
					$loginBox.addClass('success');
					setTimeout(function(){
						checkLogged(function(){
							modal.close();
							callback();
						});
					},1000);
				}
			},
			error:function(){
				loading.stop();
				$errorText.text('Błąd komunikacji. Prosimy spróbować raz jeszcze!');
			}
		});
	}

	function registerFormSuccess(callback){
		$registerForm.find('input:checkbox').click(function(){
			$registerForm.validate().element($(this));
		});
		agreementsSubmit.init($registerForm, $registerForm.find('input[name=permissionToProcessingAccepted]'));

		$('#login-layer .js-check-nick').click(function(){
			checkNick(false);
			return false;
		});
		validate.task('registerLayer',function(){
			checkNick(null, function () {
				$('#login-layer .check').remove();
				registerFormSend(callback);
			});
		});
		emailInvalid.init($('#email-form'), $('#email-invalid-text'), $('#email-form').closest('.row'));
		passwordInvalid.init($('#password'), $('#password').next('.password-invalid'), $('#password').closest('.row'), $('#email-form'));
	}
	function loginFormSend(callback){
		var data=$loginForm.find('input').serializeArray();
		const token = $loginForm.find('[data-toggle="captcha"] .g-recaptcha-response').val();
		const idCaptcha = $loginForm.find('[data-toggle="captcha"]').attr('id');
		addRecaptchaToken(token, data);
		var $errorText=$loginForm.find('.errorText');
		var url='/json_j_spring_security_check.ltr';
		loading.start();
		$.ajax({
			url: url,
			type: 'POST',
			data: data,
			success:function(json){
				loading.stop();
				if(json.loginFailed && json.errorMessage!==''){
					$errorText.html(json.errorMessage);
					captchaService.reset(idCaptcha);
					addLoginCaptcha();
				}
				if(json.loginSuccessful){
					$loginBox.addClass('success');
					setTimeout(function(){
						checkLogged(function(){
							modal.close();
							$('#login-modal').remove();
							callback();
						});
					},1000);
				}
			},
			error:function(){
				loading.stop();
				$errorText.text('Błąd komunikacji. Prosimy spróbować raz jeszcze!');
			}
		});
	}

	function addRecaptchaToken(token, data) {
		if (token) {
			data.push({
				name: 'g-recaptcha-response',
				value: token
			});
		}
	}

	function loginFormSuccess(callback){
		validate.task('loginView',function(){
			loginFormSend(callback);
		});
	}

	function loadLayerSuccess(html,callback){
		loading.stop();
		modal.generate({
			header:'Logowanie/Rejestracja',
			content:html,
			id:'login-modal',
			callbackOpen:function(){
				$loginBox=$('#login-layer');
				$loginForm=$('#loginView-login');
				$registerForm = $('#loginView-register');
				loginFormSuccess(callback);
				registerFormSuccess(callback);
				addLoginCaptcha();
				addRegistrationCaptcha();
				$loginMenu=$('.js-menu-item', $loginBox);
				$loginMenu.click(function(){
					$loginBox.removeClass('login-mode register-mode');
					$loginBox.addClass($(this).data('mode')+'-mode');
				});
			}
		});
	}
	function addRegistrationCaptcha() {
		let $captcha = $registerForm.find('[data-toggle="captcha"]');
		if ($captcha.length) {
			captchaService.load($captcha.attr('id'), $captcha.data('place'));
		}
	}
	function addLoginCaptcha() {
		let $captcha = $loginForm.find('[data-toggle="captcha"]');
		let username = $loginForm.find('#j_username').val();
		if ($captcha.length) {
			captchaService.load($captcha.attr('id'), $captcha.data('place'), username);
		}
	}
	function loadLayer(callback){
		if($.support.cors){
			loading.start();
			$.ajax({
				url:'/layer-registration.ltr',
				data:{
					'user_utterance':userUtterance
				},
				success:function(html){
					loadLayerSuccess(html, callback);
				},
				error:function(){
					loading.stop();
					modal.alert('Wystąpił błąd komunikacji, prosimy spróbować później');
				}
			});
		}else{
			modal.alert('Przestarzałą przeglądarka!');
		}
	}
	function checkLogged(callback) {
		loading.start();
		$.ajax({
			url: '/front-user-logged.ltr',
			dataType: 'json',
			success: function(data) {
				loading.stop();
				if (data.logged) {
					callback();
				}else{
					loadLayer(callback);
				}
			},
			error:function(){
				loading.stop();
				modal.alert('Wystąpił błąd komunikacji, prosimy spróbować później');
			}
		});
	}
	function checkNick(submit,callback){
		if(!waitForCheckNick){
			waitForCheckNick=true;
			var action=(submit===true)?'CHECK_SAVE':'CHECK';
			var nick=$('.remodal-is-opened #nick');
			var nickValue=nick.val();
			var nickStatus=$('#nick-status');
			nickStatus.text('');
			nick.attr('disabled',true);
			nickStatus.removeClass('error valid');
			loading.start();
			$.ajax({
				//TODO 2940 czy zastapic /customer-unique-nick.ltr
				url:'/frontUser-unique-nick.ltr',
				type:'POST',
				data:{
					nick:nickValue,
					ACTION:action
				},
				complete:function(){
					waitForCheckNick=false;
				},
				success:function(html){
					loading.stop();
					var status = $('<div>' + html + '</div>').find('[data-valid]').data('valid') ? 'VALID' : 'ERROR';
					nick.removeAttr('disabled');

					nickStatus.addClass(status.toLowerCase());
					if(status=='VALID'){
						nickStatus.text('Nick jest OK');
					}
					if(status=='ERROR'){
						nickStatus.text('Nick jest już zajęty lub niepoprawny');
					}
					checkUniqueNickSuccess(html,function(){
						if(submit){
							modal.close();
							callback();
						}
					});
					if(submit===null){
						if(status=='VALID'){
							callback();
						}
					}
				},
				error:function(){
					loading.stop();
					nick.removeAttr('disabled');
					modal.alert('Wystąpił błąd komunikacji, prosimy spróbować później');
				}
			});
		}
	}
	function checkUniqueNickSuccess(html,callback){
		if ($('<div>' + html + '</div>').find('div').not('[data-valid]').length){
			modal.generate({
				header: 'Stwórz unikalny nick',
				content:html,
				id:'nick-modal',
				callbackOpen:function(){
					$('#nick-modal .js-check-nick').click(function(event){
						event.preventDefault();
						$('#nick-status').css('display', 'block');
						checkNick(false);
					});
					$('#nick-modal .js-nick-save').click(function(){
						$('#nick-status').css('display', 'block');
						checkNick(true, callback);
						return false;
					});
				}
			});
		}else{
			var status = $('<div>' + html + '</div>').find('[data-valid]').data('valid') ? 'VALID' : 'ERROR';
			if(status=='VALID'){
				callback();
			}
		}
	}
	function checkUniqueNick(callback, utterance){
		userUtterance=utterance;
		userCallback=callback;
		checkLogged(function(){
			loading.start();
			$.ajax({
				url:'/frontUser-unique-nick.ltr',
				cache:false,
				success:function(html){
					loading.stop();
					checkUniqueNickSuccess(html,callback);
				},
				error:function(){
					loading.stop();
					modal.alert('Wystąpił błąd komunikacji, prosimy spróbować później');
				}
			});
		});
	}
	function fastOrderLoginFormSendSuccess(html) {
		if ($(html).filter('#login-layer').length) {
			$loginBox.replaceWith(html);
			fastOrderLoginView();
			$loginBox.removeClass('loading');
		} else {
			$('#fake-user').remove();
			modal.close();
			loading.start();
			document.location.href=location.pathname;
		}
	}
	function fastOrderLoginFormSend() {
		console.log('LOGOWANIE Z WARSTWY');
		$loginBox.addClass('loading');
		loading.start();
		$.ajax({
			url:'/simple_j_spring_security_check.ltr',
			data:{
				j_username: userEmail,
				j_password:$('#j_password').val()
			},
			type:'POST',
			success:function(html){
				loading.stop();
				fastOrderLoginFormSendSuccess(html);
			},error:function(){
				modal.alert('Wystąpił błąd komunikacji, prosimy spróbować później');
			}
		});
	}
	function fastOrderLoginView(){
		$loginBox = $('#login-layer');
		$loginMenu = $('.js-menu-item', $loginBox);
		$loginMenu.click(function(){
			$loginBox.removeClass('login-mode register-mode');
			$loginBox.addClass($(this).data('mode')+'-mode');
		});
		validate.task('loginView',function(){
			fastOrderLoginFormSend();
		});
	}
	function showLoginLayer(email) {
		console.log('WYŚWIETLENIE WARSTWY Z LOGOWANIEM');
		userEmail = email;
		loading.start();
		$.ajax({
			url:'/simple-login.ltr',
			cache: false,
			data: {
				email: userEmail
			},
			success:function(html) {
				$.scrollTo(0);
				modal.generate({
					header: 'Komunikat',
					content: html,
					id:'login-modal',
					callbackOpen:function() {
						loading.stop();
						fastOrderLoginView();
					}
				});
			}
		});
	}
	return {
		checkUniqueNick:checkUniqueNick,
		showLoginLayer:showLoginLayer
	};
});
