/**
 * Moduł obsługujący status przesyłki
 */
app.service('statusDelivery',function(modal, loading, message){
	var statusCode, productId;

	function loadSuccess(html){
		modal.generate({
			id: statusCode === 'XXX' ? 'partner-delivery-modal' : 'status-details-modal',
			content:html,
			callbackOpen:function(){
				UA.pageview('/status-details.ltr?status_code='+statusCode+'&prodid='+productId);
			}
		});
	}
	function load(status, product){
		statusCode=status;
		productId=product;
		loading.start();
		$.ajax({
			url:'/status-details.ltr',
			data:{
				status_code:statusCode
			},
			success:function(html){
				loadSuccess(html);
			},
			complete:function(){
				loading.stop();
			}
		});
	}

	function setEvents(){
		$('body').on('click', '.js-phone-delivery', function() {
			loading.start();

			$.ajax({
				url:'/partner-subscription-message-alert.ltr',
				data:{
					name: 'after-sale-contract-description',
					supplierId:$(this).data('supplier')
				},
				success:function(html){
					loading.stop();
					modal.generate({
						id: 'partner-delivery-modal',
						content:html
					});
				},
				error:function(){
					loading.stop();
					modal.alert('Błąd pobierania opisu usługi');
				}
			});
		})
		.on('click', '.js-status-delivery', function() {
			load($(this).data('status'), $(this).data('product'));
		});

	}
	function setElements(){

	}
	function init(){
		setElements();
		setEvents();
	}
	return {
		init:init,
		load
	};
});
