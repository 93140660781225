app.service('moreRecommendations', function (front, lazyLoad, viewPoint, mainPageInteraction, logData, broker) {

	let $self, $tabs, $tabsContent;
	let loadedRecommendations = [];
	let topSuggestions = [];
	var isInit = false, lastViewedGroup=0;

	function show(calculatedTopSuggestions) {

		setTopSuggestions(calculatedTopSuggestions);

		$self = $('#more-recommendations-section');
		if (!calculatedTopSuggestions.length) {
			hide();
			return;
		}
		$tabs = $self.find('.js-recommendations-tabs');
		$tabsContent = $self.find('.js-recommendations-content');
		$self.addClass('loading');
		$self.addClass('is-visible');

		lastViewedGroup = Math.min(lastViewedGroup, topSuggestions.length);
		showTabs(lastViewedGroup);
		if(!isInit){
			createRecommendationsSlots();
		}
		loadRecommendations(topSuggestions[lastViewedGroup], lastViewedGroup);
		if(!isInit){
			setEvents();
			isInit = true;
		}
	}

	function setTopSuggestions(calculatedTopSuggestions) {
		const recommendedLimit = front.getParam('recommendedLimit');
		topSuggestions = calculatedTopSuggestions.slice(0, recommendedLimit);
	}

	function createRecommendationsSlots() {
		const slotsHtml = topSuggestions.map(topSuggestion => `<div data-group="${topSuggestion.groupId}" class="recommendations-slot"></div>`).join('');
		$tabsContent.html(slotsHtml);
	}

	function setEvents() {
		$self.on('click', '.recommendations-tab', function () {

			$(this).addClass('is-active')
				.siblings()
				.removeClass('is-active');

			const groupId = $(this).data('group');

			if (loadedRecommendations.indexOf(groupId) !== -1) {
				const $tab = $tabsContent.find(`[data-group=${groupId}]`);
				lastViewedGroup = $(this).index();
				setActiveTabContent($tab);
				return false;
			}

			const topSuggestion = topSuggestions.filter(topSuggestion => +topSuggestion.groupId === +groupId)[0];
			loadRecommendations(topSuggestion, $(this).index());
		});
		initUA();
	}

	function setActiveTabContent($tab) {
		$tab.addClass('is-active')
			.siblings()
			.removeClass('is-active');
	}

	function initCarousel($tab) {
		new PreviewGallery({
			selector: $tab.find('.recommendation-list'),
			perPage: {
				0: 3.25,
				1231: 4.5
			},
			endOffset: 20,
			navigation: $tab.find('.recommendations-carousel-nav'),
			swipe: true,
			onChange() {
				lazyLoad.update();
			},
			onResize() {
				lazyLoad.update();
			},
			onInit: function onInit() {
				lazyLoad.update();
			}
		});
	}

	function loadRecommendations(topSuggestion, index) {

		$self.addClass('loading');
		const $currentTab = $tabsContent.find(`[data-group=${topSuggestion.groupId}]`);
		let url = topSuggestion.aws ? '/main-page-product-recommendations.ltr' : '/product-recommendations.ltr';
		let data = topSuggestion.aws ? {
			userId : logData.customerId(),
			groupId : topSuggestion.groupId
		} : {
			productId: topSuggestion.topProduct
		};
		$.ajax({
			url,
			method: 'GET',
			data
		}).then(html => {
			$currentTab.html(html);
			setActiveTabContent($currentTab);
			if($currentTab.find('.recommendation-list-item').length){
				lastViewedGroup = index;
				initCarousel($currentTab);
				loadedRecommendations.push(+topSuggestion.groupId);
			}else if(!topSuggestion.aws){
				$self.addClass('loading');
				broker.trigger('REMOVE_SUGGESTION', topSuggestion.groupId);
			}
			viewPoint.set({
				element: $self,
				height: 0.6*$self.height(),
				callback: function(){
					mainPageInteraction.set('Sekcja Polecane teraz - wyswietlenie');
					if(topSuggestion.aws) {
						const awsCampaignId = $(html).find('.recommendations').data('campaign');
						UA.interaction('Rekomendacje AWS', 'Wyświetlenie', `${awsCampaignId ? 'tak' : 'nie'}|${test.getVar('awsRec_ab')}`);
					}
				}
			});
		}).fail(() => {
			$currentTab.html('<div class="recommendations-empty">Przepraszamy, nie udało się pobrać danych o polecanych produktach.</div>');
			setActiveTabContent($currentTab);
		}).always(() => {
			$self.removeClass('loading');
		});
	}

	function initUA(){

		$self.on('click', '.recommendations-tab', function(){
			var label = 'Zakladki '+($(this).index()+1)+' z '+$(this).parent().children().length;
			mainPageInteraction.set('Sekcja Polecane teraz - klikniecia', label);
		})
		.on('click', '.recommendation-list-item', function(){
			var index = $(this).index();
			const awsCampaignId = $(this).closest('.recommendations').data('campaign');
			if(index == 0){		// osobna analityka dla pierwszego produktu
				mainPageInteraction.set('Sekcja Polecane teraz - klikniecia', 'Produkt historyczny|Box produktowy');
			}else{
				mainPageInteraction.set('Sekcja Polecane teraz - klikniecia', 'Rekomendacje|'+index+'|Box produktowy');
				if(awsCampaignId) {
					UA.interaction('Rekomendacje AWS', 'Klik w produkt', awsCampaignId);
				}
			}
		})
		.on('click', '.js-carousel-next', function(){
			mainPageInteraction.set('Sekcja Polecane teraz - klikniecia', 'Rotator|Strzalki - Nastepny');
		})
		.on('click', '.js-carousel-prev', function(){
			mainPageInteraction.set('Sekcja Polecane teraz - klikniecia', 'Rotator|Strzalki - Poprzedni');
		})
		.on('click', '.recommendations-links a', function(){
			var category = $self.find('.recommendations-tab.is-active').text();
			var label = 'Polecane|Linki|'+category+'|';
			if($(this).hasClass('all-products')){
				label+=$(this).text();
			}else{
				label+=$(this).find('span').text();
			}
			mainPageInteraction.set('Sekcja Polecane teraz - klikniecia', label);
		});


	}
	function hide(){
		if($self && $self.length){
			$self.remove();
		}
	}

	function showTabs(index) {
		const isFirstTab = (i) => i === index;
		const html = topSuggestions
			.map((topSuggestion, index) =>
				`<div data-group="${topSuggestion.groupId}" class="recommendations-tab${isFirstTab(index) ? ' is-active' : ''}">${topSuggestion.groupName}</div>`
			).join('');
		$tabs.html(html);
	}

	return {
		show, hide
	};
});
