app.service('toCartView', function (pageConfig, cartManager, front, log, cookie, modal, accessoryCrossselling) {
	var $self, $tiles;
	var config;

	function init() {
		setElements();
		setEvents();
		if (typeof config.cartStepOrderInfo !== 'undefined') {
			log.event(
				prepareCartStepLogEventData(config.cartStepOrderInfo)
			);
		}

		if (front.katalog) {
			showAccessories();
		} else {
			accessoryCrossselling.init();
		}
	}

	function setElements() {
		$self = $('#to-cart');
		$tiles = $('#suggested-accessories', $self);
		config = pageConfig.getData('precart');
	}

	function setEvents() {
		if (front.katalog) {
			$self.on('click', '.js-add-accessory-to-cart-checkbox', function () {
				addToCartClick($(this).val(), $(this).is(':checked'));
			});
			$(document).on('cartReady', function () {
				checkCart();
			});
			$self.on('click', '.js-more-suggested-accessories', function () {
				var id = $(this).data('id');
				modal.open('to-cart-accessories-' + id);
			});
			$('.js-to-cart-back', $self).on('click', function () {
				history.back();
			});
		}
	}

	function prepareCartStepLogEventData(cartStepOrderInfo) {
		var cartStepLogEventData = {
			id:'cart_step',
		}
		// f04 : numer zamówienia
		// cartStepLogEventData.f04 = ''; //nie wysyłamy na tym kroku

		// f05 : wartość koszyka
		cartStepLogEventData.f05 = cartStepOrderInfo.paymentTotal;

		// f06 : typ płatności
		// cartStepLogEventData.f06 = ''; //nie wysyłamy na tym kroku
		// cartStepLogEventData.f06 = ''; //nie wysyłamy na tym kroku

		// f07 : typ dostawy
		// cartStepLogEventData.f07 = ''; //nie wysyłamy na tym kroku

		// f08 : czy jest kod rabatowy
		// cartStepLogEventData.f08 = ''; //nie wysyłamy na tym kroku

		// f09 : ''
		// cartStepLogEventData.f09 = ''; //puste

		// f10 : ''
		// cartStepLogEventData.f10 = ''; //puste

		// f11 : numer koszyka
		var cookieCart = front.euro ? 'euroCart' : 'oleoleCart';
		cartStepLogEventData.f11 = cookie.get(cookieCart);

		// f12 : Plu produktów w koszyku, po średniku
		cartStepLogEventData.f12 = cartStepOrderInfo.productsCodes;

		// f13 : krok koszyka
		cartStepLogEventData.f13 = 'K0';

		// f14 : czy zalogowany
		cartStepLogEventData.f14 = cartStepOrderInfo.frontUserId;

		// f15 : ''
		// cartStepLogEventData.f15 = ''; //nie wysyłamy na tym kroku

		// f16 : ścieżka ratalna
		cartStepLogEventData.f16 = cartStepOrderInfo.isInstalmentPayment == 'true' ? 'INSTALLMENT' : 'CASH';

		// f17 : wybrany temin dostawy
		// cartStepLogEventData.f17 = ''; //nie wysyłamy na tym kroku

		// f18 : wybrana godzina dostawy
		// cartStepLogEventData.f18 = ''; //nie wysyłamy na tym kroku

		// f19 : czy na firmę
		// cartStepLogEventData.f19 = ''; //nie wysyłamy na tym kroku

		// f20 : Czy wyświetliło się łączenie koszyka?
		// cartStepLogEventData.f20 = ''; //nie wysyłamy na tym kroku

		return cartStepLogEventData;
	}

	function showAccessories() {
		var data = $.extend({
			destination: 'TO_CART',
			source: 'RECOMMENDATIONS'
		}, config);
		$.ajax({
			url: '/to-cart-accessories-list.ltr',
			data: data,
			success: function (html) {
				showAccessoriesSuccess(html);
			},
			complete: function () {
				$tiles.removeClass('loading');
			},
			error: function () {
				$tiles.remove();
			}
		});
	}

	function checkCart() {
		if (front.katalog) {
			cartManager.checkRelatedProducts(config.productId / 1);
		} else {
			checkRelatedProducts()
		}
	}

	function checkRelatedProducts() {	//jeżeli przyjdzie relatedProducts to sprawdzamy przypisane akcesoria do wskazanego produktu i produkty z koszyka
		var items = cartManager.getElements();
		$('.js-add-accessory-to-cart', $tiles).each(function () {
			let $element = $(this);
			let id = $element.data('id') / 1;
			let $parent = $element.closest('.accessory-item');

			$parent.removeClass('loading');
			if ($.inArray(id, items) > -1) {
				$element.addClass('added-to-cart');
			} else {
				$element.removeClass('added-to-cart');
			}
		});
	}

	function addToCartClick(id, checked) {
		if (front.katalog) {
			cartManager.clickRelatedProducts(id, checked, 'TO_CART', config.productPlu, config.productId);
		} else {
			clickRelatedProducts(id, checked);
		}
	}

	function clickRelatedProducts(id, checked) {
		let plu = config.productPlu;
		let addedFrom = 'TO_CART';
		if (id) {
			var $elements = $('input[name=accessory]').filter('[value=' + id + ']');
			var $element = $elements.eq(0);
			$elements.prop('disabled', true);
			$elements.each(function () {
				$(this).closest('.accessory-item').addClass('loading');
			});
			if (!checked) {
				if (mainProduct) {
					removeCartItemOrAccessory({
						accessoryProductId: $element.val(),
						mainProductId: mainProduct
					});
				} else {
					if (front.katalog) {

						wcRemoveProduct($element.val());
					} else {

						simpleRemoveFromCart({
							productId: $element.val()
						});
					}
				}
			} else {
				productInteractionListener.onProductAddedToCart(id);
				if (mainProduct) {
					addAccessoryToProduct({
						accessoryProductId: $element.val(),
						mainProductId: mainProduct
					});
				} else {
					simpleAddToCart({
						productId: $element.val(),
						addedFrom: addedFrom
					});
					log.event({
						id: 'tocart',
						f03: addedFrom,
						f04: $element.data('plu'),
						f05: 'AKC',
						f06: productPLU,
						f07: front.page()
					});
					log.recommendationsForAWS({
						productPlu: $element.data('plu'),
						eventCategory: addedFrom,
						eventAction: 'Dodanie akcesoria'
					});
					UA.doubleClick($element.data('plu'), 'dc-acc-tocart-kp');
				}
			}
		}
	}

	function showAccessoriesSuccess(html) {
		const $groups = $('<div />').html(html).find('.group');
		$tiles.append($groups);
	}

	return {
		init: init
	};
});
