app.service('partnerSubscriptionManager', function(cartManager, console, callback, log, front, broker, modal, loading) {
	var productPlu;
	function checkboxClick(params) {
		if ($.inArray(parseInt(params.productId), cartManager.getElements()) != -1) {
			if (($.inArray((params.partnerSubscription+'|'+params.group), cartManager.getPartnerSubscriptions(params.productId)) != -1)) {
					cartManager.removePartnerSubscription({
						productId: params.productId,
						partnerSubscriptionId: params.partnerSubscription,
						relatedPromotionIdentifier: params.relatedPromotionIdentifier
					}, function(){
						params.callback();
					});
			} else {
				if (params.checked) {
					cartManager.addPartnerSubscription({
						productId: params.productId,
						relatedPromotionIdentifier: params.relatedPromotionIdentifier,
						partnerSubscriptionId: params.partnerSubscription
					}, function(){
						params.callback();
					});
				}
			}
		}else{
			cartManager.savePartnerSubscriptions(params);
			checkedCheck(params.productId);
		}
	}
	function checkedCheck(id){
		console.log('partnerSubscriptionManager.checkedCheck');
		var partnerSubscriptions=[];
		var added=cartManager.getAddedPartnerSubscriptions();
		var saved=cartManager.getPartnerSubscriptions(id);
		if(added && added.length){
			partnerSubscriptions=partnerSubscriptions.concat(added);
		}
		if(saved && saved.length){
			partnerSubscriptions=partnerSubscriptions.concat(saved);
		}
		var $groups=$('.js-show-partner-subscriptions');
		var $checkbox=$('input[name="partnerSubscription"]');
		var $labels=$('.selected-partner-subscriptions li');
		$groups.removeClass('change-mode');
		$labels.removeClass('show last').parents('.selected-partner-subscriptions').removeClass('show');
		//console.log(partnerSubscriptions)
		partnerSubscriptions.forEach(function(n){
			var data=n.split("|");
			if(data[1].length){
				$groups.filter('[data-group='+data[1]+']').addClass('change-mode');
			}
			$checkbox.filter('[value='+data[0]+']').prop('checked',true);
			$labels.filter('[data-id='+data[0]+']').addClass('show').parents('.selected-partner-subscriptions').addClass('show');
		});
		$('.selected-partner-subscriptions').each(function(){
			$('.show', $(this)).last().addClass('last');
		});

	}
	function loadModal(product, group, relatedPromotionIdentifier, callback){
		loading.start();
		$.ajax({
			url:'/partner-subscriptions.ltr',
			data: {
				productId: product,
				partnerSubscriptionGroupId: group
			},
			success:function(html){
				loading.stop();
				modal.generate({
					content:html,
					id:'modal-partner-subscriptions',
					callbackOpen:function(){
						$('input:checkbox[name="partnerSubscription"]').click(function(){
							var id = $(this).val()/1;
							var checked = $(this).is(':checked');
							checkboxClick({
								partnerSubscription: id,
								checked: checked,
								productId: product,
								relatedPromotionIdentifier,
								group: front.page()=='basket'?'':group,
								callback: function(){
									if (checked) {
										log.event({
											id: 'to_cart',
											f03: 'cart_step_1',
											f04: id, // brak przekazanego plu do ewentualnego EUROTM7-905 BE
											f05: 'service',
											f06: productPlu,
											f07: 'services_box',
											f08: 'fancybox',
											f09: '',
											f10: 'subscription',
										});
									}
									callback(id, checked);
								}
							});
							log.event({
								"id": "Uslugi K1",
								"f03": document.location.href,
								"f04": "Premium Mobile - Warstwa",
								"f05": "Wybierz - "+$(this).closest('.item').find('.name').text().trim()
							});
							UA.pageview('/K1/abonament-premium-mobile.bhtml');
						});
						checkedCheck(product);
					},
					callbackClose:function(){
						app.logEvent({
							"id": "Uslugi K1",
							"f03": document.location.href,
							"f04": "Premium Mobile -  Warstwa",
							"f05": "Zamknij"
						});
					}
				})
			},
			error:function(){
				modal.alert('Błąd pobierania danych');
			}
		});
	}
	function init(){
		$('body').on('click', '.js-show-partner-subscriptions',function(){
			var group = $(this).data('group');
			var product = $(this).data('product');
			productPlu = $(this).data('product-plu');
			var relatedPromotionIdentifier = $(this).data('related-promotion-identifier');
			loadModal(product, group, relatedPromotionIdentifier, function(id, checked){
				// ewentulny callbck
				broker.trigger('REFRESH_BASKET_VIEW');
			});
		});

		$('body').on('click', '.js-services-next', function() {
			modal.close();
		});
	}

	return {
		checkedCheck,
		init
	};
});
