/**
 * Moduł umozliwia dodanie klasy .show-loading do tagu <html>
 * w przypadku przekierowania lub przeładowania strony
 * @type {module}
 */
app.service('loading',function(console, html, front, cookie){
	var time, $focus;
	var isInit= false;

	function start(){
		if(!isInit){
			$focus = $('<input id="loading-focus" type="text" style="width:1px;height:1px;position:fixed;left:0;bottom:0;"/>').appendTo('body');
			isInit = true;
		}
		html.add('show-loading');
		if(document.activeElement.tagName.toLowerCase() != 'input'){
			$focus.focus().click();
		}
		clearTimeout(time);
		time=setTimeout(function(){
			logError();
		},1000*11);
		//console.log('LOADING start');
	}
	function stop(){
		clearTimeout(time);
		html.remove('show-loading');
		//console.log('LOADING stop');
	}
	function logError(){
		app.logEvent({
			id: 'timeout-loading',
			f03: document.location.href,
			f04: front.page(),
			f05: cookie.get('JSESSIONID')
		});
		UA.interaction('Monitoring','Przekroczne czasu ładowania',document.location.href);
	}
	function redirect(data){
		console.log('LOADING redirect');
		start();
		//remodal blokuje javascript jesli jego animacja się nie zakończy
		requestAnimationFrame(function() {
			if(typeof data == "object"){
				var queryData = $.parseQuery();
				document.location.href = '?'+$.param($.extend({},queryData,data));
			} else {
				document.location.href = data;
			}
		});
	}
	function reload(){
		console.log('LOADING reload');
		start();
		location.reload(true);
	}
	$(window).on('pagehide', function(){
		stop();
	});
	$(window).on('loadingStart', function(){
		start();
	});
	$(window).on('loadingStop', function(){
		stop();
	});
	return {
		start : start,
		stop : stop,
		redirect : redirect,
		reload : reload
	};
});
