/**
 * obsługa schowka - usuwanie, dodawanie - logika w pliku /front/app-modules/productClipboard.js
 */
app.service('clipboardProducts',function(productClipboard, wcClientClipboard, front){
	var $self, $counts;


	function setElements() {
		$self = $('#clipboard-products');
		if (front.katalog) {
			$counts = $('.js-clipboard-products-count');
		} else {
			$counts = $('.js-clipboard-products-count, #account-menu .clipboard em');
		}
		
	}
	function addEvents(){
		$('body').on('click','.js-add-to-clipboard',function(){
			var $element=$(this);
			if(front.katalog){
				wcClientClipboard.addProduct($element.data('product'),function(){
					click($element);
				});
			}else{
				click($element);
			}
		});
		$('body').on('click','.js-delete-from-clipboard',function(){
			remove($(this));
		});
	}
	function remove($element){
		if(front.katalog){
			wcClientClipboard.remove($element,true);
		}else{
			productClipboard.remove($element);
		}
	}
	function click($element){
		productClipboard.click($element);
		updateCount();
	}
	function updateCount() {
		var count=productClipboard.getElements().length;
		if(count>0){
			$self.addClass('visible');
		}else{
			$self.removeClass('visible');
		}
		if (!front.katalog) {
			$counts.text(count > 0 ? count : '');
		} else {
			$counts.text(count);
		}
	}

	function init() {
		productClipboard.init();
		setElements();
		addEvents();
		updateCount();
	}
	return {
		init:init
	};
});
