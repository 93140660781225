app.service('productVariants', function(productCardTest) {
	let $currentVariant = null;

	function setCurrentVariant ( $element ) {
		$currentVariant = $element;
	}

	function hideVariantList () {
		$currentVariant !== null && $currentVariant.removeClass('variant-active');
	}

	function showVariantList () {
		var $currentVariantList = $currentVariant.find('.variant-list'),
			$variants = $currentVariantList.find('.js-variant-item'),
			maxWidth = 450,
			totalWidth = 0;

		$variants.each(function() {
			var elementWidth = $(this).outerWidth(true),
				nextValue = totalWidth + elementWidth;
			if(nextValue < maxWidth) {
				totalWidth += elementWidth;
			} else {
				return false;
			}
		});

		$currentVariant.addClass('variant-active');
		$currentVariantList.width(totalWidth);
		calculateMaxWidth($variants, totalWidth);
	}

	function calculateMaxWidth ($variants, boxWidth) {
		$variants.each(function() {
			var $element = $(this);
			if($element.outerWidth(true) > boxWidth) {
				var width = boxWidth - parseInt($element.css('margin-right')) - parseInt($element.css('margin-left'));
				$element.css({
					'max-width': width
				});
				$element.addClass('long-name');
			}
		});
	}

	function toggleVariantList ( $element ) {
		if( $element.hasClass('variant-active') ) {
			hideVariantList();
		} else {
			hideVariantList();
			setCurrentVariant( $element );
			showVariantList();
		}
	}

	function logEvent ( $element ) {
		var data = ($element.data('event') || '').split("|");

		if( data.length===6 ) {
			UA.pageInteraction( 'Wariantowanie - ' + data[5], $.trim($element.attr('title')) );

			app.logEvent({
				id: 'wariantowanie',
				f03: data[0],
				f04: data[1],
				f05: data[2],
				f06: data[3],
				f07: data[4]
			});
		}
	}

	function setEvents (){
		$('body').on('click', function(event) { // ukrywa listę wariantow jeśli kliknięto obok
			!$(event.target).closest('.js-variant').length && hideVariantList();
		});

		$('body').on('click', '.js-variant', function () {
			toggleVariantList( $(this) ); // pokaż/ukryj listę wariantów dla klikniętego/obecnego elementu
		});

		// .variant-button, oraz .variant-current - dodano na potrzeby testów A/B
		$('body').on('click', '.js-variant-item, .js-variant-button', function() {
			logEvent( $(this) );
		});
	}

	function init () {
		if(productCardTest.enabled) return;
		setEvents();
	}

	return {
		init: init
	};

});
