/**
 * Moduł obsługujący Tooltip
 * @type {module}
 */
app.service('tooltip', function(html) {
	var $self,
		//$linkTip,
		tooltipArrowMargin = 22,
		tooltipArrowHeight = 10,
		bodyMargin = 100,
		position = {},
		currentElement,
		timeoutTooltip;

	/**
	 * cache elementu DOM
	 */
	function setElements() {
		//$linkTip = $('.js-show-tooltip');
	}

	function createTipContainer() {
		$self = $('<div class="tooltip"></div>');
		$('body').append($self);
	}
	// $(document).on('pageChange',function (){
	// 	initTooltip();
	// });
	function initTooltip() {
		setElements();
		$('body').on('mouseover', '.js-show-tooltip', function(){
			currentElement = $(this);
			showTooltip();
		}).on('mouseleave', '.js-show-tooltip', function(){
			closeTooltip();
		});

	}

	function closeTooltip() {
		clearTimeout(timeoutTooltip);
		timeoutTooltip = setTimeout(function() {
			if (!$self.hasClass('hover')) {
				hideTooltip();
			}
		}, 500);
	}

	function showTooltip() {
		html.add('show-tooltip');

		var tooltipId = currentElement.data('tooltip-id');

		setTooltipText(tooltipId);
		setTooltipPosition();

		$self.hover(function() {
			$self.addClass('hover');
		}, function() {
			$self.removeClass('hover');
			closeTooltip();
		});
	}

	function hideTooltip() {
		setTooltipPosition();
		html.remove('show-tooltip');
		$self.removeClass('hover');
	}

	function setTooltipText(id) {
		var text = $('#'+id).html();
		$self.html(text);
	}

	function setArrowDirection(direction) {
		$self.attr('data-arrow-direction', direction);
	}

	function setArrowPlace(place) {
		$self.attr('data-arrow-place', place);
	}

	function reposition(place) {
		/*
		 * domyslną pozycją jest top + right dlatego tych wariantów nie trzeba pozycjonować
		 */

		var currentElementCoordinates = getCurrentElementCoordinates();
		var currentElementSize = getCurrentElementSize();
		var tooltipSize = getTooltipConainterSize();

		if (place == 'left') {
			position.left = currentElementCoordinates.left - tooltipSize.width + currentElementSize.width + tooltipArrowMargin;
			setArrowPlace('right');
		}

		if (place == 'bottom') {
			position.top = currentElementCoordinates.top + currentElementSize.height + tooltipArrowHeight;
			setArrowDirection('top');
			setArrowPlace('left');
		}

		applyPosition(position);
	}

	function getCurrentElementCoordinates() {
		var offset = currentElement.offset();

		return {
			top: offset.top,
			left: offset.left
		};
	}

	function getCurrentElementSize() {
		return {
			width: currentElement.width(),
			height: currentElement.height()
		};
	}

	function getTooltipConainterSize() {
		return {
			width: $self.outerWidth(true),
			height: $self.outerHeight(true)
		};
	}

	function setDefaultPosition() {
		var currentElementCoordinates = getCurrentElementCoordinates();
		var tooltipSize = getTooltipConainterSize();
		var currentElementSize = getCurrentElementSize();

		position = {
			top: currentElementCoordinates.top - tooltipSize.height - tooltipArrowHeight,
			left: currentElementCoordinates.left - tooltipArrowMargin
		};

		setArrowDirection('bottom');
		setArrowPlace('left');

		applyPosition(position);
	}

	function setTooltipPosition() {
		setDefaultPosition();

		var tooltipSize = getTooltipConainterSize();
		var top = $self.offset().top - $(window).scrollTop();
		var windowWidth = $(window).width();

		if (top < bodyMargin) {
			reposition('bottom');
		}

		if (windowWidth - ($self.offset().left + tooltipSize.width) < bodyMargin) {
			reposition('left');
		}
	}

	function applyPosition(pos) {
		$self.css(pos);
	}

	function init() {
		createTipContainer();
		initTooltip();
	}

	return {
		init
	};
});
