app.service('footerReveal', function() {
	var $self, $img, windowHeight, height;

	function isElementInViewport( $element ) {
		var rect = $element[0].getBoundingClientRect();
		return rect.top >= 0 && rect.bottom <= windowHeight + height;
	}

	function setEvents() {
		var $window = $(window);
		$window.on('scroll', function() {
			if( isElementInViewport( $self ) ) {
				$self.addClass('is-visible');
			} else {
				$self.removeClass('is-visible');
			}
		});
		$window.on('resize', function() {
			setView();
		});
	}

	function getImageHeight( callback, imgUrl ) {
		var ratio;
		var img = new Image();
		img.onload = function() {
			ratio = this.height / this.width;
			height = ratio * $(window).width();
			callback( height );
		};
		img.src = imgUrl;
	}

	function setView() {
		var imgUrl = $img.attr('data-original');
		getImageHeight(function( imgHeight ) {
			height = imgHeight;
			windowHeight = $(window).height();
			$img.height( height );
			$('#main-footer').css( 'marginBottom', height );
		}, imgUrl);
	}

	function setElements() {
		$self = $(".banners-scroll_footer");
		$img = $self.find('.banner-photo img');
	}

	function init() {
		setElements();
		if( $self.length && $img.length && $img.length==1) {
			setView();
			setEvents();
		}else{
			$self.remove();
		}
	}

	return {
		init: init
	};
});
