app.service('nodeView', function(loading, ajaxPage) {
	var $self;
	function setElements() {
		$self=$('#node');

	}

	function setEvents() {
		$('.category-list-box-link', $self).click(function(event){
			ajaxPage.load({
				url: $(this).attr('href'),
				beforeLoad:function(){
					$.scrollTo($('#products'));
				}
			});
			event.preventDefault();
		});
	}

	function init() {
		setElements();
		setEvents();
	}

	return {
		init: init
	};
});
