app.service('suggestedProductsSection', function (lastProducts, lazyLoad, viewPoint) {

	let $self, $content, $suggestedProductsList;

	function init() {
		setElements();
		setEvents();
		const productIds = lastProducts.getProducts();
		loadSuggestedProducts(productIds).then(response => {

			if (response.isEmpty) {
				$self.addClass('is-hidden');
				return;
			}

			$suggestedProductsList.html(response.html);

			new PreviewGallery({
				selector: $suggestedProductsList,
				perPage: {
					0: 4.5,
					1231: 6
				},
				navigation: $content.find(".suggested-products-list-nav"),
				onInit() {
					lazyLoad.update();
				},
				onResize() {
					lazyLoad.update();
				},
				onChange() {
					lazyLoad.update();
				}
			});

			setUA();
		})
	}

	function setElements() {
		$self = $('#suggested-products');
		$content = $self.find('.section-content');
		$suggestedProductsList = $self.find('.suggested-products-list');
	}

	function setEvents() {
		$self.on('click', '.js-clear-suggested-products', function handleClearSuggestedProductsClick() {
			lastProducts.clear();
			$self.addClass('is-hidden');
		});
	}

	function loadSuggestedProducts(productIds) {

		let requests = loadAllProducts(productIds);

		return $.when(...requests)
			.then((...responses) => {
				const response = getResponse(responses);
				return {
					isEmpty: response === "",
					html: response
				};
			})
			.fail(() => {
				$content.text("[suggestedProducts] Błąd inicjalizacji modułu. Odśwież stronę aby spróbować ponownie.");
			})
			.always(() => {
				$self.removeClass('loading');
			});
	}

	function getResponse(responses) {
		if (typeof responses[0] === "string") { // gdy jest tylko jeden produkt obiekt responses wygląda trochę inaczej
			return responses[0].trim();
		}

		return responses.map(p => p[0]).join('').trim();
	}

	function loadAllProducts(productIds) {
		return productIds.map(productId =>
			$.ajax({
				url: '/web-cached/search-suggestions/single-suggested-product.ltr',
				data: {
					productId
				}
			})
		);
	}

	function setUA() {
		viewPoint.set({
			element: $self,
			height: 0.6 * $self.height(),
			callback() {
				UA.searchInteraction('Zerowe wyniki wyszukiwan', 'Ostatnio ogladane', 'Wyswietlenie');
			}
		});

		$suggestedProductsList.on('click', 'a', function () {
			const productCode = $(this).closest('.product-carousel-item').find('.productPlu').val();
			UA.searchInteraction('Zerowe wyniki wyszukiwan', 'Ostatnio ogladane', 'Klikniecie', productCode);
		});
	}

	return {
		init
	};
});