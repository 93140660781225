app.service('brandView', function (filterBox, nodeTree, lazyLoad, autocompleteInfo, pageConfig ) {
	var $self, config;

	function setElements(){
		$self = $("#brand");
		config = pageConfig.getData('brand');
	}

	function setEvents(){


	}

	function init() {
		setElements();
		setEvents();
		autocompleteInfo.logResult('brand', config);
		filterBox.init();
		nodeTree.init();
		new PreviewGallery({
			selector: $('.articles-list'),
			perPage: {
				0: 4
			},
			endOffset: 10,
			navigation: $('.articles-carousel-nav'),
			swipe: true,
			onChange() {
				lazyLoad.update();
			},
			onResize() {
				lazyLoad.update();
			},
			onInit: function onInit() {
				lazyLoad.update();
			}
		});

	}
	return {
		init
	};
});
