app.service('mainPageView', function(mainPageRecommendations, newMainPageView, mainPageTopSlider, lastProducts, front, viewPoint, mainPageInteraction) {
	var $bannersList, $columnPresetButtton, $horizontalBanners;

	function setElements() {
		$bannersList = $('#main-page-columns');
		$columnPresetButtton = $('.js-columns-preset', $bannersList);
		$horizontalBanners = $('#horizontal-banners');
	}

	function setEvents() {
		$columnPresetButtton.on('click', function () {
			var preset = $(this).closest('.box').index()+1;

			$columnPresetButtton.not(this).removeClass('active');
			$bannersList.removeClass();

			if ($(this).hasClass('active')){
				$(this).removeClass('active');
			}else{
				$(this).addClass('active');
				$bannersList.addClass('set-'+preset);
			}
		});

		viewPoint.set({
			element: $horizontalBanners,
			height: 0.6*$horizontalBanners.height(),
			callback: function(){
				mainPageInteraction.set('Sekcja USP - wyswietlenie');
			}
		});
	}
	function newVersion(){
		newMainPageView.init();
	}
	function oldVersion(){
		mainPageInteraction.init(lastProducts.getProducts().length > 0);
		mainPageRecommendations.init();
	}

	function init() {
		mainPageTopSlider.init();
		if (front.katalog) {
			oldVersion();
		} else {
			newVersion();
		}
		setElements();
		setEvents();
	}

	return {
		init
	}
});
