app.service('customerRecommendations', function (recommendationsApi, lazyLoad, localStorage, broker, viewPoint, mainPageInteraction, log) {

	let $self;
	let recommendationsLoaded = false;

	function loadRecommendations(productId) {

		$self.addClass('is-visible loading');

		$self.find('.customer-recommendations').addClass('is-visible');

		recommendationsApi.fetch(productId)
			.then(showRecommendations)
			.fail(onError)
	}

	function showHistoryMessage() {
		$self.addClass('is-visible');
		$self.find('.recommendations-history').addClass('is-visible');
	}

	function setEvents() {
		$('body')
			.on('click', '.js-mark-as-bought', handleMarkAsBoughtClick)
			.on('click', '.js-undo-mark-as-bought', handleUndoMarkAsBoughtClick);
	}

	function handleMarkAsBoughtClick() {
		$self.find('.customer-recommendations').addClass('is-hidden').slideUp();
		$self.find('.recommendations-history').addClass('is-visible');

		const position = $self.offset().top - 80;
		$(window).scrollTop(position);

		const groupId = $(this).data('group-id');

		localStorage.setItem('top_suggestion_remember', groupId);
	}

	function handleUndoMarkAsBoughtClick() {
		localStorage.removeItem('top_suggestion_remember');
		$self.find('.recommendations-history').removeClass('is-visible');

		if (recommendationsLoaded) {
			$self.find('.customer-recommendations').removeClass('is-hidden').slideDown();
		} else {
			broker.trigger("SHOW_TOP_SUGGESTION")
		}
	}

	function showRecommendations(recommendationsHtml) {

		$self.find('.customer-recommendations').html(recommendationsHtml);

		initCarousel();

		$self.find('.recommendations-history').removeClass('is-visible');

		$self.removeClass('loading');

		recommendationsLoaded = true;

		initUA();

	}

	function initCarousel() {
		new PreviewGallery({
			selector: $self.find('.recommendation-list'),
			perPage: {
				0: 3.25,
				1231: 4.5
			},
			endOffset: 20,
			navigation: $self.find('.recommendations-carousel-nav'),
			swipe: true,
			onInit: function onInit() {
				lazyLoad.update();
			}
		});
	}

	function onError() {

		const errorMessage = "Błąd pobierania rekomendacji";
		$self.html(errorMessage);
		console.error(errorMessage);
	}

	function initUA(){

		let label=[];
		if($self.find('.product-preview').length){
			label.push('Produkt historyczny')
		}
		if($self.find('.recommended-product-preview')){
			label.push('Porownaj')
		}
		if($self.find('.recommendations')){
			label.push('Polecane');
		}

		viewPoint.set({
			element: $self,
			height: 0.6*$self.height(),
			callback: function(){
				mainPageInteraction.set('Sekcja rekomendacji - wyswietlenie', label.join('|'));
			}
		});
		
		$self.on('click', '.product-preview .product-photo', function(){
				mainPageInteraction.set('Sekcja rekomendacji - klikniecia', 'Produkt historyczny|Zdjecie');
			})
			.on('click', '.product-preview .details-button', function(){
				mainPageInteraction.set('Sekcja rekomendacji - klikniecia', 'Produkt historyczny|Szczegoly');
			})
			.on('click', '.product-preview .product-name', function(){
				mainPageInteraction.set('Sekcja rekomendacji - klikniecia', 'Produkt historyczny|Naglowek');
			})
			.on('click', '.product-preview .mark-as-bought', function(){
				mainPageInteraction.set('Sekcja rekomendacji - klikniecia', 'Produkt historyczny|Nie interesuja mnie');
			})
			.on('click', '.compare-product', function(){
				mainPageInteraction.set('Sekcja rekomendacji - klikniecia', 'Porównaj');
			})
			.on('click', '.recommended-product-preview .product-photo', function(){
				mainPageInteraction.set('Sekcja rekomendacji - klikniecia', 'Produkt do porownania|Zdjecie');
			})
			.on('click', '.recommended-product-preview .details-button', function(){
				mainPageInteraction.set('Sekcja rekomendacji - klikniecia', 'Produkt do porownania|Szczegoly');
			})
			.on('click', '.recommended-product-preview .product-name', function(){
				mainPageInteraction.set('Sekcja rekomendacji - klikniecia', 'Produkt do porownania|Naglowek');
			})
			.on('click', '.recommendations-carousel .js-carousel-next', function(){
				mainPageInteraction.set('Sekcja rekomendacji - klikniecia', 'Polecane|Strzalka - Nastepny');
			})
			.on('click', '.recommendations-carousel .js-carousel-prev', function(){
				mainPageInteraction.set('Sekcja rekomendacji - klikniecia', 'Polecane|Strzalka - Poprzedni');
			})
			.on('click', '.recommendations-carousel .recommendation-list-item', function(){
				mainPageInteraction.set('Sekcja rekomendacji - klikniecia', 'Polecane|Boxy produktowe');
			})
			.on('click', '.recommendations .mark-as-bought', function(){
				mainPageInteraction.set('Sekcja rekomendacji - klikniecia', 'Polecane|Nie interesuja mnie');
			})
			.on('click', '.recommendations-links a', function(){
				var category = $(this).closest('.recommendations').data('category');
				var label = 'Polecane|Linki|'+category+'|';
				if($(this).hasClass('all-products')){
					label+=$(this).text();
				}else{
					label+=$(this).find('span').text();
				}
				mainPageInteraction.set('Sekcja rekomendacji - klikniecia', label);
			});
	}

	function init() {
		$self = $('#customer-recommendations-section');
		setEvents();
	}

	return {
		init,
		loadRecommendations,
		showHistoryMessage
	};
});
