/**
 * Moduł sterujący dolną belką porownywarki produktow,
 * odpowiada m.in. za wyswietlenie modala, aktualizacje licznika na belce
 */
app.service('compareProducts',function(console, front, ajaxPage, productCompare, modal, lazyLoad, pageConfig){
	var $self, $counts, $simpleList, $goToCompareButton, $showMore, $comparePopup, $compareList, $compareListLis, $category;
	var simpleProducts = [], detailedProducts = [], categoryString='', currentCategory=false;
	var changeStateInterval=false;
	var firstProducts;

	function setElements() {
		$self=$('#compare-products');
		$counts=$('.js-compare-products-count');
		$simpleList=$('#compare-product-list', $self);
		$goToCompareButton = $('.js-go-to-compare-from-bar', $self);
		$showMore=$('.js-show-more', $self);
		$compareList = $('#compare-products-list');
		$comparePopup = $('#compare-products-modal');
		$modal=$comparePopup.remodal();
		$category=$('#compare-category');
	}
	function goToCompare(products, category){
		var url='/porownanie.bhtml?';
		var params=[];
		if(products.length<2){
			modal.alert('Wybierz co najmniej dwa Towary');
		}else{
			$.each(products,function(i,n){
				params.push('p'+(i+1)+'='+n);
			});
			if (front.page() == 'product-card' || front.page() == 'product-detail') {
				localStorage.removeItem('LPBC-' + category);
			}
			ajaxPage.load({
				url: url+params.join('&'),
				scrollTop: false,
				beforeLoad: function() {
					$.scrollTo(0);
				}
			});
		}
	}
	function renderList(category, noRebuildCategory) {

		if(!category){
			noRebuildCategory=false;
			category=currentCategory;
		}
		currentCategory=category=parseInt(category);
		var products = productCompare.getProducts(category);

		if(products.length){
			if(!noRebuildCategory){
				generateCategory(category);
			}

			$compareList.html('');
			$(Array(3)).each(function (i) {
				$compareList.append('<li class="js-UA-product"><div class="'+((products.length<=i)?'empty':'loading')+'"></div></li>');
			});

			$compareListLis = $('li', $compareList);

			$(products).each(function (index, productId) {
				loadDetailed(index, productId);
			});
		} else {
			var lastCategory = productCompare.getCurrentGroup();
			if(productCompare.getProducts(lastCategory).length > 0){
				currentCategory=lastCategory;
				renderList();
			} else if($modal.getState()==="opened"){
				$modal.close();
			}
		}
	}
	function changeCategory(category){
		renderList(category,true);
	}
	function generateCategory(selectedCategory){
		var groups=productCompare.getGroups();
		if(!selectedCategory){
			selectedCategory=(groups.length)?groups[0]:false;
		}
		if(groups.join('')!==categoryString){
			categoryString=groups.join('');
			$category.select3('disable');
			$.ajax({
				url: '/products-bottom-bar.ltr',
				data: {
					groupId: groups
				},
				success: function (result) {
					$comparePopup.removeClass('disabled-category');
					var elements=[];
					$(groups).each(function(i,n){
						elements.push({value:n,text:result.productGroupsBottomBar[i].productGroupName});
						$('.js-close-compare-modal').attr('data-' + n, result.productGroupsBottomBar[i].linkName);
					});
					$category.select3('enable');
					$category.select3({elements:elements, value:selectedCategory});
				}
			});
		}
	}

	function openModal() {
		renderList();
		$modal.open();
	}
	function loadDetailedSuccess(index, html) {
		var products = productCompare.getProducts(currentCategory);

		$compareListLis.eq(index).html(html).data('product', products[index]);

		checkRequestContent(html, products[index]);
		lazyLoad.check();
	}
	function loadDetailed(index, productId) {
		if (productId in detailedProducts) {
			loadDetailedSuccess(index, detailedProducts[productId]);
		} else {
			$.ajax({
				url: '/products-bottom-bar.ltr',
				data: {
					productId: productId,
					detailed: true
				},
				success: function (result) {
					detailedProducts[productId] = result;
					loadDetailedSuccess(index, result);
				}
			});
		}
	}
	function inputClick(product, group, plu){
		productCompare.click(product, group, plu);
		renderSimpleList();
		updateCount();
	}
	function redirect() {
		var value = $category.val();
		var $button = $('.js-close-compare-modal');
		var currentPage = front.page();
		var currentGroup = false;
		if(currentPage == 'product'){
			currentGroup = pageConfig.getData('product').groupId;
		} else if(currentPage == 'leaf'){
			currentGroup = pageConfig.getData('productsList').groupId;
		}
		if( $.trim(value) === $.trim(currentGroup) ) {
			$modal.close();
		} else {
			var url = $button.data(value);
			ajaxPage.load({
				url: url,
				scrollTop: false,
				beforeLoad: function() {
					$.scrollTo(0);
				}
			});
		}
	}
	function setEvents() {
		$goToCompareButton.click(function(){
			UA.interaction('Belka', 'Porównanie', 'Do porównania');
			goToCompare(productCompare.getProducts(), productCompare.getCurrentGroup());
		});
		$showMore.click(function(){
			UA.interaction('Belka', 'Porównanie', 'Więcej');
			openModal();
		});
		$comparePopup.on('click', '.js-delete-product' ,function(){
			UA.interaction('Belka', 'Warstwa Porównania', 'Usuń');
			deleteProduct(parseInt($(this).data('product')), parseInt($(this).data('group')));
			return false;
		});

		$comparePopup.on('click', '.js-close-compare-modal', function(){
			UA.interaction('Belka', 'Warstwa Porównania', 'Wybieraj dalej');
			redirect();
		});
		$comparePopup.on('click', '.js-go-to-compare-from-modal', function(){
			UA.interaction('Belka', 'Warstwa Porównania', 'Idź do porównania');
			goToCompare(productCompare.getProducts(currentCategory), currentCategory);
		});
		$category.change(function(){
			UA.interaction('Belka', 'Warstwa Porównania', 'Zmień kategorię');
			changeCategory($(this).val());
		});
		$category.select3();
	}
	function deleteProduct(product,group){
		productCompare.click(product, group);
		renderSimpleList();
		updateCount();
		renderList();
	}
	function renderSimpleList(){
		var products = productCompare.getProducts();
		firstProducts=getActiveProductString();
		$simpleList.html('');
		if (products.length) {
			$(products).each(function (index, productId) {
				$simpleList.append('<li class="js-UA-product"></li>');
				loadSimple(index, productId);
			});
			$self.addClass('visible');
			if (products.length>1){
				$self.removeClass('disable-go-to-compare');
			}else{
				$self.addClass('disable-go-to-compare');
			}
			if (products.length>1){
				$self.removeClass('disable-go-to-compare');
			}else{
				$self.addClass('disable-go-to-compare');
			}
			lazyLoad.check();
		}else{
			$self.removeClass('visible');
		}
	}
	function loadSimple(index, productId) {
		if (productId in simpleProducts) {
			loadSimpleSuccess(index, simpleProducts[productId]);
		} else {
			$.ajax({
				url: '/products-bottom-bar.ltr',
				data:{
					productId: productId
				},
				success: function(result){
					simpleProducts[productId] = result;
					loadSimpleSuccess(index, result);
				}
			});
		}
	}
	function loadSimpleSuccess(index, html) {
		var products = productCompare.getProducts(currentCategory);
		$('li',$simpleList).eq(index).html(html);
		checkRequestContent(html, products[index]);
	}

	function checkRequestContent(html, productId) {
		if (!$.trim(html).length) {
			productCompare.removeProduct(productId, currentCategory);
			renderSimpleList();
			updateCount();
			renderList(currentCategory, true);
		}
	}
	function updateCount() {
		var groups = productCompare.getGroups();
		$counts.text(groups.length);
		if (groups.length>1){
			$self.removeClass('disable-modal');
		}else{
			$self.addClass('disable-modal');
		}
	}
	function getComparedProducts() {
		var products = productCompare.getProducts();
		currentCategory=productCompare.getCurrentGroup();
		if (products.length) {
			renderSimpleList();
			updateCount();
			$self.addClass('visible');
		}
	}
	function getActiveProductString(){
		return productCompare.getProducts().join('');
	}
	function trackChanges(){
		clearInterval(changeStateInterval);
		changeStateInterval=setInterval(function(){
			var currentProducts = getActiveProductString();
			if(currentProducts!==firstProducts){
				renderSimpleList();
				updateCount();
				console.log('wykryto zmianę aktualnej listy produktow');
			}
		},5000);
	}
	function clear(){
		productCompare.clear();
	}
	function init() {
		productCompare.init();
		setElements();
		setEvents();
		getComparedProducts();

		trackChanges();
	}
	return {
		init:init,
		click:inputClick,
		clear: clear
	};
});
