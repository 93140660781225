//INFO: Serwis 'cart' służy tylko do odświeżania koszyka, 'cartManager' wykonuje operacje na koszyku
app.service('cartBox',function($global, console, cookie, html, loading, callback, front, pageConfig, lazyLoad, sessionStorage, modal, broker) {
	var $self, $added;
	var ajax=false;
	var countRefresh=0;
	var isInit=false;

	function emptyCartCookie() {
		countRefresh++;
		if (front.katalog) {
			$self.empty().html('<span><span class="empty">0</span></span>');
		} else {
			$self.empty().html('<a class="empty" href="/cart.bhtml"></a>');
		}
	}
	function initRefresh() {
		var cartCookie = (front.euro || front.katalog)?'euroCart':'oleoleCart';
		if (cookie.isEmpty(cartCookie)) {
			emptyCartCookie();
		} else {
			refresh();
		}
	}
	function show(){
		$self.addClass('show-menu');
	}
	function hide(){
		$self.removeClass('show-menu');
	}
	function isShow(){
		return $self.hasClass('show-menu');
	}
	function refreshSuccess(response,text){
		$self.empty().html(response);
		$added=$self.find('.cart-added');
		$self.attr('data-quantity', getCount());

		if (text && text!=='') {
			$added.show().find('span').html(text);
		} else {
			$added.hide();
		}
		html.add('load-cart-box');
		countRefresh++;
		if(countRefresh>1){
			$self.addClass('refreshed');
			setTimeout(function(){
				$self.removeClass('refreshed');
			},2000);
		}
		if(sessionStorage.empty('bS12') || countRefresh>1){			//item do sprawdzania czy przy pierwszej wizycie w sesji został wysłany stan koszyka
			sessionStorage.setItem('bS12','1');
			UA.refreshBasket(getBasketConfig('items'));
		}
		if(front.katalog && getBasketConfig().clearCart){
			broker.trigger('WC_FORCE_CLEAR_CART');
		}
		$(document).trigger('cartReady', getBasketConfig());
	}
	function getBasketConfig( config ) {
		if(config){
			return pageConfig.getData( 'basket' )[ config ];
		}else{
			return pageConfig.getData( 'basket' );
		}
	}

	function getItemProperty( id, prop ) {
		return getBasketConfig('items')
			.filter(function(item) {
				return item.id === id;
			})
			.map(function(item) {
				return item[prop];
			})[0];
	}

	function getElements() {
		var items = getBasketConfig('items');
		if(items){
			return items.map(function(item) {
				return item.id;
		});
		} else {
			return [];
	}
	}

	function getAccessories(id) {
		return getItemProperty(id, 'accessories');
	}

	function getServices(id) {
		return getItemProperty(id, 'services');
	}

	function getPartnerSubscriptions(id) {
		return getItemProperty(id, 'partnerSubscriptions');
	}

	function getSubscriptionServices(id) {
		const subscriptionServices = getItemProperty(id, 'subscriptionServices');
		return !!subscriptionServices ? subscriptionServices : [];
	}

	function getWarranty(id) {
		return {
			warrantyId: getItemProperty(id, 'warranty'),
			warrantyPaymentType: getItemProperty(id, 'warrantyPaymentType')
		};
	}

	function getCount() {
		return getBasketConfig('quantity');
	}

	function isOutlet() {
		return getBasketConfig('isOutlet');
	}
	function abortRefresh(){
		if(ajax){
			ajax.abort();
		}
	}
	function refresh(text) {
		if ($self.length) {
			abortRefresh();
			ajax=$.ajax({
				url: '/refresh-cart.ltr',
				method: 'POST',
				cache: false,
				success: function(html) {
					refreshSuccess(html,text);
				},
				error: function(){
					console.log('refreshCartBox ERROR');
				}
			});
		}
	}
	function setEvents() {
		if (front.katalog) {
			$self.on('click','.js-cart',function(event){
				if(isShow()){
					hide();
				}else{
					show();
					lazyLoad.update();
				}
				event.preventDefault();
			});
			$('body').click(function(event){
				if( !$(event.target).closest('#cart-box').length ) {
					hide();
				}
			});
		}
	}
	function init(){
		if(!isInit){
			$self = $('#cart-box');
			$self.attr('data-quantity', getCount());
			setEvents();
			initRefresh();
			isInit=true;
		}
	}
	$global('cartRefresh',refresh);
	return {
		init,
		abortRefresh,
		refresh,
		getElements,
		getAccessories,
		getServices,
		getWarranty,
		getPartnerSubscriptions,
		getSubscriptionServices,
		getCount,
		isOutlet
	};
});
