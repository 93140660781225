app.service('obligatoryChangePassword', function (passwordInvalid, validate) {

    function setEvents() {
        passwordInvalid.init($('#password'), $('.password-invalid'), $('#password').closest('.row'), $('#email-hidden'));
        validate.task('obligatoryChangePassword');
    }

    function init() {
        setEvents();
    }

    return {
        init
    };
})