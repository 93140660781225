app.service('productDetailsModal', function (cartManager, modal, loading, pageConfig, log) {
	var params = {
		productId: false,
		productPlu: false,
		parentId: false,
		callbackAdded: function () { }
	}

	function add() {
		let logEventData = {
			id: 'to_cart',
			f03: 'cart_step_0',
			f04: params.productPlu,
			f05: 'akc',
			f06: '',
			f07: 'accessory_card',
			f08: 'fancybox',
			f09: 'new',
			f10: '',
		}
		var precart = pageConfig.getData('precart');
		var basket = pageConfig.getData('basket');
		if (precart) { // K0
			logEventData.f06 = precart.productPlu;
			logEventData.f10 = precart.isInstalmentPrice ? 'installment' : 'cash';
		} else {
			var parent = basket.items.filter(function (e) {
				return e.id == params.parentId;
			});
			if (parent.length) {
				parent = parent[0];
				logEventData.f06 = parent.plu;
				logEventData.f10 = parent.isInstalmentPrice ? 'installment' : 'cash';
			}
		}

		cartManager.simpleAddProduct({
			productId: params.productId,
			addedFrom: 'CART_EDIT'
		}, params.callbackAdded, logEventData);

		// TODO Dostosować analitykę w momencie gdy będzimey używać modala na K0
		// if (parent.length) {
		// 	parent = parent[0];
			// let product = parent.relatedAccessory.filter(function (e) {
			// 	return e.id == productId;
			// });
		// 	if (product.length) {
		// 		product = product[0];
		// 		var data = {
		// 			item_name: product.name,//nazwa produktu (wartość zmienna)
		// 			item_id: product.plu, //numer PLU (wartość zmienna)
		// 			price: product.price, //cena z uwzględnieniem przeceny! (wartość zmienna)  
		// 			item_brand: product.brand,  //marka (wartość zmienna)       
		// 			item_list_name: 'Koszyk - Akcesoria', // nazwa listy produktowej (stała wartość)
		// 			item_list_id: 'Koszyk', //identyfikator listy produktowej (stała wartość)
		// 			dimension31: "Towar", //rodzaj produktu (Towar | Usługa | Ubezpieczenie) (wartość zmienna)
		// 			dimension14: parent.name, //item_name produktu podstawowego
		// 			dimension15: parent.plu //item_id produktu podstawowego
		// 		}
		// 		if (product.oldPrice.length) { 
		// 			data.metric1 = product.oldPrice; //oryginalna cena produktu bez zniżki (wartość zmienna)
		// 		}
		// 		if (product.nodes) {
		// 			if (product.nodes[0]) {
		// 				data.item_category = product.nodes[0].name; //Kategoria produktu (zmienna wartość)    
		// 				if (product.nodes[1]) {
		// 					data.item_category2 = product.nodes[1].name; //Kategoria produktu 2 (zmienna wartość)     
		// 					if (product.nodes[2]) {
		// 						data.item_category3 = product.nodes[2].name; //Kategoria produktu 3 (zmienna wartość)   
		// 					}
		// 				}
		// 			}
		// 		}
		// 		if (parent.nodes) {
		// 			if (parent.nodes[0]) {
		// 				data.dimension16 = parent.nodes[0].name; //Kategoria produktu (zmienna wartość)    
		// 				if (parent.nodes[1]) {
		// 					data.dimension17 = parent.nodes[1].name; //Kategoria produktu 2 (zmienna wartość)     
		// 					if (parent.nodes[2]) {
		// 						data.dimension18 = parent.nodes[2].name; //Kategoria produktu 3 (zmienna wartość)   
		// 					}
		// 				}
		// 			}
		// 		}
		// 		UA.push({
		// 			event: "add_to_cart",
		// 			ecommerce: {
		// 				items: [
		// 					data
		// 				]
		// 			}
		// 		});
		// 	}
		// }
	}
	function loadAccessoryDetails() {
		loading.start();
		$.ajax({
			url: '/web-cached/product-detail/product-details-box.ltr',
			data: {
				productId: params.productId,
			},
			success: function (result) {
				loading.stop();
				modal.generate({
					id:'modal-product-details',
					content: result,
					modifier: 'remodal-full-width',
					callbackOpen: function () {
						$('#modal-product-details .js-add-product-from-details').on('click', function () {
							add($(this).data('product'));
						});
					}
				});
				UA.interaction('Koszyk - K1','Warstwa szczegółów produktu','Wyświetlenie');
			},
			error: function () {
				loading.stop();
				modal.alert('Błąd pobierania danych o akcesorium');
			}
		});
	}
	function open(data) {
		params.productId = data.productId ? data.productId : false;
		params.productPlu = data.productPlu ? data.productPlu : false;
		params.parentId = data.parentId ? data.parentId : false;
		params.callbackAdded = data.callbackAdded ? data.callbackAdded : function () { };
		if (params.productId) {
			loadAccessoryDetails();
		}
	}
	return {
		open
	};
});
