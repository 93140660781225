app.service('bannerEars',function(front){
	var $self, $leftEar, $rightEar, $window, $ears;
	var $flex, flexOn;

	function setBodyBackgroundColor() {
		const colorCode = $self.find('img').first().attr('alt');

		if (colorCode) {
			$('body').css('background-color', colorCode);
		}
	}

	function rebuild(){
		if(flexOn){
			var windowWidth=$window.width();
			var pageWidth=$self.width();
			var width=0;
			var height=$window.height();

			if(windowWidth>pageWidth){
				width=(windowWidth-pageWidth)/2;
				$flex.css({
					width:width,
					height:height
				});
			}
		}
	}
	function checkFlex(){
		$flex=$ears.filter('.flex');
		flexOn=$flex.length;
	}
	function ajaxRebuild() {
		setElements();
		checkFlex();
		rebuild();
	}
	function setElements(){
		$self=$('#ear-box');
		$leftEar=$('#left-ear', $self);
		$rightEar=$('#right-ear', $self);
		$window=$(window);
		$ears=$('#left-ear, #right-ear', $self);
	}
	function setEvents(){
		$window.on('resize',function(){
			rebuild();
		});
	}
	function init() {
		setElements();
		setEvents();
		checkFlex();
		rebuild();
		if (front.page() === 'main-page') {
			setBodyBackgroundColor();
		}
	}
	return {
		init:init,
		ajaxRebuild:ajaxRebuild
	};
});
