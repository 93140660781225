app.service('productInteractionSaver', function (productInteractionStorage) {

	function saveVisit(data) {

		const event = productInteractionStorage.addEvent({
			type: 'VISIT',
			data: {
				date: $.now(),
				plu: data.plu,
				productId: data.id,
				groupId: data.groupId,
				groupName: data.groupName,
				visitDuration: 0
			}
		});

		const interval = 10000; // 10s

		setInterval(() => {
			if(!document.hidden) { // jeżeli zakładka jest aktywna
				updateLastVisitDuration(event.id, interval/100);
			}
		}, interval);
	}

	function updateLastVisitDuration(eventId, interval) {

		const lastVisitEvent = productInteractionStorage.findEvent(eventId);

		if (lastVisitEvent == null) return;

		const event = $.extend(true, {}, lastVisitEvent, {
			data: {
				visitDuration: lastVisitEvent.data.visitDuration + interval
			}
		});

		productInteractionStorage.updateEvent(event);
	}

	function saveEvent(type, data) {

		const lastEvent = productInteractionStorage.findEventByProductId(type, data.productId);

		if (lastEvent) {
			const event = $.extend(true, {}, lastEvent, {
				data: {
					date: $.now()
				}
			});

			productInteractionStorage.updateEvent(event);
		} else {
			productInteractionStorage.addEvent({
				type,
				data
			});
		}
	}

	return {
		saveVisit,
		saveEvent
	};
});