/**
 * Ustawia minimalną wysokość <body>
 */
app.service('footer',function(html){
	var $body, $self, $collapsibleRow, $collapseButton;

	function setElements() {
		$body = $('body');
		$self = $('#main-footer');
		$collapsibleRow = $('#footer-links');
		$collapseButton = $('.js-toggle-footer-link');
	}

	function setStyles() {
		$body.css({
			'min-height': 'calc(100vh - '+($self.outerHeight())+'px)',
			'padding-bottom': ($self.outerHeight() + parseInt($self.css('margin-bottom')))+'px'
		});
		html.add('footer2bottom');
	}

	function setEvents() {
		$(window).resize(setStyles);
		window.onload = setStyles;
		$collapseButton.click(function(){
			$collapsibleRow.toggleClass('open'); 
			setStyles();
		});
	}

	function init() {
		setElements();
		setStyles();
		setEvents();
	}

	return {
		init:init
	};
});
