app.service('relatedPromotionCommercial', function (broker, front, modal, loading, cartManager) {
	var productId, installment, installmentNumber;
	var lastModal = false;
	function init() {
		$('body').on('click', '.js-modal-promotion-package', function () {
			productId = $(this).data('product');
			installment = $(this).data('installment') || undefined;
			installmentNumber = $(this).data('duration') || undefined;
			UA.interaction("Promocja wiązana", "Otwarcie warstwy");
			let $currentModal = $('.remodal.remodal-is-opened');
			if ($currentModal.length) {
				lastModal = $currentModal.attr('id');
			} else { 
				lastModal = false;
			}
			load();
		});
		$('body').on('click', '.js-add-promotion-package', function () {
			productId = $(this).data('product') || productId;
			let addedFrom = $(this).data('added-from');
			if (modal.isOpen('promotion-package-modal')) {
				UA.interaction("Promocja wiązana", "Dodanie pakietu do koszyka z warstwy", addedFrom);
			} else {
				UA.interaction("Promocja wiązana", "Dodanie pakietu do koszyka", addedFrom);
			}
			if (front.page() == 'product-detail') {
				broker.trigger('ADD_TO_CART_PROMOTION_PACKAGE', {
					relatedPromotionServiceId: $(this).data('related-promotion-service'),
					instalment: $(this).data('installment') || installment || undefined,
					instalmentNumber: $(this).data('duration') || installmentNumber || undefined,
					paymentType: $(this).data('installment') ? 'INSTALMENT_IN_CREDIT_AGRICOLE' : undefined
				});
			} else {
				cartManager.addProduct({
					productId,
					relatedPromotionServiceId: $(this).data('related-promotion-service'),
					addedFrom: addedFrom,
					replaceProduct: front.page() == 'basket' ? true : undefined,
					instalment: $(this).data('installment') || installment || undefined,
					instalmentNumber: $(this).data('duration') || installmentNumber || undefined,
					paymentType: $(this).data('installment') ? 'INSTALMENT_IN_CREDIT_AGRICOLE' : undefined,
				});
			}
		});
		
	}
	function load() {
		$.fancybox.close();
		if (productId/1 > 0 ) {
			loading.start();
			$.ajax({
				url: '/related-promotion-details.ltr',
				data: {
					productId,
					instalmentCode: installment,
					instalmentNumber: installmentNumber
				},
				success: function (html) {
					loading.stop();
					modal.generate({
						id: 'promotion-package-modal',
						content: html,
						callbackClose: function () { 
							if (lastModal) {
								modal.open(lastModal);
								lastModal = false;
							}
						}
					});
				},
				error: function () {
					loading.stop();
					modal.alert('Błąd pobierania danych. Spróbuj ponownie później');
				}
			});
		} else {
			modal.alert('Błąd generowania warstwy. Spróbuj ponownie później');
		}

	}

	return {
		init
	}
});
