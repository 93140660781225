app.service('eastMessage', function(account) {
	var isInit = false,
		text, expanded, timeout = null;
	var $self;

	function setEvents() {
		$self.mouseover(function() {
			show();
		}).mouseout(function() {
			hide();
		});
	}

	function removeTimeout() {
		if (timeout !== null) {
			clearTimeout(timeout);
		}
	}

	function show() {
		$self.addClass('show-message');
		removeTimeout();
	}

	function hide() {
		$self.removeClass('show-message');
		removeTimeout();
	}

	function setElements() {
		$self = $('<div id="east-message"><div class="message">' + text + '</div></div>').appendTo($('body'));
		if (expanded) {
			show();
			timeout = setTimeout(function() {
				hide();
			}, 10000);
		}

	}
	function initOnLoad(){
		account.onLoad(function(data){
			if(data && data.showEasternEuropeMessage === true){
				init(data.easternEuropeMessage, data.easternEuropeMessageExpanded);
			}
		});
	}
	function init(t, e) {
		if (!isInit) {
			text = t;
			expanded = e;
			setElements();
			setEvents();
			isInit = true;
		}
	}
	return {
		init: initOnLoad
	};
});
