app.service('partner',function( callback, html, loading, sticky, account){
	var $self=false;
	var orderPath=false;
	var cssClass='partner-logged';

	function showSuccess(){
		if(location.protocol.indexOf('https')>-1 || window.cart){
			orderPath=true;
		}
		setEvents();
		html.add(cssClass);
	}

	function show() {
		$self=$('#top-bar');
		if($('#top-bar > div').length){
			showSuccess();
		}else{
			$.ajax({
				url : '/partner-bar-nobody.ltr',
				success : function(response) {
					$self.html(response);
					showSuccess();
				}
			});
		}
	}
	function setEvents(){
		$self.find('.js-logout-partner').click(function(){
			logoutPartner();
		});
	}

	function logoutPartner(a) {
		$self.remove();
		html.remove(cssClass);
		sticky.rebuild('topBar');
		if(orderPath){
			loading.start();
			document.location.href='/konto-wyloguj.bhtml';
		}
		else{
			$.ajax({
				url:'/konto-wyloguj.bhtml',
				method:'POST'
			});
			callback.run('logoutPartnerCallback');
		}
	}
	function init(data){
		html.remove('partner-not-logged partner-logged');
		if ((data && data.partnerLogged === true) || $('#top-bar .partner-bar').length) {
			show();
		}else{
			html.add('partner-not-logged');
		}
	}
	function initOnLoad(){
		account.onLoad(function(data){
			init(data);
		});
	}
	return {
		init:initOnLoad
	};
});
