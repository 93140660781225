app.service('wcReportBug',function(modal, front, cookie, loading, validate, account, console, html) {
	var $self, $modal;
	var productId, url, ajaxData;

	function closeCallback(){
		html.remove('remodal-bottom-view');
	}

	function loadSuccess(){
		$self=$('#modal-technical-error');
		$('#attribute-name').text(ajaxData.attributeName);
		$('#attribute-value').text(ajaxData.attributeValue);
		$('#dictionary-name').text(ajaxData.dictionaryName);
		validate.task('technicalError',function(form){
			send(form);
		});
	}
	function load(params){
		if(params.dictionaryName){
			url = '/errorDictionaryWarning.ltr',
			ajaxData = {
				dictionaryName: params.dictionaryName,
				productId: productId
			}
		}else{
			url = '/errorAttributeWarning.ltr';
			ajaxData = {
				attributeName: params.attributeName,
				attributeValue: params.attributeValue,
				productId: productId
			}
		}
		console.log(ajaxData);
		loading.start();
		$.ajax({
			url:url,
			success:function(content){
				$modal=modal.generate({
					id:'modal-technical-error',
					content: content,
					modifier: 'remodal-bottom',
					callbackOpen:function(){
						loadSuccess();
					},
					callbackClose:function(){
						closeCallback();
					}
				});
				loading.stop();
			},
			error:function(){
				modal.alert('Błąd ładowania warstwy.')
			}
		});
	}
	function send($form){
		$form.addClass('loading');
		$form.ajaxSubmit({
			url: url,
			data: ajaxData,
			type: 'POST',
			success:function(){
				modal.alert('Dziękujemy za Twoje zgłoszenie');
			},error:function(){
				modal.alert('Wystapił błąd wysyłania formularza. Prosimy spróbować później');
			}
		});
	}
	function setEvents(){
		$('body').on('click','.js-report-technical-bug',function(){
			productId = $('#product-top').data('product');
			if($(this).data('definition')){
				load({
					dictionaryName: $(this).closest('h1').text().trim()
				});
			}else if($(this).closest('tr').length){
				load({
					attributeName: $(this).closest('tr').find('td:first').text().trim(),
					attributeValue: $(this).closest('tr').find('td:last').text().trim()
				});
			}
		});
	}
	function init(){
		setEvents();
	}
	return {
		init
	};
});
