/**
 * Moduł obsługujący porownywarkę produktow,
 * zarządza ciasteczkami związanymi z modułem
 *
 * @type {module}
 */
app.service('productCompare',function(cookie, console, modal, localStorage, front, productInteractionListener, log) {
	var $compareButtons;

	function setElements(){
		$compareButtons = $('.js-add-to-compare');
	}
	function setEvents(){

	}
	function checkCorrectCookie(){
		var groups=[];
		$(getGroups()).each(function(i,group){
			if(!localStorage.empty('c'+group)){
				groups.push(group);
			}
		});
		saveGroups(groups);
	}
	function getGroups(){
		var groups=[];
		if(!localStorage.empty('c')){
			var cLocalStorage=localStorage.getItem('c').split('|');
			$(cLocalStorage).each(function(i,n){
				var element=parseInt(n);
				if(element>0){
					groups.push(element);
				}
			});
		}
		return groups;
	}
	function getLastGroup(){
		var lastGroup=false;
		var groups=getGroups();
		var data;
		if(groups.length){
			lastGroup=groups[0];
			if($('#products').length){
				data=parseInt($('#products').data('group'));
				if(data>0 && $.inArray(data, groups)>-1 && getProducts(data).length){
					lastGroup=data;
				}
			}
			else if($('#product-top').length){
				data=parseInt($('#product-top').data('group'));
				if(data>0 && $.inArray(data, groups)>-1 && getProducts(data).length){
					lastGroup=data;
				}
			}
		}
		return lastGroup;
	}
	function addGroup(group){
		var groups=getGroups();
		var index=$.inArray(group,groups);
		if(index>-1){
			groups.splice(index,1);
		}
		groups.unshift(group);
		//console.log('dodano kategorię: '+groups);
		saveGroups(groups);
	}
	function removeGroup(group){
		var groups=getGroups();
		var index=$.inArray(group,groups);
		if(index>-1){
			groups.splice(index,1);
			//console.log('usunięto kategorię: '+group);
		}
		saveGroups(groups);
		localStorage.removeItem('LPBC-'+group);
	}
	function saveGroups(groups){
		if(groups.length){
			localStorage.setItem('c', groups.join("|"), 30);
			//console.log('zapisanie itemu kategorii',groups);
		}else{
			localStorage.removeItem('c');
			//console.log('wyczyszczenie itemu kategorii');
		}
	}
	function getProducts(group) {
		var products=[];
		if(!group){
			group=getLastGroup();
		}
		if(group && !localStorage.empty('c'+group)){
			var cLocalStorage=localStorage.getItem('c'+group).split('|');
			$(cLocalStorage).each(function(i,n){
				var element=parseInt(n);
				if(element>0){
					products.push(element);
				}
			});
		}
		return products;
	}
	function addProduct(product, group){
		//console.log('addPRoduct', product, group)
		productInteractionListener.onProductAddToCompare(product);
		var products=getProducts(group);
		if($.inArray(product,products)==-1 && products.length<3){
			products.push(product);
			addGroup(group);
			changeClass($compareButtons.filter('[data-product="'+product+'"]'), true);
			saveProduct(products, group);
			//console.log('dodano '+product+' w kategorii '+group);
			localStorage.setItem('LPBC-'+group, document.location.href);
		}else{
			removeProduct(products[0], group);
			addProduct(product, group);
		}
	}
	function removeProduct(product, group){
		if(product && group){
			var products=getProducts(group);
			var index=$.inArray(product,products);
			if(index>-1){
				products.splice(index,1);
				changeClass($compareButtons.filter('[data-product="'+product+'"]'), false);
				saveProduct(products, group);
				if(!products.length){
					removeGroup(group);
				}
				//console.log('usunięto '+product+' z kategorii '+group);
				if(front.page() !== 'comparison') {
					localStorage.setItem('LPBC-'+group, document.location.href);
				}
			}else{
				console.log('nieudana próba usunięcia produktu: ' +product);
			}
		}else{
			console.log('brak poprawnych danych do usuwania', product, group);
		}
	}
	function saveProduct(products, group){
		if(products.length){
			localStorage.setItem('c'+group, products.join("|"), 30);
		}else{
			localStorage.removeItem('c'+group);
		}
	}
	function click(product, group, plu) {
		var products=getProducts(group);
		//console.log('KLIK - PRODUKT DO PORÓWNANIA - '+product, products, group);
		if($.inArray(product,products)==-1){
			addProduct(product, group);
			UA.pageInteraction('Porównanie', 'Porównaj');
			log.recommendationsForAWS({
				productPlu: plu,
				eventCategory: 'Porównanie',
				eventAction: 'Porównaj'
			});
		}else{
			removeProduct(product, group);
			UA.pageInteraction('Porównanie', 'Usuń z Porównania');
		}
	}
	function changeClass(element,add){
		if(add){
			$(element).addClass('compare-button-active');
		}else{
			$(element).removeClass('compare-button-active');
		}
	}
	function checkCompared(){
		setElements();
		$compareButtons.each(function(i,element){
			var product = $(element).data('product');
			var group = $(element).data("group");
			var products=getProducts(group);
			changeClass($(element), $.inArray(product,products)>-1);
		});
	}
	function clear(){
		var groups=getGroups();
		$(groups).each(function(i,n){
			//console.log('usuięcie grupy: '+n);
			localStorage.removeItem('c'+n);
		});
		localStorage.removeItem('c');
		checkCompared();
	}
	function init() {
		setElements();
		checkCorrectCookie();
		setEvents();
		checkCompared();

		$(document).on('pageChange',function(){
			checkCompared();
		});
	}
	return {
		init,
		getProducts,
		getGroups,
		getCurrentGroup:getLastGroup,
		click,
		removeProduct,
		checkCompared,
		clear
	};
});
