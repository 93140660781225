app.service("emptySearchResultsContactForm", function (modal, validate, loading, viewPoint, captchaService) {

	function init() {
		validate.task('emptySearchResultsContactForm', send);
		setUA();
	}

	function send($form) {

		loading.start();

		$.ajax({
			method: "POST",
			dataType: "json",
			contentType: "application/json",
			url: "/search-suggestions/search-suggestion-advice.ltr",
			data: JSON.stringify({
				message: $form.find('#contact-form-message').val().trim(),
				captchaToken: $form.find("#g-recaptcha-response").val() || ""
			})
		}).then(response => {
			if (response.status === "FAIL") {
				modal.alert(response.messages.join(", "));
				return;
			}
			$form.find('#contact-form-message').val("");
			modal.alert("Dziękujemy za Twoje zgłoszenie.");
			refreshCaptcha();
			UA.searchInteraction('Zerowe wyniki wyszukiwan', 'Formularz', 'Wyslanie');
		}).fail(() => {
			modal.alert("Błąd wysyłania formularza. Spróbuj ponownie.");
		}).always(() => {
			loading.stop();
		});
	}

	function refreshCaptcha() {
		let $captcha = $("#contact-form-captcha");
		if ($captcha.is(':hidden')) {
			captchaService.load($captcha.attr('id'), 'EMPTY_SEARCH_RESULTS_ADVICE_FORM');
		} else {
			captchaService.reset($captcha.attr('id'));
		}
	}

	function setUA() {

		let $form = $("#contact-form");

		viewPoint.set({
			element: $form,
			height: 0.6 * $form.height(),
			callback() {
				UA.searchInteraction('Zerowe wyniki wyszukiwan', 'Formularz', 'Wyswietlenie');
			}
		});
	}

	return {
		init: init
	};
});