app.service('pageConfig',function($global) {

	var defaults={
		basket:{
			quantity: 0,
			items: []
		},
		productCard:{
			print:false
		},
		brand: {},
		productsList:{
			print:false
		},
		order: {},
		operator: {},
		precart:{},
		compare: {
			groupId:0,
			products:[]
		},
		account:{},
		author:{},
		wc: {},
		emptySearchResults: {},
		emailsRules: []
	};
	var config=$.extend(true,[],defaults);

	function setData(page, params){
		if (defaults[page]) {
			config[page]=$.extend({}, defaults[page], params);
		}
	}

	function getData(page){
		if (config[page]) {
			return config[page];
		}
	}

	$global('pageConfig', setData);

	return {
		getData
	};
});
