/*
	Obsługa modala wyświetlającego dane słownikowe.
 */
app.service('modalDictionary',function(modal, console, loading, definitionItem, pageConfig) {

	var $self;
	var productCode, definitionId;

	function loadSuccess(html){
		$self = $('#dictionary-modal');
		definitionItem.init('modal', html, definitionId, productCode);
	}

	function open(html){
		modal.generate({
			id: 'dictionary-modal',
			content: html,
			cssClass: 'dictionary-modal',
			callbackOpen: function(){
				loadSuccess();
			}
		});
	}

	function show(html){
		if(modal.isExist('dictionary-modal') && modal.isOpen('dictionary-modal')){
			loadSuccess(html);
		} else {
			open(html);
		}
	}

	function load(definitionId){
		if(definitionId>0){
			$.ajax({
				url: '/slownik.ltr',
				data: {
					id: definitionId
				},
				beforeSend: function(){
					loading.start();
					if($self){
						$self.addClass('in-progress');
					}
				},
				success: function(html) {
					show(html);
				},
				error: function(response) {
					console.log('load dictionary ERROR');
				},
				complete: function(){
					loading.stop();
					if($self) {
						$self.removeClass('in-progress');
					}
				}
			});
		}else{
			modal.alert('Błąd generowania warstwy z wyjaśnieniem');
		}
	}

	function init(){
		$('body').on('click','.fancyDict, .js-dictionary',function(event){
			var href = $(this).attr('href') || $(this).attr('data-href');
			if(!href || href.match(/\.pdf$/)) {
				return true;
			}else{
				definitionId = parseInt(href.substr(href.indexOf('definitionId=')+13));
				productCode = $(this).data('product') || pageConfig.getData('productCard').plu || undefined;
				load(definitionId);
				return false;
			}
		});
	}

	return {
		init:init
	};
});
