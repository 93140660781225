/*
 * Warstwa usług
 */
app.service('serviceManager', function(cartManager, callback, log, front) {

	function uncheckAll() {
		$('.js-add-service-checkbox').prop('checked', false);
	}

	function checkboxCheck(serviceId, isChecked) {
		$('input.js-add-service-checkbox[value=' + serviceId + ']').prop('checked', isChecked);
	}

	function checkedCheck(productId, tempChecked) {
		var servicesList = $.extend([],cartManager.getServices(productId) ); //lista usług w koszyku
		if(tempChecked)	{								//po otwarciu modala zaznaczona usługa powinna być też oznaczona na modalu
		$('input.js-add-service-checkbox:checked').each(function() {
			servicesList.push(parseInt($(this).val())); // dodanie listy usług ktore jeszcze nie zostały dodane
		});
		}
		uncheckAll();
		$(servicesList).each(function(i, service) {
			checkboxCheck(service, true);
		});
	}

	function checkboxClick(params) {
		var options = $.extend({
			productPLU: '',
			serviceChecked: false,
			place: 'Usługi'
		}, params);
		var id=options.productId;
		if ($.inArray(parseInt(id), cartManager.getElements()) != -1) {
			if (($.inArray(parseInt(options.serviceId), cartManager.getServices(id)) != -1)) {
					cartManager.removeService({
						productId: options.productId,
						serviceId: options.serviceId
					});
			} else {
				if (options.serviceChecked) {
					cartManager.addService({
						productId: options.productId,
						addedFrom: options.addedFrom,
						serviceId: options.serviceId
					});
				}
			}
		}
		if (options.serviceChecked) {
			UA.pageInteraction(options.place, 'Wybieram - ' + $.trim($("#service-name-" + options.serviceId).text()));
		}
		checkboxCheck(options.serviceId, options.serviceChecked);

		if (options.serviceChecked) {
			log.event({
				id: 'tocart',
				f03: options.addedFrom,
				f04: options.productPLU,
				f05: 'uslugi',
				f06: '',
				f07: front.page()
			});
		}
	}

	return {
		checkboxClick: checkboxClick,
		checkboxCheck: checkboxCheck,
		checkedCheck: checkedCheck
	};
});
