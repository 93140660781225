app.service('stringUtils', function () {

	function isEmpty(str) {
		return str === "";
	}

	function isBlank(str) {
		return !str || isEmpty(str.trim());
	}

	return {
		isEmpty,
		isNotEmpty: str => !isEmpty(str),
		isBlank,
		isNotBlank: str => !isBlank(str)
	}
});