app.service('productSalesTabs', (front, lazyLoad, broker, console) => {

	function init() {
		setEvents();
	}

	function setEvents() {
		$('body')
			.on('click', '.js-select-outlet-category', handleOutletCategoryClick)
			.on('click', '.js-outlet-quality-category', showOutletDescription)
			.on('click', '.js-tab-outlet', toggleOutletMenu)
			.on('click', '.js-outlet-dropdown', hideDropdown)
			.on('click', '.js-shopping-options', handleShoppingOptionsClick)
			.on('click', '.js-select-outlet-category-instalment', handleOutletCategoryInstalmentClick);
	}

	function isProductCard() {
		return front.page() === 'product-card';
	}

	function handleShoppingOptionsClick(){
		UA.pageInteraction('CTA', 'Opcje zakupowe - '+$(this).data('outlet-quality'));
	}

	function handleOutletCategoryClick() {
		if (isProductCard()) {
			UA.pageInteraction('CTA', 'Opcje zakupowe - '+$(this).data('outlet-quality'));
		}
		setOutletCategory({
			outletCategoryId: $(this).data('product-category'),
			productId: $(this).data('product-id'),
			scroll: $(this).data('scroll')
		});
		handleTabStateAnalytic($(this).data('product-category')==='new');
	}

	function handleTabStateAnalytic(state) {
		const tabType = state ? 'Nowy' : 'Outlet';
		UA.pageInteraction('CTA', 'Zakładka - ' + tabType);
		app.logEvent({
			'id': 'switch-product-tab',
			'f03': front.page(), 
			'f04': 'Zakładka - ' + tabType
		});
	}

	function handleOutletCategoryInstalmentClick(){
		setOutletCategory({
			outletCategoryId: $(this).data('product-category'),
			productId: $(this).data('product-id'),
			scroll: 'tab',
			tab: 'raty'
		});
	}

	function setOutletCategory(data) {
		console.log('Przełączenie kategroii outlet', data);
		const $outletTab = $('.product-sales-'+data.productId).find(data.outletCategoryId=='new'?'.tab-new':'.tab-outlet');
		const $outletContent = $('.product-sales-'+data.productId).find('.product-category-'+data.outletCategoryId);
		const $outletLink = $('.outlet-categories-'+data.productId).find('.outlet-category-'+data.outletCategoryId);
		const $dropdown = $('.product-sales-'+data.productId).find('.tab-outlet');

		$dropdown.removeClass('is-open');

		$outletTab.addClass('is-active')
					.siblings()
					.removeClass('is-active');

		$outletContent.addClass('is-active')
					.siblings()
					.removeClass('is-active');
		$outletLink.addClass('is-active')
					.siblings()
					.removeClass('is-active');

		setOutletAdertisingPlacement(data);

		if (isProductCard()) {
			broker.trigger('CHANGE_PRODUCT_CATEGORY', [data]);
		}
		lazyLoad.update();
	}

	function setOutletAdertisingPlacement(data) {
		var $productBox=$(`#product-top, .product-box[data-product-id=${data.productId}]`);
		if (data.outletCategoryId!=="new") {
			$productBox
				.addClass('product-is-outlet')
				.find('.advertising-placement-outlet')
				.removeClass('is-active')
				.filter(`[data-category-id=${data.outletCategoryId}]`)
				.addClass('is-active');
		} else {
			$productBox.removeClass('product-is-outlet');
		}
	}

	function showOutletDescription(e) {
		$(e.target).toggleClass('show-description');

		setTimeout(() => $(e.target).removeClass('show-description'), 3000);

		UA.pageInteraction(
			'Outlet - Warstwa',
			e.target.dataset.outletQuality,
			false
		);
	}
	function toggleOutletMenu() {
		$(this).toggleClass('is-open');
	}

	function hideDropdown() {
		$(this).prev().removeClass('is-open');
	}

	return {
		init,
		setOutletCategory
	};
});
