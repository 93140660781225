app.service('YouTubeEvent', function(front) {
	var loadJS =false;
	function preload(){
		if(!loadJS){
			loadJS =true;
			if (!window['YT']) {var YT = {loading: 0,loaded: 0};}if (!window['YTConfig']) {var YTConfig = {'host': 'http://www.youtube.com'};}if (!YT.loading) {YT.loading = 1;(function(){var l = [];YT.ready = function(f) {if (YT.loaded) {f();} else {l.push(f);}};window.onYTReady = function() {YT.loaded = 1;for (var i = 0; i < l.length; i++) {try {l[i]();} catch (e) {}}};YT.setConfig = function(c) {for (var k in c) {if (c.hasOwnProperty(k)) {YTConfig[k] = c[k];}}};var a = document.createElement('script');a.type = 'text/javascript';a.id = 'www-widgetapi-script';a.src = 'https:' + '//s.ytimg.com/yts/jsbin/www-widgetapi-vflpdK2eS/www-widgetapi.js';a.async = true;var b = document.getElementsByTagName('script')[0];b.parentNode.insertBefore(a, b);})();}
		}
	}
	function init(iframe, plu, placement){
		const currentPage = front.page();


		function handler_onPlayerReady(event){
			//nic nie zgłaszamy
		}
		function handler_onStateChange(event){
			if ( !event.target.getVideoData ){
				return;
			}
			var videoData = event.target.getVideoData();
			var videoMuteStatus = event.target.j;

			if ( event.data == YT.PlayerState.PAUSED ) {
				UA.push({
					'event': 'visitorInteraction',
					'eventCategory': 'Video',
					'eventAction': 'Pause ' + ' List: ' + currentPage + ' Placement: ' + placement,
					'eventLabel': videoData.title + ' ( PLU: ' + plu + ' )',
					'eventNonInteraction': 'false',
					'videoAuthor': videoData.author
				});
			}
			if ( event.data == YT.PlayerState.PLAYING ) {
				UA.push({
					'event': 'visitorInteraction',
					'eventCategory': 'Video',
					'eventAction': 'Play ' + ' List: ' + currentPage + ' Placement: ' + placement,
					'eventLabel': videoData.title + ' ( PLU: ' + plu + ' )',
					'eventNonInteraction': 'false',
					'videoAuthor': videoData.author
				});
				UA.push({
					'event': 'visitorInteraction',
					'eventCategory': 'Video',
					'eventAction': 'Player Voice',
					'eventLabel': 'Mute: ' + videoMuteStatus.muted,
					'eventNonInteraction': 'true'
				});
			}
		}
		function handler_onPlaybackQualityChange(event){
			if (!event.target.getVideoData){
				return;
			}
			UA.interaction('Video','Player Quality','Quality: ' + event.target.j.playbackQuality);
		}
		function handler_onError(event){
			var errorCode;

			switch ( event.data ){
				case 2:
					errorCode = 'Błąd w VIDEO_ID (APIerrorId = 2)';
					break;
				case 5:
					errorCode = 'Błąd odtwarzacz HTML (APIerrorId = 5)';
					break;
				case 100:
					errorCode = 'Film został usunięty lub prywatny (APIerrorId = 100)';
					break;
				case 101:
					errorCode = 'Właściciel nie zezwala na odtwarzanie (APIerrorId = 150)';
					break;
				case 150:
					errorCode = 'Film został usunięty lub prywatny (APIerrorId = 150)';
					break;
			};
			UA.interaction('Video', 'ErrorCode: ' + errorCode, 'PLU: ' + plu);
		}
		$('#' + iframe).on("load", (e) => {
			new YT.Player($(e.target).get(0), {
				events: {
					'onReady': handler_onPlayerReady,
					'onStateChange': handler_onStateChange,
					'onPlaybackQualityChange': handler_onPlaybackQualityChange,
					'onError': handler_onError
				}
			});
		});
	}

	return {
		init, preload
	};
});
