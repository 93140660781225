app.service('reportAbuse',function(modal, loading, validate, account, console, html, captchaService) {
	var $self, $modal;

	function loadSuccess(){
		validate.task('utteranceForm',function(form){
			send(form);
		});
		html.remove('remodal-is-locked');
		var userInfo=account.user();
		if(userInfo.email || userInfo.wcEmail){
			$('#utterance-email').val(userInfo.email || userInfo.wcEmail);
		}
		addUtteranceAbuseCaptcha();
	}
	function load(params){
		loading.start();

		$.ajax({
			url:'/front-utterance-abuse.ltr',
			data:params,
			success:function(content){
				$modal=modal.generate({
					id:'modal-utterance',
					content: content,
					modifier: 'remodal-bottom',
					callbackOpen:function(){
						loadSuccess();
					}
				});
			},
			complete:function(){
				loading.stop();
			}
		});
	}
	function addUtteranceAbuseCaptcha() {
		let $captcha = $('#modal-utterance').find('[data-toggle="captcha"]');
		if ($captcha.length) {
			captchaService.load($captcha.attr('id'), $captcha.data('place'));
		}
	}
	function send($form){
		var params = {
			userAgent: navigator.userAgent
		};
		$form.addClass('loading');
		$form.serializeArray().forEach(element => {
			params[element.name] = element.value;
		});
		$.ajax({
			url: '/front-utterance-abuse.ltr',
			method: 'POST',
			data: params,
			success:function(json){
				if(json.status == 'SUCCESS') {
					modal.alert('Dziękujemy za Twoje zgłoszenie');
				}else {
					modal.alert(json.messages, function(){
						$modal.open();
					});
				}
			},error:function(){
 				modal.alert('Wystapił błąd wysyłania formularza. Prosimy spróbować później');
			},complete:function(){
				$form.removeClass('loading');
			}
		});
	}
	function show(params){
		if(params){
			load(params);
		}else{
			modal.alert('Brak możliwości raportowania błędu na tej stronie');
		}
	}
	function setEvents(){
		$('body').on('click','.js-report-abuse',function(){
			show({
				code: $(this).data('code'),
			    question_token : $(this).data('question-id'),
				opinion_id : $(this).data('opinion-id')
			});
		});
	}
	function init(){
		setEvents();
	}
	return {
		init:init
	};
});
