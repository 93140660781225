/**
 * @module broker
 * Moduł umożliwiający komunikację pomiedzy modułami za pośrednictwem magistrali zdarzeń.
 */
app.service('broker', function() {
	var $broker = $({});

	function trigger(event, data, elem) {
		if (elem) {
			return $(elem).trigger(event, data);
		} else {
			return $broker.trigger(event, data);
		}
	}

	function on(event, callback, scope) {
		return $broker.on(event, $.proxy(callback, scope || $broker));
	}

	function off(event) {
		return $broker.off(event);
	}

	return {
		trigger: trigger,
		on: on,
		off: off
	};
});
