app.service('price', function () {

	function format(price, currency = true) {
		let p = price.toString().split('.');
		let result = p[0];
		if (p[0].length > 3) {
			result = p[0].substr(0, p[0].length - 3) + ' ' + p[0].substr(p[0].length - 3, 3);
		}
		if (p[1]) {
			if (p[1].length == 1) {
				result += ',' + p[1] + '0';
			} else {
				result += ',' + p[1].substr(0, 2);
			}
		}
		return result + (currency ? ' zł' : '');
	}

	function add(a, b) {
		return Math.round(Math.round(100 * a) + Math.round(100 * b)) / 100;
	}

	function subtract(a, b) {
		return Math.round(Math.round(100 * a) - Math.round(100 * b)) / 100;
	}

	return {
		format,
		add,
		subtract
	}
});