app.service('searchResultCollector',function(searchBox, callback, front, message) {
	var $self;
	var hashQuery={};

	function getHashQuery(){
		var hash = window.location.hash;
		if (hash.match(/^#afterSearch-/)) {
			var table = hash.replace(/#afterSearch-/,"").replace(/%7C/gi, "|").split('|').map((item, i) => {
				return decodeURIComponent(item).replace(/<[^>]*>/gi, "");
			});
			hashQuery={
				keyword:table[0],
				nodeid:table[1],
				searchType:table[2],
				nodeName:table[3],
				messageType:table[4]
			};
		}
	}
	function checkIfOneSearchResult() {
		var url ='/search.bhtml?result=1';
		if (hashQuery.searchType=='tag'){
			url += '&keywordtag='+hashQuery.keyword;
		} else {
			url += '&keyword='+hashQuery.keyword;
		}
		if (hashQuery.nodeid!==''){
			url += '&category=' + hashQuery.nodeid;
		}
		UA.pageview(url);
	}

	function loadMessage(mode,productName){
		var data={
			names: ['keyword', 'category_name'],
			values: [hashQuery.keyword, hashQuery.nodeName],
			code:'wynik-wyszukiwania-we-wszystkich-kategoriach'
		};
		if(mode==='product'){
			searchBox.setKeyword(hashQuery.keyword);

			data.code = 'pojedynczy-wynik-wyszukiwania';
			if(hashQuery.messageType == 'product-all'){
				data.code += '-we-wszystkich-kategoriach';
			}
			else if(hashQuery.messageType == 'product-changed-criteria'){
				data.code += '-w-moim-sklepie-lub-m1';
			}

			data.names.push('product_name');
			data.values.push(productName);
			checkIfOneSearchResult();
			callback.run('product-card-single-search',$.extend(true,{},hashQuery));
		}
		message.get(data).then(function(html){
			$self.html(html).addClass('showed');
		});
	}
	function sendLog(logData) {
		setElements();
		var data = $.extend(true,{
			initialKeyword : '',			//nie wiem po co ten parametr
			keyword	: '',
			nodeId	: '',
			searchType : '',
			resultNumber : 0
		},logData);
		$.ajax({
			type:'POST',
			url: '/searchResultCollector.ltr',
			data:data
		});
	}
	function setElements(){
		$self=$('#after-search-message');
	}
	function init(mode,params){
		setElements();
		getHashQuery();
		if($self.length){
			if(mode==='product' && hashQuery.keyword){
				loadMessage(mode,params);
				sendLog({
					keyword	: hashQuery.keyword,
					nodeId	: hashQuery.nodeName,
					searchType: hashQuery.searchType,
					resultNumber: 1
				});
			}
			else if(mode==='list'){
				if(hashQuery.keyword){
					loadMessage(mode);
				}
				sendLog(params);
			}
		}
	}
	return {
		init : init
	};
});
