app.service('livechat',function(front, console, html){
	var url='/chat.ltr';
	var name='Czat_13050';
	var specs='width=564,height=639,resizable=yes,scrollbars=no,status=1';
	var enable=false;

	// Ograniczenia czasowe widoczności czatu
	// 1 - Poniedziałek, 7 - Niedziela, Godziny bez minut
	// PN-PT : 8:00-20:00, SB-ND : 9:00-19:00  => visibility : ['1-5:8-20','6-7:9-19']
	var visibility = ['1-5:8-20','6-7:9-19'];

	function load(){
		$.ajax({
			dataType: 'json',
			url: '/chat-status.ltr',
			success:function(data) {
				loadSuccess(data.status===true);
			},
			error: function() {
				loadSuccess(false);
			}
		});
	}
	function loadSuccess(status){
		enable=status;
		checkVisible();
		setTimeout(function(){
			checkVisible();
		},60000);
	}
	function checkVisible(){
		if(enable && controlDate()){
			html.add('livechat-on');
		}else{
			html.remove('livechat-on');
		}
	}
	function controlDate(){
		var date = new Date();
		var dayOfWeek = date.getDay(),
			hour = date.getHours();

		var result=false;
		$(visibility).each(function(i,n){
			var range = n.split(/\-|\:/g);
			if((dayOfWeek >= range[0] && dayOfWeek <= range[1] )&&( hour >= range[2] && hour < range[3] )){
				result=true;
			}
		});
		return result;
	}
	function setEvents(){
		$('body').on('click','.js-livechat',function(e) {
			UA.pageview('/livechat' + window.location.pathname);
			window.open(url, name, specs);
		});
	}

	function init() {
		if(controlDate() && !front.katalog && front.getParam('liveChat')){
			load(); 
		}
		setEvents();
	}
	return {
		init:init
	};
});
