app.service('watchedProducts',function(){

	function init() {
		
	}

	return {
		init:init
	};
});
