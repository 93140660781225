/**
 * Moduł umozliwia zaladowanie strony, za pomocą ządania AJAX
 * i podmianę w miesce obecnej
 * @type {module}
 */
app.service('ajaxPage', function(loading, console, modal, front, bannerEars, broker) {
	var isLoad = false;
	var pages = [];
	var currentUrl = location.pathname + location.search;

	function htmlReplace(options) {
		UA.removePageUrl();
		$('body > .page').html(options.html);

		if(options.earsHtml) {
			$('#ears').html(options.earsHtml);
			bannerEars.ajaxRebuild();
		}

		$('body').attr({
			'id': options.id,
		});
		$('title').text(options.title);
		UA.pageCategory();
		currentUrl = options.url;  //zmieniamy dopiero po załadowaniu contentu
	}

	function loadSuccess(options) {
		htmlReplace(options);
		if(options.scrollTop) {
			var breadcrumb = $('#breadcrumb');
			if(breadcrumb.length) {
				$.scrollTo(breadcrumb);
			} else {
				$.scrollTo(0);
			}
		}
		if (options.success) {
			options.success();
		}
		if (!options.back) {
			pages.push(options);
			history.pushState({}, options.title, options.url);
		}else{
			pages.pop();
		}
		UA.pageview(options.url);
		$(document).trigger('pageChange');
		broker.trigger('PAGE_CHANGE', options);
	}

	function load(options) {
		if (currentUrl === options.url) {
			return;
		}
		if(isEnabled()) {
			var params = $.extend(true,{
				back: false,
				showLoading: true,
				scrollTop: true,
				success: function() {
				},
				error:function(){},
				beforeLoad: null
			}, options);
			if (isLoad) {
				return false;
			}
			isLoad = true;
			if (params.showLoading) {
				loading.start();
			}
			if (params.beforeLoad){
				params.beforeLoad();
			}
			ajax(params);
		} else {
			loading.redirect(options.url);
		}
		modal.close();
	}

	function back() {
		var page=pages.filter(function(o){
			return o.url && o.url==location.pathname + location.search;
		});
		if (currentUrl === page[0].url) {
			return false;
		}
		if(page.length){
			if(page[0].id){
				loadSuccess($.extend(page[0], {
					back: true
				}));
			}else{
				load($.extend(page[0], {
					back: true
				}));
			}
		}else{
			loading.reload();
		}
		//przy powrocie do poprzedniej strony zamykaj modale
		modal.close();
	}

	function ajax(params) {
		$.ajax({
			url: params.url,
			type: 'GET',
			data: {
				//ajax:true
			},
			success: function(html) {
				if ($('<div>' + html + '</div>').find('.page').length) {
					var ears = $(html).filter('#ears');
					var earsHtml = ears.length ? ears : '';

					var id = html.substr(html.indexOf('<body id="') + 10);
					id = id.substr(0, id.indexOf('"'));
					var title = html.substr(html.indexOf('<title>') + 7);
					title = $.trim(title.substr(0, title.indexOf('</title>')));

					var ttt = '<body id="' + id + '" class="';
					var cssClass = '';
					if (html.indexOf(ttt) > -1) {
						cssClass = html.substr(html.indexOf(ttt) + ttt.length);
						cssClass = $.trim(cssClass.substr(0, cssClass.indexOf('"')));
					}
					var url=$('<div>' + html + '</div>').find('link[rel=nice]').attr('href') || params.url;
					html = $('<div>' + html + '</div>').find('.page:first').html();
					loadSuccess(
						$.extend(params, {
							id: id,
							title: title,
							html: html,
							earsHtml: earsHtml,
							cssClass: cssClass,
							url: url
						})
					);
				} else {
					console.log('Błąd ładowania strony: CFRE');
				}
			},
			error: function() {
				console.log('Błąd ładowania strony: RTUE');
				params.error();
			},
			complete: function() {
				isLoad = false;
				if (params.showLoading) {
					loading.stop();
				}
			}
		});
	}

	function init(){
		if(isEnabled()){
			pages.push({
				url:location.pathname + location.search
			});
			window.onpopstate = function(event) {
				back();
			};
		}
	}
	function isEnabled(){
		return front.isAjaxPageEnabled();;
	}
	function getHistory(index){
		if(!index){
			return $.extend([],pages);
		}
		if(pages.length && index>0){
			var element=pages.length-index-1;
			return element>-1?pages[element].url:false;
		}
		return false;
	}
	return {
		load: load,
		isEnabled: isEnabled,
		history: getHistory,
		init: init
	};
});
