/** Moduł obsługuje dwie funkcjonalności:
 * - udostępnianie koszyka
 * - odtwarzanie koszyka
 */
app.service('sharedCart', function(pageConfig, sharedCartManager, modal, validate, console) {
	let sharedCart = '';

	function submitForm($form) {
		sharedCartManager.submitForm($form, response => {
			const $sharedCartUrl = $(response).filter('#shared-cart-url');

			if( $sharedCartUrl.length ) {
				const url = $sharedCartUrl.val();
				window.location.href = `//${url}`;
			} else {
				generateModal(response);
			}
		});
	}

	function loadFormSuccess() {
		validate.task('restoreSharedCart', submitForm);
		$('#identifier').focus();
	}

	function generateModal(content) {
		modal.generate({
			id: 'modal-restore-shared-cart',
			content: content,
			modifier: 'remodal-bottom',
			callbackOpen: loadFormSuccess
		});
	}

	function restoreSharedCart() {
		sharedCartManager.getForm()
			.then(content => generateModal(content));
	}

	function shareCartPrompt() {
		modal.generate({
			id: 'modal-share-cart',
			content: sharedCart,
			modifier: 'remodal-bottom',
			callbackOpen: () => {
				const $modal = $('#modal-share-cart');
				const $sharedCartLinkInput = $modal.find('.shared-cart-link');

				$modal.on('click', '.js-copy', () => {
					try {
						$sharedCartLinkInput.select();
						document.execCommand('copy');
					} catch (err) {
						console.log('[Błąd kopiowania] Nie można skopiować zawartości.');
					}
				});
			}
		});
	}

	function shareCart() {
		if( sharedCart ) {
			shareCartPrompt();
			return false;
		}
		const products = pageConfig.getData('basket').products;
		const cartItems = products.map(mapProducts);

		sharedCartManager.getSharedCart( JSON.stringify({ cartItems }) )
			.then(response => {
				sharedCart = response; // cache udostępnionego koszyka
				shareCartPrompt();
			})
			.catch(error => {
				modal.alert('Wystapił błąd poczas próby udostępnienia koszyka.');
			});
	}

	function mapProducts(product) {
		const cartItems = {
			productId: product.id,
			productQuantity: product.quantity,
			serviceItems: product.services.map(service => ({
				serviceId: service.id,
				serviceQuantity: service.quantity
			}))
		};
		if( product.warranty.length > 0 ) {
			cartItems.productWarrantyItem = {
				warrantyId: product.warranty[0].id,
				warrantyQuantity: product.warranty[0].quantity
			};
		}
		return cartItems;
	}

	function setEvents() {
		$('body').on('click', '.js-share-cart', shareCart);
		$('body').on('click', '.js-restore-shared-cart', restoreSharedCart);
	}

	function init() {
		setEvents();
	}

	/*
	* Na K1 - dodanie usługi powoduje przeładowanie strony ajaxem
	* dlatego należy umożliwić ponowne wygenerowanie linku
	*/
	function refresh() {
		sharedCart = null;
		modal.close();
	}

	window.refreshSharedCart = refresh;

	return { init };
});
