app.service('topSuggestion', function (topSuggestionCalculator, sessionStorage, console, localStorage, productInteractionStorage, logData, front, customerRecommendations, broker, moreRecommendations) {

	function loadTopSuggestion(callback){
		if(logData.showRecommendationsFromAWSonMainPage()){
			$.ajax({
				url:'/main-page-recommendation-groups.ltr',
				async: false,
				data:{
					userId : logData.customerId()
				},
				success:function(data){
					if(data.length){
						callback(data.map(function(item, i){
							return {
								groupId: item.id,
								groupName: item.name,
								position: i,
								aws: true
							}
						}));
					}else{
						callback(findTopSuggestion());
					}
				},
				error: function(){
					console.log('błąd pobierania danych do rekomendacji');
				}
			});
		}else{
			callback(findTopSuggestion());
		}
	}
	function removeSuggestion(groupId){
		var topSuggestion = calculateTopSuggestion();
		topSuggestion = topSuggestion.filter(function(item){
			return item.groupId != groupId;
		});
		if(topSuggestion.lenght){
			sessionStorage.setItem('top_suggestion', JSON.stringify(topSuggestion));
		}else{
			sessionStorage.removeItem('top_suggestion');
		}
	}

	function findTopSuggestion() {
		const savedTopSuggestion = JSON.parse(sessionStorage.getItem('top_suggestion'));
		const topSuggestionRemember = localStorage.getItem('top_suggestion_remember');

		if (savedTopSuggestion) {

			if (topSuggestionRemember) {
				// customerRecommendations.showHistoryMessage();
				return [];
			}

			return savedTopSuggestion;
		}

		if (topSuggestionRemember) {
			productInteractionStorage.removeEventsByGroup(topSuggestionRemember);
			localStorage.removeItem('top_suggestion_remember');
		}

		const topSuggestion = calculateTopSuggestion();

		if (topSuggestion.length) {
			sessionStorage.setItem('top_suggestion', JSON.stringify(topSuggestion));
		}

		return topSuggestion;
	}

	function calculateTopSuggestion() {

		const config = getTopSuggestionConfig();

		productInteractionStorage.removeOlderThan(config.expirationDate);

		const productInteractions = productInteractionStorage.findAll();

		if (!productInteractions) return;

		return topSuggestionCalculator.calculate(productInteractions, config);

		function getTopSuggestionConfig() {

			return front.getParam('algorithmConfiguration');
		}
	}

	function init() {

		if (noHistoricalData()) {
			setFirstSession();
		}

		if (isFirstSession()) return false;

		showTopSuggestion();
		setEvents();
		broker.on('REMOVE_SUGGESTION', function(event, groupId){
			productInteractionStorage.removeEventsByGroup(groupId)
			removeSuggestion(groupId);
			showTopSuggestion();
		});
	}

	function noHistoricalData() {
		return localStorage.getItem('product_interaction') === null;
	}

	function isFirstSession() {
		return sessionStorage.getItem('first_session');
	}

	function setFirstSession() {
		sessionStorage.setItem('first_session', true);
	}

	function showTopSuggestion() {
		loadTopSuggestion(function(topSuggestions){

			if (!topSuggestions.length) {
				moreRecommendations.hide();
				return false;
			}

			// customerRecommendations.loadRecommendations(mostRelevantProduct(topSuggestions));

			// moreRecommendations.show(restOfTheSuggestions(topSuggestions));

			moreRecommendations.show(topSuggestions);
		});
	}

	function mostRelevantProduct(topSuggestions) {
		return topSuggestions[0].topProduct;
	}

	function restOfTheSuggestions(topSuggestions) {
		return topSuggestions.splice(1);
	}

	function setEvents() {

		broker.on("SHOW_TOP_SUGGESTION", function () {
			showTopSuggestion();
		});
	}

	function hasSuggestion() {
		return sessionStorage.getItem('top_suggestion') !== null;
	}

	return {
		init, hasSuggestion
	};
});
