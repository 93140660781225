var www={
	appName:'euro',
	pageType:'',
	print:false,
	groupId:0,
	fancyOk:function(text){
		this.fancyOkCallback(text, function() {$.fancybox.close();});
	},
	fancyOkCallback:function(body, onclick) {
		var t = ' <div class="w350">'
					+ '<div class="f14 bold tcenter">'
						+ body
					+ '</div>'
					+ '<div class="clear"></div>'
					+ '<div class="buttonA h30 mT10">'
						+ '<a href="#" class="ok btn btn-primary">OK</a>'
						+ '<div class="clear"></div>'
					+ '</div>'
				+ '</div>';
		var option = {
			autoDimensions : true,
			onComplete : function() {
				$.fancybox.resize();
				$('#fancybox-content .ok').click(function() {
					onclick();
				});

			}
		};
		$.fancybox(t, option);
	},
	events:{
		shopEventLogger:function(obj){
			app.logEvent(obj);
		}
	}
};
messageShopValidator="Wybierz sklep, w którym chcesz odebrać Towary";
var orderNumberLocal ="";

function previewStart(){			//używane w gazetce i na ścieżce
	var container = $('<div id="personPopupContainer">'
			+ '<table width="" border="0" cellspacing="0" cellpadding="0" align="center" class="personPopupPopup">'
			+ '<tr>'
			+ '   <td class="corner topLeft"></td>'
			+ '   <td class="top"></td>'
			+ '   <td class="corner topRight"></td>'
			+ '</tr>'
			+ '<tr>'
			+ '   <td class="left">&nbsp;</td>'
			+ '   <td><div id="personPopupContent"></div></td>'
			+ '   <td class="right">&nbsp;</td>'
			+ '</tr>'
			+ '<tr>'
			+ '   <td class="corner bottomLeft">&nbsp;</td>'
			+ '   <td class="bottom">&nbsp;</td>'
			+ '   <td class="corner bottomRight"></td>'
			+ '</tr>'
			+ '</table>'
			+ '</div>');
	$('body').append(container);
	window.container = container;
	jQuery('#personPopupContainer').mouseover(function() {
		if (hideTimer)
			clearTimeout(hideTimer);
		$('a.hover').trigger('hover');
	});

	// Hide after mouseout
	jQuery('#personPopupContainer').mouseout(function() {
		if (hideTimer)
			clearTimeout(hideTimer);
		hideTimer = setTimeout(function() {
			container.css('display', 'none');
		}, hideDelay);
	});
}

function start(print) {
	try{

		//CHECKBOXY I RADIO SAFARI
		checkbox('');

		$('body').append('<div id="toolPgr"><div class="top"></div><div class="bottom"><div class="ico"></div></div></div>');
		$('#toolPgr').hover(function() {
			$(this).addClass('hover');
		}, function() {
			$('#toolPgr').removeClass('hover');
			closeToolBox();
		});

		$(document).on('click','.js-privacy-fancybox',function(event){
			var url = this.getAttribute("href");
			event.preventDefault();
			privacyFancybox(url);
		});

		$('.hoverTip').mouseover(function(){
			showToolBox(this,$('.'+jQuery(this).attr('id')).html(),25);
		}).mouseleave(function(){
			closeToolBox();
		});

		getAdditionalPageContent(); //dodatkowe skrypty po autoryzacji
		if(print=='true'){		//funkcje które mają się pojawić tylko na princie
			replaceLink();
			if(www.pageType=='product'){

			}else{
				if($('#printButton a').length){
					setTimeout("$('#printButton a').click()",2000);
				}else setTimeout("window.print()",2000);
			}
		}else	{		//funkcje które nie powinny wyświetlić się przy drukowaniu
			if(print!='preview'){
				$('table[border=0]').addClass('noBorder');

				$('#notifyFriendEmail').keyup(function(event){if(event.keyCode == 13){$('#notifyFriendF').click();}});
				$('h4.title').each(function() {
					if ($(this).height() > 32)
						$(this).addClass("title-long");
				});
				errorBox();
				$('.tooltip').tooltip({
					track : true,
					showURL : false,
					left : -5
				});
				$('a.deliveryCitySelection').click(function(){
					selectDeliveryCity($(this).attr('rel'));
				});
				fancyCmsLayer();
				$('.int').keyfilter(/[\d]/);
			}
			fancybox();
		}
	}catch(e){console.log(e)}
}
function fancyConfirm(data,callback){
	$.fancybox('<div class="pT15 w350 f15 bold tcenter">'+data+'</div><div class="mT10 buttonA"><div class="w190"><a href="javascript:void(0)" class="tak btn btn-second">Tak</a><a href="javascript:void(0)" onclick="$.fancybox.close();" class="nie btn btn-second">Nie</a></div>',{
		autoDimensions:true,
		onComplete:function(){
			$('#fancybox-content .tak').click(function(){
				if($.isFunction(callback))callback();
				else eval(callback);
			});
		}
	});
}

function closeFancyWithAction(action) {
	$.fancybox.close();
	if ( action != null && action != '' ) {
		//musi być ustawiony timeout bo inaczej nie odpali poprawnie akcji, która np. otwiera kolejnego fancyboxa
		eval('setTimeout("'+action+'",350)');
	}
}

function getBestVoucherCode(productId) {
	var element = $('#p'+ productId + ' .best-voucher-code');
	return element ? element.val() : undefined;
}

function addToCartFromArticle(item_id){			//używane na artykułach
	loadingStart();
	addToCartManager({
		productId:item_id,
		addedFrom:'ARTICLE'
	});
}

//funckje nadpisywane w teście ab
function customerPermissionAbSuccess(){}
function customerPermissionAbFail(){}
/**
 * Na potrzeby testu AB
 * Autocomplete k3 - M.Waligórski
 */
function initAutocomplete() {
	$('.home-address-autocomplete')
		.show()
		.autosearch({
			componentForm: {
				route: 'homeAddressStreet',
				street_number: 'homeAddressHouseNumber',
				flat_number: 'homeAddressFlatNumber',
				locality: 'cityName',
				postal_code: 'zipCode'
			}
	});
}
