/**
 * Uruchomienie aplikacji
 */
app.service('main', function (dispatcher, html, callback, front, wcBar){
	var isInit = false;
	var onLoadCallbacks = [];

	function onLoad(callback) {
		if (isInit) {
			callback();
		} else {
			onLoadCallbacks.push(callback);
		}
	}

	function init(){
		function go() {
			dispatcher.pageRun();
			UA.init();
			isInit = true;
			for (var i = 0; i < onLoadCallbacks.length; i++) {
				var call = onLoadCallbacks[i];
				call();
			}
			html.add('page-init');
			callback.run('afterInit');
		}
		if(!isInit){
			callback.run('beforeInit');
			if (front.katalog) {
				wcBar.init(go);
			} else {
				go();
			}
			
		}
	}
	$().ready(function() {
		if($('#error404').length && !$('#error404 #header').length){
			load404();
			return;
		}
		if($('#error403').length && !$('#error403 #header').length){
			load403();
			return;
		}
		init();
	});
	function load404(){
		$.ajax({
			url:'/404-container-content.bhtml',
			success:function(html){
				$('body').append(html);
				setTimeout(function(){
					init();
				},100);
			}
		});
	}
	function load403(){
		$.ajax({
			url:'/403-container-content.bhtml',
			success:function(html){
				$('body').append(html);
				setTimeout(function(){
					init();
				},100);
			}
		});
	}
	return {
		onLoad
	}
});
UA.unlockAccessories();
