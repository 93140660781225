app.service('newMainPageView', function( front, topSuggestion, dayDeal, customerRecommendations, newDeals, mainPageBrands, viewPoint, mainPageInteraction, weeklyPromotions, opportunityZone, articleRotator, rankingRotator, popularProducts, lastProducts) {

	function init() {
		// customerRecommendations.init();
		topSuggestion.init();
		mainPageInteraction.init(lastProducts.getProducts().length > 0);
		dayDeal.init();
		mainPageBrands.init();
		weeklyPromotions.init();
		opportunityZone.init();
		articleRotator.init();
		rankingRotator.init();
		popularProducts.init();
		newDeals.init();

		const $categories= $('#categories-in-our-shop');
		viewPoint.set({
			element: $categories,
			height: 0.6*$categories.height(),
			callback: function(){
				mainPageInteraction.set('Sekcja Kategorie w naszym sklepie - wyswietlenie');
			}
		});

		const $footer= $('#main-footer');
		viewPoint.set({
			element: $footer,
			height: 0.6*$footer.height(),
			callback: function(){
				mainPageInteraction.set('Sekcja Footer - wyswietlenie');
			}
		});

	}

	return {
		init
	}
});
