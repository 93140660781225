app.service('dayDealCounter', function (broker, account) {

	let $self, $counters;
	let countDownDate;
	let timer = null;
	let currentDateTime;

	function getCounter(distance) {
		return {
			hours: Math.floor(distance / (1000 * 60 * 60)),
			minutes: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
			seconds: Math.floor((distance % (1000 * 60)) / 1000)
		};
	}

	function startTimer() {
		renderCounter();
		timer = setInterval(renderCounter, 1000);
	}

	function renderCounter() {
		const currentTime = currentDateTime.getTime();
		const distance = countDownDate - currentTime;
		const counter = getCounter(distance);

		if (distance < 0) {
			clearInterval(timer);
			$('#day-deal').hide();
		}

		render(counter);

		currentDateTime.setSeconds(currentDateTime.getSeconds() + 1);
	}

	function render(counter) {
		$counters.html(`
			<div>
				<div>${formattedNumber(counter.hours)}</div>
				<div>godzin</div>
			</div>
			<span class="colon">:</span>
			<div>
				<div>${formattedNumber(counter.minutes)}</div>
				<div>minut</div>
			</div>
			<span class="colon">:</span>
			<div>
				<div>${formattedNumber(counter.seconds)}</div>
				<div>sekund</div>
			</div>
		`);
	}

	function formattedNumber(num) {
		return padLeft(String(num))
			.split("")
			.map(digit => `<span class="number">${digit}</span>`)
			.join("");
	}

	function padLeft(digit) {
		if (digit.length < 2) {
			return '0' + digit;
		}

		return digit;
	}

	function setCountDownDate() {
		countDownDate = new Date($self.first().data('end')).getTime();
	}

	function setEvents() {
		broker.on('DATETIME_ONLOAD', function (e, date) {
			currentDateTime = new Date(date);
			startTimer();
		});

		// sprawdzenie czy udało się pobrać datę z serwera
		account.onLoad(function () {
			if (!currentDateTime) {
				$counters.text("Błąd pobierania czasu trwania promocji.");
			}
		});
	}

	function setElements() {
		$self = $('.js-day-deal-counter');
		$counters = $self.find('.counter-widget');
	}

	function update() {
		setElements();
		renderCounter();
	}

	function init() {
		setElements();
		setCountDownDate();
		setEvents();
	}

	return {
		init,
		update: update
	};
});