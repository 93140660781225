app.service('rankingRotator', function (lazyLoad, viewPoint, mainPageInteraction) {

	let $self;
	let $box

	function init() {
		$box = $('#ranking-rotator-section');
		$self = $(".js-ranking-rotator-carousel");
		if ($self.length) {
			initCarousel();
			initUA();
		}
	}

	function initUA(){

		viewPoint.set({
			element: $box,
			height: 0.6*$box.height(),
			callback: function(){
				mainPageInteraction.set('Sekcja Rankingi - wyswietlenie');
			}
		});

		$box.on('click', '.ranking-rotator-item',function(){
			var index = $(this).index()+1;
			mainPageInteraction.set('Sekcja Rankingi - klikniecia', 'Rotator|'+index);
		});
		$box.on('click', '.ranking-rotator-item .photo',function(){
			var index = $(this).closest('.ranking-rotator-item').index()+1;
			mainPageInteraction.set('Sekcja Rankingi - klikniecia', 'Rotator|Obraz|'+index);
		});
		$box.on('click', '.ranking-rotator-item .ranking-heading',function(){
			var index = $(this).closest('.ranking-rotator-item').index()+1;
			mainPageInteraction.set('Sekcja Rankingi - klikniecia', 'Rotator|Tytul|'+index);
		});
		$box.on('click', '.ranking-rotator-item .author-box',function(){
			var name = $(this).find('.author-name').text().trim();
			mainPageInteraction.set('Sekcja Rankingi - klikniecia', 'Rotator|Autor|'+name);
		});
		$box.on('click', '.js-carousel-next',function(){
			mainPageInteraction.set('Sekcja Rankingi - klikniecia', 'Strzalka|Nastepny');
		});
		$box.on('click', '.js-carousel-prev',function(){
			mainPageInteraction.set('Sekcja Rankingi - klikniecia', 'Strzalka|Poprzedni');
		});
	}

	function initCarousel() {

		new PreviewGallery({
			selector: $self,
			autoPlay: false,
			swipe: true,
			navigation: $('.ranking-rotator-carousel-nav'),
			perPage: {
				0: 4,
				1231: 5
			},
			onInit() {
				lazyLoad.update();
			},
			onChange() {
				lazyLoad.update();
			},
			onResize() {
				lazyLoad.update();
			}
		});

	}

	return {init};
});
