app.service('agreementsSubmit',function(log, agreementsRemoveModal) {	
	var valuesBeforePost;
	var valuesOnPost;

	function changedAgreements(){
		return valuesOnPost.filter(function (element, i) {
			return valuesBeforePost[i].value!=element.value;
		}).map(function(element, i){
			var value = valuesBeforePost.filter(function(e){
				return element.name == e.name;
			})[0].value;
			if(element.name == 'PERMISSION_TO_ELECTRONIC_CHANNEL' && element.value == false){
				localStorage.setItem('permissions-minimalize', true);
			}
			return element.name + ": " + value + ' => ' + element.value;
		}).join(", ");
	}
	function allAgreements(){
		return valuesOnPost.map(function (element, i) {
			return element.name + ': ' + element.value
		}).join(", ");
	}

	function submitAgreementsForm(){
		valuesOnPost = agreementStatus();
		log.event({
			'id': 'agreements-form',
			'f03': document.location.pathname,
			'f04': JSON.stringify(valuesOnPost) != JSON.stringify(valuesBeforePost),
			'f05': changedAgreements(),
			'f06': allAgreements()
		});
	}

	function agreementStatus(){
		var result = [];
		$inputs.each(function () {
			const $element=$(this);
			const data = ($element.data('types') || "").split('|');
			const checked = $element.prop('checked');
			data.forEach(function(type) {
				result.push({
					'name': type,
					'value': checked
				});
			});
		});
		return result;
	}


	function init($form, $checkboxes){
		if($checkboxes && $form && $checkboxes.length && $form.length){
			$inputs = $checkboxes;
			valuesBeforePost = agreementStatus();
			$form.on('submit agreementSubmit', function(){
				if($form.valid()){
					submitAgreementsForm();
				}
			});

			agreementsRemoveModal.init($inputs.filter('[data-types*="PERMISSION_TO_ELECTRONIC_CHANNEL"]'));
		}
	}

	return {
		init, send: submitAgreementsForm
	}
});