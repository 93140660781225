/**
 * Moduł odpowiedzialny za bloczek użytkownika w nagłówku, udostępnia informacje o użytkowniku. Dopiero po załadowaniu bloczka doczytywany jest tryb operatorski i dolna belka
 * @type {module}
 */
app.service('wcStockUpdate',function(modal, pageConfig, localStorage) {

	function setEvents(){
		$('body').on('click', '.js-stocks-update', function(){
			load($(this).data('id'), true);
		});

		$(document).on('opened', '.remodal', function () {
			refreshStocks();
		});

		$(document).on('pageChange', function() {
			refreshStocks();
		});

		$('body').on('click', '.js-check-reservations.active', function(){
			$(this).closest('.product-sales').toggleClass('show-reservations');
		});
	}

	function load(id, showError){
		var $self=$('.wc-product-quantity-'+id);
		$self.addClass('loading');
		$.ajax({
			url: '/stocksUpdate.ltr',
			dataType: 'json',
			data: {
				'productId':id
			},
			success: function(data) {
				loadSuccess(id, data);
			},
			error:function(data){
				loadError(data);
			},
			complete:function(){
				$self.removeClass('loading');
			}
		});
	}

	function outletAvailability(outletStocks=[], id){
		var kP=$('#outlet').length;
		var cart=$('#basket').length;
		var $outlets=$('.wc-product-quantity-'+id);
		if(kP){
			$outlets=$('#outlet .outlet-product-item:not(.outlet-product-item-new)');
			$outlets.removeClass('outlet-product-available');
		}
		$outlets.find('.outlet-quantity').text(0);

		outletStocks.forEach(function(outletCategory){
			var $el=(kP)?
				$outlets.filter('.outlet-product-item-'+outletCategory.outletCategoryId)
				:
				$outlets.find('.outlet-'+outletCategory.outletCategoryId);
			var $quantity=$el.find('.outlet-quantity')
			if($quantity.length && outletCategory.stockQuantity>0){
				if(kP){
					$el.addClass('outlet-product-available');
				}
				$quantity.text(outletCategory.stockQuantity);
			}
		});
	}
	function update(id,result,setStorage){
		var m1=result.m1Quantity;
		var local=result.localShopQuantity;
		var outletStocks=result.outletStocks;
		var localShop = parseInt(result.localShopOutletQuantity) > 0 ? ` <span>+ ${result.localShopOutletQuantity} przec. </span>` : '';
		var activeReservations = result.activeReservationsQuantity || 0;
		var expiredReservations = result.expiredReservationsQuantity || 0;

		$('.m1-quantity-'+id).text(m1 + ' szt.');
		$('.local-quantity-'+id).html(local + ' szt.' + localShop);
		$(`.active-reservations-quantity-${id} .count`).html(activeReservations);
		$(`.expired-reservations-quantity-${id} .count`).html(expiredReservations);

		if(activeReservations > 0 || expiredReservations > 0) {
			$(`.js-check-reservations[data-id="${id}"]`).addClass('active');
		}

		var $point = $(`.local-score-${id}`);
		var $element = $(`.js-add-to-cart[data-product="${id}"]:not(.add-to-cart-outlet)`);
		if(m1 == '0' && local == '0') {
			$element.addClass('btn-hide');
		} else {
			$element.removeClass('btn-hide');
		}
		if(local == '0') {
			$point.addClass('score-hide');
		} else {
			$point.removeClass('score-hide');
		}
		outletAvailability(outletStocks, id);

		if(setStorage){
			var q=read();
			var d = new Date();
			q["p"+id]={
				m1:m1,
				local:local,
				outletStocks: outletStocks,
				date:d.getTime()+10*60*1000,
				localShop: result.localShopOutletQuantity,
				activeReservationsQuantity: result.activeReservationsQuantity,
				expiredReservationsQuantity: result.expiredReservationsQuantity
			}
			save(q);
		}
	}
	function save(q){
		localStorage.setItem('stocksUpdate',JSON.stringify(q));
	}
	function read(){
		return JSON.parse(localStorage.getItem('stocksUpdate')) || {}
	}

	function loadError(data){
	    if(data.error) {
	        modal.alert('Twoja sesja wygasła. Odśwież stronę.');
	    }
		modal.alert('Próba aktualizacji nie powiodła się. Błędne żądanie.');
	}

	function loadSuccess(id,result){
		if (result.error){
			modal.alert('Próba aktualizacji nie powiodła się. Błędne żądanie.');
		}else{
			update(id,result,true);
		}
	}

	function refreshStocks(){
		$('.wc-product-quantity').each(function(){
			if($(this).find('.js-stocks-update').length) {
				var id = $(this).data('id');
				var data = read();
				if (data && data["p"+id]){
					var item = data["p"+id];
					var d = new Date();
					if(item && item.date>d.getTime()){
						result =
							update(id,{
								m1Quantity: item.m1,
								localShopQuantity: item.local,
								outletStocks: item.outletStocks,
								localShop: item.localShop,
								activeReservationsQuantity: item.activeReservationsQuantity,
								expiredReservationsQuantity: item.expiredReservationsQuantity
					},false);
				}else{
					delete data["p"+id];
					save(data);
				}
			}
			var $this=$(this);
			var $m1Quantity=$('.local-quantity', $this);
			var $localQuantity=$('.m1-quantity', $this);
			var $element = $(`.js-add-to-cart[data-product="${id}"]:not(.add-to-cart-outlet)`);
			if($.trim($m1Quantity.text())=='0' && $.trim($localQuantity.text())=='0') {
				$element.addClass('btn-hide');
			} else {
				$element.removeClass('btn-hide');}
			}
		});
	}
	function checkOld(){
		var data=read();
		var result =[];
		var d = new Date().getTime();
		var keys=Object.getOwnPropertyNames(data);
		keys.forEach(function(key){
			var t=new Date();
			if(data[key].date<d){
				delete data[""+key];
			}
		})
		save(data);
	}
	function reload(){
		$('.wc-product-quantity').each(function(){
			var id = $(this).data('id');
			load($(this).data('id'), false);
		});
	}
	function init(){
		setEvents();
		checkOld();
		refreshStocks();
	}


	return {
		init,
		reload
	};
});
