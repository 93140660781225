app.service('emailInvalid', function (pageConfig, front, modal) {
	var $email, $box, emailRulesList;
	function init($e, $s, $b) {
		$email = $e;
		$box = $b;
		$suggest = $s;
		emailRulesList = pageConfig.getData('emailsRules').emailRulesList;
		if ($email.length && $suggest.length && $box.length && emailRulesList.length) {
			$email.on('change keyup paste blur', function () {
				showSuggestion();
			});
			$suggest.mouseenter(function () {
				$(this).parent().focus().click();
			}).click(function () {
				$email.val($(this).text()).change();
			});
			showSuggestion();
		}
	}
	function showSuggestion(){
		var result = rules();
		if (result.length) {
			$suggest.html(result[0] + '<strong>@' + result[1] + '</strong>');
			$box.addClass('email-invalid');
		} else {
			$box.removeClass('email-invalid');
		}
	}
	function rules() {
		var value = $email.val();
		var email = value.split('@');
		var result = [];
		if (email.length && front.getParam('emailReg').test(value)) {
			emailRulesList.forEach(function (element) {
				if (email[1] == element.bad) {
					result = [email[0], element.correct];
				}
			});
		}
		return result;
	}
	function check(callback, editCallback) {
		var result = rules();
		if (result.length) {
			modal.generate({
				content: `<h2>Czy Twój e-mail jest poprawny?</h2><h3>${$email.val()}</h3>`,
				id:'modal-email-rules',
				buttons: [
					{
						text: 'Tak, poprawny',
						click: function () {
							modal.close();
							callback()
						},
						cssClass: 'yes'
					}, {
						text: 'Nie, zmień',
						click: function () {
							if (editCallback) {
								editCallback();
							} else {
								modal.close();
								$.scrollTo( $box );
							}
						},
						cssClass: 'no'
					}
				]
			});
		} else {
			callback();
		}
	}
	return {
		init, check
	};
});