app.service('mainPageInteraction', function() {
	let returnUser, prefix;
	function set(action, label) {
		if(label){
			UA.interaction('Test SG', prefix+' '+action, label);
		}else{
			UA.interaction('Test SG', prefix+' '+action);
		}
	}
	function init(a){
		returnUser = a == true;
		prefix = returnUser ? '[RV]' : '[NV]';
		UA.push({"event":returnUser? 'customer_rv' : 'customer-nv'});
	}

	return {
		set, init
	};
});
