/**
 * Umozliwia powrot do poprzeniej strony na podstawie localstorage 'refpage'
 * @type {module}
 */
app.service('goBack',function($global, console, localStorage, loading, front){
	function go(){
		console.log('POWRÓT DO POPRZEDNIEJ STRONY');
		var url='/';
		if (!localStorage.empty('refpage')){
			url = check(localStorage.getItem('refpage'));
		}
		loading.redirect(url);
	}

	function check(tmpUrl){
		tmpUrl = "" +tmpUrl;
		if(tmpUrl.indexOf(front.getParam('hostname'))!==0){
			return "";
		}
		if(tmpUrl.indexOf("<")>-1){
			tmpUrl = tmpUrl.substr(0, tmpUrl.indexOf("<"));
		}
		return tmpUrl;
	}

	function setRefPage(){
		if(document.location.search!=='?print=2' && front.group() != 'order' && front.page() != 'to-cart' && front.group() != 'account' && (typeof cart === "undefined" || !cart) && !(typeof(www) === 'object' && www.pageType && www.pageType=='account')){
			localStorage.setItem('refpage', check(self.location.href));
		}
	}
	$global('goBack',go);
	return {
		setRefPage:setRefPage,
		go:go
	};
});
