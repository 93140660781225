app.service('lazyLoad', function() {
	var lazyLoad;

	function callbackLoad(img) {
		var $img=$(img);
		var $productContainer = $img.closest('.js-UA-product');
		if($productContainer.length){
			var productId=$productContainer.find('.productId').val();
			var productList=$productContainer.find('.productList').val();
			UA.setProductVisible(productId, productList);
		}
	}

	function check() {
		lazyLoad.handleScroll();
	}

	function update() {
		lazyLoad.update();
	}

	function setEvents() {
		var $document = $(document);
		$document.ajaxComplete( update );
		$document.on( 'lazyLoad:check', check );
		$document.on( 'opening, opened', '.remodal', update );
		$document.on('fancybox-opened', update);               //reagowanie na otwieranie fancybox'a z uslugami w k1
	}

	function init() {
		lazyLoad = new LazyLoad({
			elements_selector: "img.lazy",
			callback_load: callbackLoad
		});
		setEvents();
	}

	return {
		init: init,
		check: check,
		update: update
	};
});
