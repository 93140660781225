/**
 * Moduł obsługujący modal "Brakuje ci opcji przeglądania? Zgłoś!"
 * @type {module}
 */
app.service('reportNotify', function (modal, front, cookie, loading, validate, account, console, html, captchaService) {
  var $modal;
	var params;
	let $self;

	function loadSuccess() {
		$self = $('#modal-notify');
		validate.task('notifyForm', function(form) {
			send(form);
		});
		var userInfo = account.user();
		if(userInfo.email || userInfo.wcEmail) {
			$('#notify-email').val(userInfo.email || userInfo.wcEmail);
		}
		addErrorWarningCaptcha();
	}

	function addErrorWarningCaptcha() {
		let $captcha = $self.find('[data-toggle="captcha"]');
		if ($captcha.length) {
			captchaService.load($captcha.attr('id'), $captcha.data('place'));
		}
	}

	/**
	 * Pobranie formularza
	 */
	function load() {
		loading.start();
		$.ajax({
			url: '/listingFilterOptionsForm.ltr',
			success: function(content) {
					$modal = modal.generate({
						id: 'modal-notify',
						content: content,
						callbackOpen: function(){
							loadSuccess();
						}
					});
			},
			error: function () {
				modal.alert('Wystapił błąd wysyłania formularza. Prosimy spróbować później');
			},
			complete: function() {
				loading.stop();
			}
		});
	}
	/**
	 * Wysłanie formularza
	 */
	function send($form) {
		$form.addClass('loading');
		$form.serializeArray().forEach(element => {
			params[element.name] = element.value;
		});
		$.ajax({
			type: 'POST',
			url: '/listingFilterOptionsForm.ltr',
			data: params,
			success: function (json) {
				if (json.status == 'SUCCESS') {
					modal.alert('Dziękujemy za Twoje zgłoszenie');
				} else {
					modal.alert(json.messages, function () {
						$modal.open();
					});
				}
			},
			error: function() {
				modal.alert('Wystapił błąd wysyłania formularza. Prosimy spróbować później');
			}
		});
	}
	/**
	 * Przygotowanie danych do wysłania ajaxem
	 * (obecny adres url oraz przeglądarka)
	 */
	function prepareData() {
		var data = {
			currentUrl: encodeURIComponent(document.location.href),
			browser: navigator.userAgent
		};
		params = data;
	}
	/**
	 * Ustawienie event listenera
	 * kliknięcie wczytuje formularz
	 */
  function setEvents() {
    $('body').on('click', '.js-filter-notify', function() {
			prepareData();
			load();
    });
	}

	/**
	 * Inicjalizacja modułu
	 */
  function init() {
      setEvents();
  }
  return {
    init: init
  };

});
