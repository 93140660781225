app.service('operatorProductStocks',function(console){
	var $self, $container;

	function load(){
		$self.each(function(){
			addProductStocks($(this).data('id'));
		});
	}

	function addProductStocks(productId) {
		$.ajax({
			url:'/productStocksFinder.ltr',
			type:'GET',
			data: {
				productId: productId
			},
			success: function(html) {
				loadSuccess(html, productId);
			},
			error: function(e) {
			}
		});
	}

	function refreshProductStocks(productId, cityCode) {
		$.ajax({
			url:'/productStocksFinder.ltr',
			type:'POST',
			data: {
				productId: productId,
				cityCode: cityCode
			},
			success: function(html) {
				loadSuccess(html, productId);
			},
			error: function(e) {
			}
		});
	}

	function loadSuccess(html, productId) {
		$container = $self.filter('[data-id="'+ productId +'"]');
		$container.html(html);
		setCitySelect();
		$(window).scroll();
	}

	function setCitySelect() {
		var $input = $('.select3-input', $container);
		var cityCode = $container.find('.select3-option-selected').data('value');
		$input.select3();
		if (cityCode) {
			$input.val(cityCode);
			$input.change();
		}
		$input.addClass('js-city-code');
	}

	function setElements() {
		$self = $('.operator-product-stocks');
	}

	function setEvents() {
		$self.on('change', '.js-city-code', function() {
			$container = $(this).closest('.operator-product-stocks');
			refreshProductStocks($container.data('id'), $(this).val());
		});
		$self.on('click', '.js-expand', function() {
			$('.product-stocks-box', $self).toggleClass('show-product-stocks');
		});
		$self.on('click', '.select3-filter', function() {
			$container = $(this).closest('.operator-product-stocks');
			$('.simplebar', $container).simplebar('recalculate');
		});
	}

	function init(){
		setElements();
		setEvents();
		$(document).on('pageChange',function (){
			load();
		});
		load();
	}

	return {
		init:init
	};
});
