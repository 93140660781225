app.service('definitionsView', function(definitionItem) {
	var $letters, $lettersNext, $lettersPrev,$lettersList,	$definitionsList;
	var definitionsKeyword,
		lettersSlideNo = 0,
		lettersSlideMax,
		mode = '';

	function setElements() {
		$letters = $('#definitions-letters');
		$lettersList = $('.list',$letters);
		$lettersUl = $('ul',$letters);
		$lettersNext = $('.next',$letters);
		$lettersPrev = $('.prev',$letters);
		mode=$letters.data('mode');
		resize();

		$definitionsList = $('#definitions-lists .list');
		definitionsKeyword = $.trim($('#definitions-keyword').val());
	}

	function setEvents() {
		if (mode === 'list') {
			$lettersUl.find('a').on('click',function(event){
				event.preventDefault();
				showDefinitionsByLetter($(this));
			});
		}
		else{
			definitionItem.init('page');
		}
		$letters.swipe({
			swipe:function(event, direction, distance, duration, fingerCount, fingerData) {
				letterSwipe(direction);
			},
			excludedElements: "label, button, input, select, textarea, .noSwipe"
		});
		$lettersNext.on('click',function() {
			letterSwipe('left');
		});
		$lettersPrev.on('click',function() {
			letterSwipe('right');
		});
	}

	function init() {
		setElements();
		if (definitionsKeyword !== '' && $definitionsList.length) {
			$.scrollTo($definitionsList);
		}
		setEvents();
	}

	function showDefinitionsByLetter(element) {
		if(!element.hasClass('active')){
			$letters.find('a').removeClass('active');
			element.addClass('active');
			$definitionsList.hide().filter('.list-'+element.attr('data-letter')).show();
		}
	}

	function resize(){
		lettersSlideNo = 0;
		lettersSlideMax=parseInt($lettersUl.width()/$lettersList.width());
		letterSwipe();
	}
	function letterSwipe(direction){
		$letters.removeClass();
		if (direction == 'left') {
			if (lettersSlideNo < lettersSlideMax) {
				lettersSlideNo++;
			}
		}
		if (direction == 'right') {
			if (lettersSlideNo > 0) {
				lettersSlideNo--;
			}
		}
		if(lettersSlideNo==lettersSlideMax-1){
			$letters.addClass('letters-slide-last');
		}
		$letters.addClass('letters-slide-'+lettersSlideNo);
	}
	return {
		init: init
	};
});
