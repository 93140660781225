app.service('topSuggestionCalculator', function () {

	function calculate(productInteractions, config) {

		const productsRating = getProductsRating(byProductId(productInteractions), config)
			.filter(productRating => productRating.productRate >= config.minimalRating);

		return topSuggestions(groupByGroupId(productsRating))
			.sort((a, b) => a.position - b.position);
	}

	function topSuggestions(productGroupsRating) {

		return Object.keys(productGroupsRating).map(groupId => {
			const productRating = productGroupsRating[groupId][0];

			return {
				topProduct: +productRating.productId,
				groupId,
				groupName: productRating.groupName,
				position: productRating.position
			};
		});
	}

	function getProductsRating(eventsByProductId, config) {

		const compare = (a, b) => (a > b) - (a < b);

		return calculateRates(eventsByProductId, config)
			.sort((a, b) => compare(b.productRate, a.productRate) || compare(b.latestVisit, a.latestVisit))
			.map((rating, position) => {
				rating.position = position;
				return rating;
			});
	}

	function calculateRates(eventsByProductId, config) {

		return Object.keys(eventsByProductId).map(productId => {
			const productEvents = eventsByProductId[productId];
			const group = findGroup(productEvents);
			return {
				productId,
				productRate: productRate(productEvents, config),
				groupId: group.id,
				groupName: group.name,
				latestVisit: findLatestVisit(productEvents)
			};
		});
	}

	function findLatestVisit(productEvents) {
		return productEvents
			.filter(event => event.type === 'VISIT')
			.sort((a, b) => new Date(a.data.date) - new Date(b.data.date))
			.map(item => item.data.date)[0];
	}

	function findGroup(productEvents) {

		const event = productEvents.filter(e => e.data.groupId)[0];

		return event ? {
			id: event.data.groupId,
			name: event.data.groupName
		} : {
			id: null,
			name: null
		};
	}

	function productRate(productEvents, config) {

		const visit = visitData(productEvents);

		return productEvents
				.filter(event => event.type !== 'VISIT')
				.reduce((acc, event) => {
					acc += eventRate(event, config);
					return acc;
				}, 0)
			+ visit.count * config.visitCount
			+ visit.totalDuration * config.visitDuration;
	}

	function visitData(productEvents) {
		return productEvents
			.filter(event => event.type === 'VISIT')
			.reduce((acc, event) => {
				acc = acc || {};
				acc.count = acc.count + 1 || 1;
				acc.totalDuration = (acc.totalDuration || 0) + event.data.visitDuration;

				return acc;
			}, {});
	}

	function eventRate(event, config) {

		switch (event.type) {

			case "ADDED_TO_CART":
				return +config.addedToCart;

			case "ADDED_TO_COMPARE":
				return +config.addedToCompare;

			case "ADDED_TO_CLIPBOARD":
				return +config.addedToClipboard;

			default:
				break;
		}
	}

	function groupByGroupId(productsRating) {

		return productsRating.reduce((acc, rating) => {
			if (!rating.groupId) return acc;

			acc[rating.groupId] = acc[rating.groupId] || [];
			acc[rating.groupId].push(rating);

			return acc;
		}, {});
	}

	function byProductId(productInteractions) {

		return productInteractions.reduce((acc, event) => {
			acc[event.data.productId] = acc[event.data.productId] || [];
			acc[event.data.productId].push(event);
			return acc;
		}, {});
	}

	return {
		calculate
	};
});