app.service('breadcrumb',function(ajaxPage, front) {
	var $self;

	function setEvents(){
		$('body').on('click', '#breadcrumb a[href!="/"]', function(event){
			if(['leaf','node'].indexOf(front.page())>-1){
				ajaxPage.load({
					url: $(this).attr('href')
				});
				event.preventDefault();
			}
		});
	}

	function init() {
		setEvents();
	}
	return {
		init:init
	};
});
