app.service('passwordInvalid', function () {
    
	let $self, $input, $box, $row, $email, $passwords, $password1, $password2, $password3, $password4, $password5, $password6, $password7, $password8, $passwordsWithout;
	let value;
	let enabledValidate = false;
	let errorsList = [];
	function init(input, box, row, email=false) {
		$input = input;
		$box = box;
		$row = row;
		$email = email;
		$passwords = $('input:password', $box.closest('form'));  
		setElements();
		setEvents();
	}
	function setElements() {
		$self = $input.closest('form');
		$password1 = $box.find('.password-rule-1');
		$password2 = $box.find('.password-rule-2');
		$password3 = $box.find('.password-rule-3');
		$password4 = $box.find('.password-rule-4');
		$password5 = $box.find('.password-rule-5');
		$password6 = $box.find('.password-rule-6');
		$password7 = $box.find('.password-rule-7');
		$password8 = $box.find('.password-rule-8');
		$passwordsWithout = $box.find('.password-without');
		$row.addClass('row-register-password');
	}
	function setEvents() {
		$input.on('change keyup paste', changeInput)
		.on('blur', function(){
			if ($input.val() != '') {
				enabledValidate = true;
				changeInput();
			}
		});
		$box.on('click', '.js-password-toogle', function () {
			let newType = $input.attr('type') == 'password' ? 'text' : 'password';
			$passwords.attr('type', newType);
			$row.attr('type', newType);
		});
	}

	function changeInput() {
		[$password2, $password3, $password4, $password5, $password7].forEach($element => {
			$element.removeClass('ok error');
		});
		[$password1, $password6, $password8].forEach($element => {
			if(!$element.hasClass('error')) {
				$passwordsWithout.removeClass('show-without');
			}
		});
		errorsList = [];
		value = typeof $input.val() === 'string' ? $input.val() : String($input.val());
		password1();
		password2();
		password3();
		password4();
		password5();
		if ($email) {
			password6();
		}
		password7();
		password8();

		if (errorsList.length === 0 && value.length) {
			$row.removeClass('error-box');
		} else if (errorsList.length > 0) {
			$row.addClass('error-box');
		}

		function password1() {
			$password1.removeClass('error');
			if (/[\s]/.test(value)) {
				$passwordsWithout.addClass('show-without');
				$password1.addClass('error');
				errorsList.push('password1');
			}
		}

		function password2() {
			if (/[A-ZŻĄĘĆŹŃŁÓ]/.test(value)) {
				$password2.addClass('ok');
			} else if(enabledValidate) {
				$password2.addClass('error');
				errorsList.push('password2');
			}
		}
		function password3() {
			if (/[a-zżąęźśćńłó]/.test(value)) {
				$password3.addClass('ok');
			} else if (enabledValidate) {
				$password3.addClass('error');
				errorsList.push('password3');
			}
		}
		function password4() {
			if (/[0-9]/.test(value)) {
				$password4.addClass('ok');
			} else if (enabledValidate) {
				$password4.addClass('error');
				errorsList.push('password4');
			}
		}
		function password5() {
			if (/[!"#$%&'()*+,-./:;<=>?@`_{|}~\^\[\]\\]/.test(value)) {
				$password5.addClass('ok');
			} else if (enabledValidate) {
				$password5.addClass('error');
				errorsList.push('password5');
			}
		}
		function password6() {
			$password6.removeClass('error');
			var email = ($email.val() || "").toLowerCase();
			if (email && value.toLowerCase().indexOf(email) != -1) {
				$passwordsWithout.addClass('show-without');
				$password6.addClass('error');
				errorsList.push('password6');
			}
		}
		function password7() {
			$password7.removeClass('error');
			if (value.length >= 8) {
				$password7.addClass('ok');
			} else if (enabledValidate) {
				$password7.addClass('error');
				errorsList.push('password7');
			}
		}
		function password8() {
			$password8.removeClass('error');
			if (value.length > 30) {
				$passwordsWithout.addClass('show-without');
				$password8.addClass('error');
				errorsList.push('password8');
			}
		}
	}

	function checkValid() {
		changeInput();
		if (errorsList.length === 0) {
			return true;
		}
		return false;
	}
	function check() {
		enabledValidate = true;
		return checkValid();
	}

	return {
		init, check
	};
});