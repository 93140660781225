/*
	Moduł wyświetlający listę sklepów dla danego produktu
 */
app.service("shopsDetails", function(loading, front, console, modal, validate, userPreferences, cookie) {
	var $self;
	var lastAjaxData;

	function load(ajaxData) {
		lastAjaxData=ajaxData;
		return $.ajax({
			url: front.katalog?'/nearestShopStocks.ltr':'/refresh-shops-with-product.ltr',
			data: ajaxData,
			beforeSend: function () {
				$self.addClass('in-progress');
			},
			success:function(html){
				loadSuccess(html);
			},
			error:function(){
				loadError();
			},
			complete: function () {
				$self.removeClass('in-progress');
			}
		});
	}

	function loadError(){
		console.log('Loading shop details ERROR');
	}
	function loadSuccess(html){
		$self.html(html);
		setEvents();
	}

	function setEvents(){
		$('#more-shops', $self).on('click', function(){
			showMore();
		});
		$('.js-show-all-available-shops', $self).click(function () {
			UA.interaction('Sprawdź dostępność w sklepach', 'Towar niedostępny w danym kodzie / mieście', 'Pokaż wszystkie sklepy z danym towarem');
			load($.extend(true,lastAjaxData,{
				showAllAvailableShops:true
			}));
		});
	}

	function showMore(){
		$('#shops-list .shop-item.hidden:lt(5)', $self).removeClass('hidden');

		if($('#shops-list .shop-item.hidden', $self).length === 0){
			$('#shops-list', $self).removeClass('show-more');
		}
	}

	function showShops(productId, params) {

		var data = $.extend({
			product_id: productId
		}, params);

		var location = userPreferences.getShopLocation();
		var wcShopCode = params.shopCode || false;
		if (location && !wcShopCode) {
			if(/^[0-9]{2}\-[0-9]{3}$/.test(location)){
				data.zipCode = location;
			}else{
				data.city = location;
			}
		}

		var ajax = load(data);

	}

	function init(product, data){
		$self=$('#shops-details');
		if(product){
			showShops(product, data);
		}
	}

	return {
		init: init,
		showShops: showShops
	};
});
