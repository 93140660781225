/**
 * Strona pustych wyników wyszukiwania
 * Inicjalizuje moduły na stronie
 */
app.service('emptySearchResultsView', function (relatedKeywordsSection, suggestedProductsSection, emptySearchResultsContactForm, recommendedProductsSection, pageConfig, autocompleteInfo, productsListConfig) {

	function init() {
		relatedKeywordsSection.init();
		suggestedProductsSection.init();
		emptySearchResultsContactForm.init();
		recommendedProductsSection.init();
		setUA();
	}

	function setUA() {
		const keyword = pageConfig.getData('emptySearchResults').keyword;
		const config = productsListConfig.getData();
		UA.interaction('Site Search', "Zerowe wyniki wyszukan", keyword);
		autocompleteInfo.logResult('listing', config)
	}

	return {
		init
	};
});