app.service('mainPageBrands', function (lazyLoad, viewPoint, mainPageInteraction) {
	let $self;

	function init() {
		$self = $('#brands-section');

		if (!$self.length) return false;

		new PreviewGallery({
			selector: $self.find("#brands-carousel"),
			perPage: {
				0: 6,
				1231: 7
			},
			navigation: $self.find('.brands-carousel-nav'),
			onChange() {
				lazyLoad.update();
			},
			onResize() {
				lazyLoad.update();
			},
			onInit: function onInit() {
				lazyLoad.update();
			},
			swipe: true
		});

		viewPoint.set({
			element: $self,
			height: 0.6*$self.height(),
			callback: function(){
				mainPageInteraction.set('Sekcja strefa marek - wyswietlenie');
			}
		});

		$self.on('click', '.js-carousel-next', function(){
			mainPageInteraction.set('Sekcja Strefa Marek - klikniecia', 'Strzalka|Nastepny');
		});
		$self.on('click', '.js-carousel-prev', function(){
			mainPageInteraction.set('Sekcja Strefa Marek - klikniecia', 'Strzalka|Poprzedni');
		});

	}

	return {
		init
	};
});
