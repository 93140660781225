app.service('productOpinions',function() {
	var $opinionPreview, $toggleContentButton;

	function preview(id){
		var href='/customer-opinion-preview.ltr';
		$.fancybox({
			href:href+'?id='+id
		},{
			autoDimensions: true,
			padding: 10,
			onComplete:function(){
				$('.js-toggle-content').hide();
			}
		});
	}
	function setEvents(){
		$opinionPreview.on('click',function(){
			preview($(this).data('id'));
		});
	}
	function setElements(){
		$opinionPreview = $self.find('.js-opinion-fancy');
		$toggleContentButton = $self.find('.js-toggle-content');
	}
	function init(){
		$self = $('#opinion-table');
		setElements();
		setEvents();
	}
	return {
		init: init,
	};
});
