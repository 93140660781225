app.service('developSearchModeTest', function ($global) {

	let isDevelopSearchModeEnabled = false;

	$global("enableDevelopSearchMode", function enableDevelopSearchMode() {
		isDevelopSearchModeEnabled = true;
	});

	return {
		isEnabled: () => isDevelopSearchModeEnabled || test.getVar("ab_dev_search") === "B"
	};
});