app.service('viewPoint', function(_, $global) {
	let list=[];
	let $window = $(window);
	let timeout = 300;

	function init() {
		_.onScroll(scrollY => checkVisible(scrollY));
	}
	function checkVisible(scrollTop){
		if(list.length){
			let windowHeight = $window.height();
			let windowWidth = $window.width();
			list.forEach((item, i) => {
				var boxOffset = item.$element.offset().top;

				if(windowHeight > 1 && windowWidth > 1 && boxOffset + item.height < scrollTop + windowHeight && boxOffset + item.height > scrollTop){
					item.callback();
					list.splice(i, 1);
				}
			});

		}
	}
	function set(params){
		if(params.element.length){
			setTimeout(function(){
				list.push({
					$element: params.element,
					height: params.height ? params.height : params.element.height()*0.1,
					callback: params.callback ? params.callback : function(){}
				});
				checkVisible($window.scrollTop());
			}, timeout);
		}
	}
	$global('viewPoint',set);
	init();
	return {
		set
	}
});
