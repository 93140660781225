app.service('recommendationsApi', function () {

	function fetchRecommendations(productId) {
		return $.ajax({
			url: 'top-product-recommendations.ltr',
			data: {
				productId
			}
		});
	}

	return {
		fetch: fetchRecommendations
	};
});

