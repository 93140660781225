app.service('dayDeal', function (dayDealCounter, lazyLoad, viewPoint, mainPageInteraction) {
	let $self;

	function init() {
		const $selector = $(".js-day-deal-carousel");
		$self = $('#day-deals-section');
		if ($selector.length){
			const carousel = new PreviewGallery({
				selector: $selector,
				autoPlay: true,
				swipe: true,
				navigation: $('.day-deal-carousel-nav'),
				onChange() {
					lazyLoad.update();
				},
				onResize() {
					dayDealCounter.update();
					lazyLoad.update();
				}
			});

			carousel.addPagination(".day-deal-pagination");

			dayDealCounter.init();
		}
		initUA();
	}

	function initUA(){
		viewPoint.set({
			element: $self,
			height: 0.6*$self.height(),
			callback: function(){
				mainPageInteraction.set('Sekcja Hot Deals - wyswietlenie');
			}
		});
		$self.on('click','.js-carousel-next',function(){
			mainPageInteraction.set('Sekcja Hot Deals - klikniecia', 'Strzalka|Nastepny');
		});
		$self.on('click','.js-carousel-prev',function(){
			mainPageInteraction.set('Sekcja Hot Deals - klikniecia', 'Strzalka|Poprzedni');
		});
		$self.on('click','.product-name',function(event){
			var index = $(this).closest('.day-deal-product').index()+1;
			mainPageInteraction.set('Sekcja Hot Deals - klikniecia', 'Rotator|'+index+'|Naglowek');
		});
		$self.on('click','.check-details',function(){
			var index = $(this).closest('.day-deal-product').index()+1;
			mainPageInteraction.set('Sekcja Hot Deals - klikniecia', 'Rotator|'+index+'|Szczegoly');
		});
		$self.on('click','.more-day-deals',function(){
			var index = $(this).closest('.day-deal-product').index()+1;
			mainPageInteraction.set('Sekcja Hot Deals - klikniecia', 'Rotator|'+index+'|Zobacz wiecej Deals');
		});
		$self.on('click','.product-photo a',function(){
			var index = $(this).closest('.day-deal-product').index()+1;
			mainPageInteraction.set('Sekcja Hot Deals - klikniecia', 'Rotator|'+index+'|Zdjecie');
		});
		$self.on('click','.day-deal-box',function(){
			var index = $(this).index()+1;
			mainPageInteraction.set('Sekcja Hot Deals - klikniecia', 'Box reklamowy|'+index);
		});

	}

	return {
		init
	};
});
