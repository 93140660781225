/**
 * Warstwa usług
 */
app.service('modalService', function(modal, loading, front, cartManager, serviceManager, pageConfig) {
	var $modal;
	var $self;
	var serviceId, productId;

	function load(service, product, zipCode=undefined, productPlu) {
		serviceId = parseInt(service);
		productId = parseInt(product) || undefined;
		productPlu = parseInt(productPlu) || undefined;
		if(!zipCode && front.katalog){
			var $wcZipCode=$('#services-zip-code');
			zipCode=($wcZipCode.length && $wcZipCode.val().length && /[0-9]{2}\-[0-9]{3}/.test($wcZipCode.val()))?$wcZipCode.val():pageConfig.getData('wc').cartZipCode;
		}
		var data={
			serviceId: serviceId,
			wcZipCode: zipCode
		};
		if(!productId && productPlu){
			data.productPlu=productPlu;
		}else{
			data.productId=productId;
		}
		loading.start();
		$.ajax({
			url: '/service-front-view.ltr',
			data,
			success: function(data) {
				loadSuccess(data);
			},
			complete: function() {
				loading.stop();
			},
			error: function() {
				modal.alert('Błąd załądowania podglądu usługi. Prosimy sprobować poźniej');
			}
		});
	}

	function loadSuccess(data) {
		$modal = modal.generate({
			id: 'service-modal',
			content: data,
			cssClass: 'service-modal',
			callbackOpen: function() {
				openSuccess();
			}
		});
	}

	function openSuccess() {
		$self = $('#service-modal');
		serviceManager.checkedCheck(productId,true);
		$('.js-add-service-checkbox', $self).on('change', function() {
			serviceManager.checkboxClick({
				serviceId: serviceId,
				productId: productId,
				productPLU: $(this).data('product-plu'),
				serviceChecked: $(this).is(':checked'),
				addedFrom: 'SERVICE_DESCRIPTION',
				place: 'Usługi - warstwa'
			});

			if($(this).is(':checked')) {
				modal.close();
			}
		});
		$('.js-cancel-service-checkbox', $self).on('click', function() {
			cancelClick();
			modal.close();
		});
	}

	function cancelClick() {
		if (($.inArray(serviceId, cartManager.getServices(productId)) != -1)) {
			cartManager.removeService({
				productId: productId,
				serviceId: serviceId
			});
		}
		serviceManager.checkboxCheck(serviceId, false);
	}
	function init(){
		$('body').on('click', '.js-show-service', function() {
			load($(this).data('service'));
		});

		$(document).on('modalServiceLoad', function(e){
			load(e.serviceId, false , false, e.zipCode || pageConfig.getData('wc').cartZipCode, e.productPlu);
		});
	}

	return {
		load, init
	};
});
