app.service('sharedCartManager', () => {

	function getSharedCart(cartItems) {
		return $.ajax({
			method: 'POST',
			url: '/create-shared-cart.ltr',
			data: cartItems,
			contentType: 'application/json',
			dataType: 'html'
		});
	}

	function submitForm($form, callback) {
		return $form.ajaxSubmit({
			method: 'POST',
			url: '/restore-shared-cart.ltr',
			success: function(response) {
				callback(response);
			}
		});
	}

	function getForm() {
		return $.ajax({
			method: 'GET',
			url: '/restore-shared-cart.ltr'
		});
	}

	return { getSharedCart, getForm, submitForm };
});