app.service('captchaService', function (recapcha, log) {

	function init() {
		$('[data-toggle="captcha"]').each(function () {
			const id = $(this).attr('id');
			const place = $(this).data('place');
			const username = $(this).data('username');
			load(id, place, username);
		});
	}

	function load(id, place, username) {                     //w razie zmian prośba o poinformowanie euro - korzystają z load() w hotfixie o id: 102608790601
		fetchCaptchaConfiguration(id, place, username)
			.then(response => {
				if (!response.required) return;

				recapcha.add(id, response.siteKey, response.type, place);
				logCaptchaEvent(id);
				UA.interaction('reCaptcha', place, 'Wyswietlenie');
			});
	}

	function reset(id) {
		recapcha.reset(id);
	}

	function hidePlace(place) {
		recapcha.hidePlace(place);
	}

	function fetchCaptchaConfiguration(captchaId, place, username) {
		return $.ajax({
			url: "/get-captcha-requirement.ltr",
			data: {
				place,
				username: username || undefined
			}
		})
			.fail(() => {
				$(`#${captchaId}`)
					.removeAttr('hidden')
					.append("<div class='error-message'>Wystąpił błąd ładowania ustawień formularza.</div>");
				UA.interaction('reCaptcha', place, 'Blad');
			});

	}

	function logCaptchaEvent(captchaId) {
		log.event({
			id: 'ReCaptcha',
			f03: document.referrer,
			f04: window.location.href,
			f05: captchaId,
			f06: 'normal'
		});
	}

	return {
		init,
		load,
		reset,
		hidePlace
	};
});