/**
 * Moduł obsługujacy podstrone porownanie.bhtml,
 * dodawanie i usuwanie elementow, powrot do listingu,
 * obsluga zdarzeń na stronie
 * @type module
 */
app.service('compareView', function (ajaxPage, modal, productCompare, compareConfig, socialBox, localStorage, log, account, wcClientClipboard, loading) {
	var $self, $backButton, $addToCompareBtn, $removeBtn, $showDiffBtn;
	var group, link, products;

	function setElements(){
		$self = $("#compare-view");
		$backButton = $(".js-compare-back", $self);
		$addToCompareBtn = $(".js-compare-add-next", $self);
		$removeBtn = $(".js-remove-compare", $self);
		$showDiffBtn = $(".js-show-difference", $self);
		$compareBar = $('#compare-bar', $self);
		group=compareConfig.getData().groupId;
		link=compareConfig.getData().link;
		products=compareConfig.getData().products;

		$('#top').find('#compare-bar').remove();
		$compareBar.appendTo( $('#top') );
	}
	function setEvents(){

		$('.js-clipboard-button').click(function(){
			var productsPlu=[];
			$(products).each(function(i,n){
				productsPlu.push(n.plu);
			});
			log.event({
				id: 'add-to-clipboard',
				f03: 'comparison',
				f04: productsPlu.join("|"),
				f05: front.katalog?'':account.user().frontUserLogged.toString(),
				f06: window.location.href
			});
			UA.doubleClick(productsPlu.join("|"),'dc-clipboard-sp');
		});
		$('.js-wc-save-compare').click(function(){
			var data=[];
			$(products).each(function(i,product){
				data.push(product.id);
			});
			wcClientClipboard.addCompare(data);
		});

		$backButton.on("click", goBack);
		$addToCompareBtn.on("click", goBack);
		$removeBtn.on('click',function(){
			removeFromCompare(parseInt($(this).attr("data-id")), parseInt($(this).attr("data-group")), $(this).attr("data-href"));
		});
		$showDiffBtn.on('click',function(){
			showDifference(this);
		});

		$('.row-attr td:first-child a', $self).click(function () {
			UA.interaction('Porównanie', 'Słownik', 'Cecha - '+$(this).attr('title'));
		});
		$('.row-attr td:last-child a', $self).click(function () {
			UA.interaction('Porównanie', 'Słownik', 'Wartość - '+$(this).attr('title'));
		});
	}
	function showDifference(span) {
		if ($self.hasClass('show-diff')){
			$(span).addClass('btn-show').removeClass('btn-hide');
			$self.removeClass('show-diff').addClass('no-diff');
		}else {
			$(span).removeClass('btn-show').addClass('btn-hide');
			$self.removeClass('no-diff').addClass('show-diff');
		}
	}
	function goBack(){
		var url=''+localStorage.getItem('LPBC-'+group);
		
		if(url.indexOf('.bhtml')==-1){
			url=link;
		}
		if(url.indexOf('.bhtml')>-1){
			changePage(url);
		}else{
			modal.alert('Błąd powrotu do produktów. Spróbuj ponownie później');
		}
	}
	function changePage(url){
		if(ajaxPage.history(1)===url){
			history.back();
		} else if(url == link){
			loading.redirect(url)
		} else{
			ajaxPage.load({
				url: url,
				beforeLoad: function () {
					$compareBar.remove();
				}
			});
		}
	}
	function removeFromCompare(id, group, location){

		productCompare.removeProduct(id, group);

		if(location){
			changePage(location);
		} else {
			goBack();
		}
	}

	function logEvent() {
		compareConfig.getData().products.forEach((product) => {
			log.recommendationsForAWS({
				productPlu: product.plu,
				eventCategory: 'Porównanie',
				eventAction: 'Widok porównania'
			});
		});
	}

	function init() {
		setElements();
		setEvents();
		socialBox.init('compare');
		logEvent();
	}
	return {
		init:init
	};
});
