/**
 * Moduł obsługujący element "powrot do gory"
 * @type module
 */
app.service('scrollTop',function(console, front){
	var $self;

	function setElements(){
		$self = $('<a id="scroll-top" href="#">Do góry</a>');
		if($('#main-footer').length){
			$('#main-footer').append($self);
		} else {
			$('body > .page').append($self);
		}
	}
	function setEvents(){
		$self.click(function(e){
			e.preventDefault();
			UA.interaction('Belka', 'Do góry', 'Strzałka');
			$.scrollTo(0,500);
		});
	}
	function init() {
		setElements();
		setEvents();
	}
	return {
		init:init
	};
});
