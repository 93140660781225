app.service('kredytLineRedirect', function (loading, modal, message) {
	var isBlocked = false;
	var timeout = 1200;
	var revisions = 3;

	function showNKLError() { 
		message.get('nkl-init-error-message').then(function (html) {
			loading.stop();
			modal.generate({
				id: 'nkl-init-error',
				content: html
			});
		});
	}

	function redirect(data, params) {
		if (params) {
			timeout = params.timeout;
			revisions = params.revisions;
		}
		if (!isBlocked) {
			isBlocked = true;
			if (!modal.isOpen('kredytLine-count')) {
				loading.start();
			}
			$.ajax({
				type: 'POST',
				url: '/kredytline-application-init.ltr?orderNumber=' + data.orderNumber,
				success: function (json) {
					if (json.status === 'SUCCESS' && json.url) {
						modal.close('kredytLine-count');
						loading.redirect(json.url)
					} else {
						isBlocked = false;
						revisions--;
						if (revisions > 0) {
							setTimeout(function () {
								redirect(data);
							}, timeout);
						} else {
							showNKLError()
						}
					}
				},
				error: function () {
					showNKLError();
				}
			});
		}
	}

	return {
		redirect
	};
});
