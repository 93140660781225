app.service('logData', function (front, cookie) {

	function visitId() {
		var cook = front.ole ? 'oleVisit' : 'euroVisit';
		var visit = cookie.split(cook);
		return visit[0];
	}
	function visitHash() {
		var cook = front.ole ? 'oleVisit' : 'euroVisit';
		var visit = cookie.split(cook);
		return visit[2];
	}
	function customerId() {
		var cook = front.ole ? 'oleCustomer' : 'euroCustomer';
		var customer = cookie.split(cook);
		return customer[0];
	}
	function showRecommendationsFromAWSonMainPage() {
		return (test.getVar('awsRec_ab') === 'B' || document.location.hash === '#newRecommendations');
	}

	function showRecommendationsFromAWSonProductCard() {
		return (test.getVar('awsRecKP_ab') === 'B' || document.location.hash === '#newRecommendations');
	}

	function showRecommendationsFromGoogleOnProductCard() {
		return (front.euro && (test.getVar('googleRecKP_ab') === 'B' || document.location.hash === '#newGoogleRecommendations'));
	}

	return {
		visitId, visitHash, customerId, showRecommendationsFromAWSonMainPage, showRecommendationsFromAWSonProductCard, showRecommendationsFromGoogleOnProductCard
	};
});
