app.service('helpline',function(){
	var $self;

	function show(){
		$self.addClass('active');
	}
	function hide(){
		$self.removeClass('active');
	}

	function setEvents(){
		$('body').on('click', '#helpline', function() {
			UA.interaction('Nawigacja - Domyślna / Sticky', 'Zadzwoń i zamów', $.trim($self.find('.helpline-hours').text()));
			$self.toggleClass('active');
		});
		$('body').click(function(event){
			if( !$(event.target).closest('#helpline').length ) {
				hide();
			}
		});
	}
	function init() {
		$self=$('#helpline');
		setEvents();
	}
	return {
		init:init
	};
});
