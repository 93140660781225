app.service('productInteractionStorage', function (localStorage) {
	const EVENTS_LIMIT = 100;
	const store = {
		get events() {
			const events = localStorage.getItem('product_interaction');
			if (!events) return {
				lastEventId: 0,
				items: []
			};
			return JSON.parse(events);
		},
		set events(events) {
			try {
				localStorage.setItem('product_interaction', JSON.stringify(events));
			} catch (e) {
				console.error("productInteractionStorage error", e);
			}
		}
	};

	function addEvent(eventData) {

		if (itemsLimitExceeded(eventData)) {

			removeOldestItem(eventData);
		}

		const events = store.events;

		const event = $.extend(eventData, {
			id: events.lastEventId + 1
		});

		store.events = $.extend({}, events, {
			lastEventId: event.id,
			items: [...events.items, event]
		});

		return event;
	}

	function removeOldestItem(event) {
		const eventId = findOldestItem(event);

		if (eventId) {
			removeEvent(eventId);
		}
	}

	function removeEvent(eventId) {

		const events = store.events;

		store.events = $.extend({}, events, {
			items: [...events.items.filter(e => e.id !== eventId)]
		});
	}

	function findOldestItem(eventData) {
		const event = store.events.items
			.filter(e => e.type === eventData.type && e.productId === eventData.productId)
			.sort((a, b) => new Date(a.data.date) - new Date(b.data.date));

		return event ? event[0].id : null;
	}

	function itemsLimitExceeded(event) {

		return store.events.items
			.filter(e => e.type === event.type && e.data.productId === event.data.productId)
			.length >= EVENTS_LIMIT;
	}

	function findEvent(eventId) {

		const event = store.events.items.filter(e => e.id === eventId);

		return event ? event[0] : null;
	}

	function updateEvent(event) {

		const events = store.events;

		store.events = $.extend({}, events, {
			items: [...events.items.filter(e => e.id !== event.id), event]
		});
	}

	function findEventByProductId(type, productId) {

		const event = store.events.items.filter(e => e.type === type && e.data.productId === productId);

		return event ? event[0] : null;
	}

	function findAll() {

		return [...store.events.items];
	}

	function removeOlderThan(expirationDate) {
		let expiration = new Date();

		expiration.setDate(expiration.getDate() - expirationDate);

		const events = store.events;

		if (!events.items.length) return false;

		store.events = $.extend({}, events, {
			items: events.items.filter(e => new Date(e.data.date) > expiration)
		});
	}

	function removeEventsForProduct(productId) {

		const events = store.events;

		store.events = $.extend({}, events, {
			items: [...events.items.filter(e => e.data.productId !== productId)]
		});
	}

	function removeEventsByGroup(groupId) {

		const events = store.events;

		store.events = $.extend({}, events, {
			items: [...events.items.filter(e => e.data.groupId !== +groupId)]
		});
	}

	return {
		addEvent,
		findEvent,
		updateEvent,
		findEventByProductId,
		findAll,
		removeOlderThan,
		removeEventsForProduct,
		removeEventsByGroup
	}
});