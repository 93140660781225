app.service('warrantyEncouragement',function(front, modal, log, loading, validate, productCardTest, console, warrantyQuestionnaire) {
	var $self, $encouragement, $description, $form, $wrapper, $tabs, $buttons, $close;
	var productId, redirectUrl, notIssuedOrderId, orderId, plu, packageIdentifier, relatedPromotionIdentifier, ajaxData, selectedWarrantyId, selectedWarrantyPaymentType, fromProductUpselling;
	var addWarrantyCallback, removeWarrantyCallback;
	var nextCallback;
	var addedWarrantyId, addedWarrantyType;
	var $warrantyPaymentTypeSelector;
	var $subscriptionBenefits;
	var $encouragementTable;

	var selectedWarrantyVariant = ''; // default value

	function setElements(){
		$self = $('#warranty-encouragement-modal');
		$close = $('.remodal-close', $self);
		$wrapper = $self.closest('.remodal-wrapper');
		$encouragement = $('#warranty-encouragement', $self);
		$description = $('#warranty-description', $self);
		$buttons = $('.js-add-warranty-button', $encouragement);
		$form=$('#warranty-form');
		$warrantyPaymentTypeSelector = $('.warranty-payment-type-selector input', $self);
		$tabs=$('.js-warranty-tab ', $self);
		$encouragementTable = $('.encouragement-box', $self);
		$subscriptionBenefits = $('.subscription-benefits', $encouragement);
		selectedWarrantyVariant = $encouragementTable
			.filter('.is-visible')
			.data('warranty-payment-type');
		addedWarrantyId = $encouragement.data('added-warranty-id');
		addedWarrantyType = $encouragement.data('added-warranty-type');
		photoWarrantyItemResize();
	}

	function showTableForSelectedWarrantyPaymentType(warrantyPaymentType) {
		$encouragementTable
			.filter(`[data-warranty-payment-type=${warrantyPaymentType}]`)
			.addClass('is-visible')
			.siblings()
			.removeClass('is-visible');
		photoWarrantyItemResize();
		warrantyQuestionnaire.markAnswers('#warranty-encouragement-modal');
	}
	function photoWarrantyItemResize(){
		var $box = $encouragementTable.filter('.is-visible');
		if($box.length){
			let maxHeightFeatures = 0;
			let maxHeightButtons = 0;
			let $features = $('.warranty-features', $box);
			let $buttons =  $('.warranty-buttons', $box);
			$features.css('height', 'auto');
			$buttons.css('height', 'auto');

			$($features).each(function(){
				maxHeightFeatures = Math.max(maxHeightFeatures, $(this).height());
			});
			$($buttons).each(function(){
				maxHeightButtons = Math.max(maxHeightButtons, $(this).height());
			});
			if(maxHeightFeatures){
				$features.css('height', maxHeightFeatures);
			}
			if(maxHeightButtons){
				$buttons.css('height', maxHeightButtons);
			}
		}
	}

	function changeWarrantyPaymentType(warrantyPaymentType) {
		selectedWarrantyVariant = warrantyPaymentType;
		var $selectedButton = $encouragementTable.filter('.is-visible').find('.js-add-warranty-button.selected');

		showTableForSelectedWarrantyPaymentType(warrantyPaymentType);

		if($selectedButton.length){
			let $newSelected = $encouragementTable.filter('[data-warranty-payment-type='+warrantyPaymentType+']').find('.js-add-warranty-button[data-warranty-id='+$selectedButton.attr('data-warranty-id')+']');
			if($newSelected.length){
				$selectedButton.removeClass('selected');
				$newSelected.addClass('selected');
			}
		}

		$warrantyPaymentTypeSelector
			.val(warrantyPaymentType)
			.change();

	}

	function handleWarrantyPaymentTypeChange() {
		changeWarrantyPaymentType($(this).val());
	}

	function setEvents(){
		if(front.katalog){
			$tabs.click(function(){
				$encouragement.attr('data-tab', $(this).data('tab'));
			});
		}else{
			if ($form.length) {
				validate.task('warrantyEncouragement',function(form){
					addWarranty();
				});
			}
			$wrapper.click(function(event){
				var action = '';
				action = $(event.target).is($wrapper) ? 'Background' : action;
				action = $(event.target).is($close) ? 'Exit' : action;
				if($(event.target).is($wrapper) || $(event.target).is($close)){
					if($form.valid()){
						UAnext(action);
						formSubmit();
					}else{
						scrollToError();
					}
				}
			});

			$warrantyPaymentTypeSelector.on('selectOption', handleWarrantyPaymentTypeChange);

			$buttons.on('click',function(){
				// resetowanie prezentowanych wszystkich benefitow abonamentowych do domyślnych
				$subscriptionBenefits.removeClass('is-visible');
				$subscriptionBenefits.filter('.default').addClass('is-visible');

				if($(this).hasClass('selected')){
					$encouragement.removeClass('selected');
					$(this).removeClass('selected');
				}else{
					$buttons.removeClass('selected');
					$encouragement.addClass('selected');
					$(this).addClass('selected');

					// prezentowanie benefitow abonamentowych (w ramach wariantu) dla wybranej gwarancji abonamentowej
					$(this).closest('.warranty-item').find('.subscription-benefits').removeClass('is-visible')
						.filter('[data-benefit-warranty-id="'+$(this).data('warranty-id')+'"]').addClass('is-visible');
				}
			});
			$('.warranty-payment-type-selector .select3-selected-item', $self).click(function(){
				setTimeout(function(){

					$self.parent().scrollTop(200)
				}, 50);
			})

			$(document).keyup(function(e) {
				if (e.keyCode == 27){
					formSubmit();
				}
			});
		}

		$('.js-add-warranty', $encouragement).on('click',function(){
			// Potykacz gwarancyjny listing SI, katalog -> CTA DALEJ
			UAnext('Dalej');
			if(addedWarrantyId && addedWarrantyType && (($buttons.length && !$buttons.filter('.selected').length))){
				removeWarranty();
			}else if($buttons.filter('.selected').length){
				formSubmit();
			}else {
				modal.close();
			}
		});

		$('.js-add-warranty', $description).on('click', function(){
			// Potykacz gwarancyjny (warunki ubezpieczenia) listing SI, katalog -> CTA DO KOSZYKA
			const warrantyPaymentType = $(this).data('warranty-payment-type');
			const warrantyId = $(this).data('warranty-id');
			UA.interaction(front.page(), "Potykacz gwarancyjny", warrantyId);
			addWarrantyCallback({				//cartManager.addWarranty
				warrantyPaymentType,
				productId: productId,
				addedFrom: 'ENCOURAGEMENT',
				warrantyId,
				callback:function(){
					modal.close();
				}
			});
		});

		$encouragement.on('click', '.js-warranty-info', function(){
			const warrantyType = $(this).data('warranty-type');
			$self.addClass('show-description');
			$('.warranty-item', $description).removeClass('visible');
			$('.warranty-info-'+$(this).data('warranty-id')).filter(`[data-warranty-type=${warrantyType}]`).addClass('visible');
		});

		$('.js-warranty-back-button',$description).on('click',function(){
			$self.removeClass('show-description');
		});

		$('.js-wc-add-warranty', $encouragement).on('click',function(){
			UA.interaction(front.page(), "Potykacz gwarancyjny", $(this).data('warranty-id'));
			addWarrantyCallback({				//cartManager.addWarranty
				productId: productId,
				addedFrom: 'ENCOURAGEMENT',
				warrantyId: $(this).data('warranty-id'),
				notIssuedOrderId: notIssuedOrderId,
				warrantyPaymentType: $(this).data('warranty-payment-type'),
				callback:function(){
					modal.close();
				}
			});
		});

		$('.js-wc-warranty-next', $encouragement).on('click',function(){
			if (typeof nextCallback === 'function') {
				nextCallback();
			}
			UA.interaction(front.page(), "Potykacz gwarancyjny", 'Dalej');
			modal.close();
		});
	}
	function removeWarranty(){
		removeWarrantyCallback({
			productId: productId,
			addedFrom: 'ENCOURAGEMENT',
			warrantyPaymentType: addedWarrantyType,
			warrantyId: addedWarrantyId,
			packageIdentifier: packageIdentifier,
			callback:function(){
				modal.close();
			}
		});
	}

	function UAnext(action){
		var label = null;
		if($buttons.length){
			label = 'Bezpłatna';
		}
		if($buttons.filter('.selected').length){
			label = $buttons.filter('.selected').attr('data-UA-label');
		}
		UA.interaction(front.page(), "Potykacz gwarancyjny", action, label);
		log.event({
			id: "Potykacz gwarancyjny",
			f03: document.location.href,
			f04: action,
			f05: label
		});
	}

	function setEventsForNewProductCardTest() {
		validate.task('warrantyEncouragement',function(form){
			addWarranty();
		});

		$warrantyPaymentTypeSelector.on('selectOption', handleWarrantyPaymentTypeChange);

		$wrapper.click(function(event){
			if($(event.target).is($wrapper) || $(event.target).is($close)){
				if ($form.valid()) {
					addWarranty();
					modal.close();
				}
			}
		});

		$(document).keyup(function(e) {
			if (e.keyCode === 27) {
				if ($form.valid()) {
					addWarranty();
					modal.close();
				}
			}
		});

		$buttons.on('click',function(){
			// resetowanie prezentowanych wszystkich benefitow abonamentowych do domyślnych
			$subscriptionBenefits.removeClass('is-visible');
			$subscriptionBenefits.filter('.default').addClass('is-visible');

			if($(this).hasClass('selected')){
				$encouragement.removeClass('selected');
				$(this).removeClass('selected');
			}else{
				$buttons.removeClass('selected');
				$encouragement.addClass('selected');
				$(this).addClass('selected');

				// prezentowanie benefitow abonamentowych (w ramach wariantu) dla wybranej gwarancji abonamentowej
				$(this).closest('.warranty-item').find('.subscription-benefits').removeClass('is-visible')
					.filter('[data-benefit-warranty-id="'+$(this).data('warranty-id')+'"]').addClass('is-visible');
			}
		});

		$('.js-add-warranty', $encouragement).on('click',function(){
			// Potykacz gwarancyjny nowa KP SI -> CTA DALEJ
			UA.interaction(front.page(), "Potykacz gwarancyjny", 'Dalej');
			if ($buttons.filter('.selected').length) {
				if ($form.valid()) {
					addWarranty();
					modal.close();
				}
			} else {
				modal.close();
			}
		});

		$encouragement.on('click', '.js-warranty-info', function(){
			const warrantyType = $(this).data('warranty-type');
			$self.addClass('show-description');
			$('.warranty-item', $description).removeClass('visible');
			$('.warranty-info-'+$(this).data('warranty-id')).filter(`[data-warranty-type=${warrantyType}]`).addClass('visible');
		});

		$('.warranty-payment-type-selector .select3-selected-item', $self).click(function(){
			setTimeout(function(){
				$self.parent().scrollTop(200)
				warrantyQuestionnaire.markAnswers('#warranty-encouragement-modal');
			}, 50);
		});

		$('.js-add-warranty', $description).on('click', function(){
			// Potykacz gwarancyjny (warunki ubezpieczenia) nowa KP SI -> CTA DO KOSZYKA
			const warrantyPaymentType = $(this).data('warranty-payment-type');
			UA.interaction(front.page(), "Potykacz gwarancyjny", $(this).data('warranty-id'));
			addWarrantyCallback({
				warrantyPaymentType,
				productId: productId,
				addedFrom: 'ENCOURAGEMENT',
				warrantyId: $(this).data('warranty-id')
			});
			modal.close();
		});

		$('.js-warranty-back-button',$description).on('click',function(){
			$self.removeClass('show-description');
		});
	}

	function formSubmit(){
		$form.submit();
	}

	function addWarranty(){
		var $button=$buttons.filter('.selected');
		var warrantyPaymentType = $button.data('warranty-type');
		var warrantyId = $button.data('warranty-id');
		var warrantyCategoryType = $button.data('warranty-category');

		if($form.valid()){
			if(warrantyId && warrantyId !== "0"){
				addWarrantyCallback({				//cartManager.addWarranty
					productId: productId,
					addedFrom: 'ENCOURAGEMENT',
					warrantyPaymentType: warrantyPaymentType,
					warrantyId: warrantyId,
					warrantyCategoryType: warrantyCategoryType,
					packageIdentifier: packageIdentifier,
					relatedPromotionIdentifier: relatedPromotionIdentifier,
					callback:function(){
						modal.close();
					}
				});
			}else{
				modal.close();

				if (productCardTest.enabled && fromProductUpselling) {
					addWarrantyCallback({ noWarrantySelected: true });
				}
			}
		}else{
			scrollToError();
		}
	}

	function scrollToError(){
		$wrapper.scrollTop(10000);
	}

	function load(json, obj, callback, removeCallback){
		addWarrantyCallback=callback;
		removeWarrantyCallback=removeCallback || function(){};
		redirectUrl = json.redirectUrl;
		productId = obj.productId || undefined;
		//packageIdentifier jest brany z odpowiedzi po dodaniu pakietu do koszyka stąd json.packageIdentifier, na K1 z obj.packageIdentifier
		packageIdentifier = obj.packageIdentifier || json.packageIdentifier || undefined;
		relatedPromotionIdentifier = obj.relatedPromotionIdentifier || json.relatedPromotionIdentifier || undefined;
		orderId = obj.orderId || undefined;
		plu = obj.plu || undefined;
		notIssuedOrderId = obj.notIssuedOrderId || undefined;
		var unpaidReservation = obj.unpaidReservation || undefined;
		var complaintExchange = obj.complaintExchange || undefined;

		nextCallback = json.nextCallback || undefined;
		ajaxData = {
			productId: productId,
			plu: plu,
			orderId: orderId,
			warrantyConflict: json.warrantyConflict, // || null
			notIssuedOrderId: notIssuedOrderId,
			packageIdentifier: packageIdentifier,
			relatedPromotionIdentifier: relatedPromotionIdentifier
		};

		// dla potykacza na nowej KP
		if (productCardTest.enabled && !json.encouragementTypeToShow) {
			selectedWarrantyId = json.warrantyId;
			selectedWarrantyPaymentType = json.warrantyPaymentType;
			fromProductUpselling = json.fromProductUpselling;
			ajaxData.outletCategoryId = obj.outletCategoryId || undefined;
			ajaxData.instalmentCode = obj.instalmentCode || obj.instalment || undefined;
			ajaxData.instalmentNumber  = obj.instalmentNumber || undefined;
		}

		var url='/warrantyEncouragement.ltr';
		if (packageIdentifier) {
			url='/package-warrantyEncouragement.ltr';
		}else if(relatedPromotionIdentifier){
			url='/relatedPromotion-warrantyEncouragement.ltr';
		}else if(complaintExchange){
			url='/complaintExchange-warrantyEncouragement.ltr';
		}else if(notIssuedOrderId){
			url='/notIssuedOrder-warrantyEncouragement.ltr';
		}else if(unpaidReservation){
			url='/unpaidReservation-warrantyEncouragement.ltr';
		}else if(orderId){
			url = '/wc-oss-order-warranty-encouragement-view.ltr';
			ajaxData.moz = obj.moz;
			if (document.location.href.indexOf('orderId=demo') > 0) {
				url = '/javascript/demo-moz-warranty-encouragement.html';
			}
		} else if(productCardTest.enabled && !json.encouragementTypeToShow) {
			url = '/web-cached/product-detail/available-warranties.ltr';
		}
		loading.start();
		$.ajax({
			url: url,
			data: ajaxData,
			success: function(data){
				loading.stop();
				loadSuccess(data);
			},
			error:function(){
				loading.stop();
				modal.alert('Błąd ładowania gwarancji. Spróbuj ponownie później.');
			}
		});
	}

	function loadSuccess(data){
		modal.generate({
			content: data,
			close: front.katalog,
			modifier:'new-warranty-encouragement',
			id: 'warranty-encouragement-modal',
			callbackOpen: function(){
				loadSuccessCallbackOpen();
			},
			callbackClose: function(){
				loadSuccessCallbackClose();
			}
		});
	}

	function checkSelectedWarranty() {
		if (selectedWarrantyId && selectedWarrantyPaymentType) {

			changeWarrantyPaymentType(selectedWarrantyPaymentType);

			if ($buttons.length) {
				changeWarrantyPaymentType(selectedWarrantyPaymentType);
				let $selectedWarranty = $buttons.filter(`[data-warranty-id=${selectedWarrantyId}]`).filter(`[data-warranty-type=${selectedWarrantyPaymentType}]`);
				$selectedWarranty.addClass('selected');

				// wyswietlenie benefitow abonamentowych dla wybranej gwarancji abonamentowej
				$selectedWarranty.closest('.warranty-item')
					.find('.subscription-benefits')
					.removeClass('is-visible')
					.filter(`[data-benefit-warranty-id=${selectedWarrantyId}]`)
					.addClass('is-visible');
			}
		}
	}

	function loadSuccessCallbackOpen(){
		setElements();
		$warrantyPaymentTypeSelector.select3();
		if (productCardTest.enabled && fromProductUpselling) {
			checkSelectedWarranty();
			setEventsForNewProductCardTest();
		} else {
			setEvents();
		}
		photoWarrantyItemResize();
		warrantyQuestionnaire.markAnswers('#warranty-encouragement-modal');
		UA.pageview("/akcje/potykacz-gwarancyjny/wyswietl-" + front.page());
	}

	function loadSuccessCallbackClose(){
		warrantyQuestionnaire.markAnswers();
		if(orderId){
			return;
		}else if(redirectUrl && redirectUrl!==''){
			loading.redirect(redirectUrl);
		}
	}

	return {
		load
	};
});
