/**
 * Obsługa filtrowania produktow
 * Na potrzeby testów AB moduł udostępnia cztery funkcje changeSort, priceFilter, changeStatus, setLinks
 */
app.service('filterBox',function(loading, front, html, ajaxPage, localStorage, modal, lazyLoad, productsListConfig, savedShopsManager, dataSeoUrl, cookie){
	var $self, $filters, $checkboxes, $subValuesIco, $buttonsShow, $buttonsHide, $links, $priceFrom, $priceTo, $priceButton, $dictionaryLink, $sort, $name, $item, $productStocksFilter, $productStocksFilterCitySelect, $productStocksFilterItem, $warehouse;

	function setElements(){
		config = productsListConfig.getData();
		$self=$('#filter-box');
		$checkboxes=$('.js-filter-checkbox', $self);
		$buttonsShow=$('.js-filter-show', $self);
		$buttonsHide=$('.js-filter-hide', $self);
		$filters=$('.filter', $self);
		$links=$('.filter-typ-checkbox a', $self);
		$priceFrom=$('#filter-price-from', $self);
		$priceTo=$('#filter-price-to', $self);
		$priceButton=$('#filter-price-button', $self);
		$sort=$('#select-sort');
		$dictionaryLink=$('.js-dictionary', $self);
		$name=$('.js-filter-name', $self);
		$item=$('.js-filter-item.filter-active', $self);
		$subValuesIco=$('.js-filter-show-subOptions', $self);
		$productStocksFilter=$('#filter-stocks', $self);
		$productStocksFilterCitySelect=$('#city-select-stock', $productStocksFilter);
		$productStocksFilterItem=$('.js-filter-stocks-shop', $productStocksFilter);
		$clearSelectedShops = $('.js-clear-selcted-shops', $productStocksFilter);
		$warehouse = $('#products .warehouse');
		$stockCity = $("#products .js-stock-city");

		resetStockFilter();
		savedShopsManager.select();
		dataSeoUrl.setEvents('.js-filter-item');
	}

	function showMore(box) {
		box.addClass('show-more');
	}

	function hideMore(box) {
		box.removeClass('show-more');
	}

	function redirect(url, activeFilter, force) {
		if (url.indexOf('.bhtml') > -1) {
			if ((!front.katalog || force)) {
				ajaxPage.load({
					url: url,
					showLoading: false,
				scrollTop: false,
					beforeLoad: function() {
						$self.addClass('filter-clicked');
						html.add('pageAjax-enabled');
						html.add('pageAjax-load');
						if (activeFilter && activeFilter.length) {
							activeFilter.addClass('filter-redirect-active');
						}
					},
					success: function() {
						checkFilterShowed();
						html.remove('pageAjax-load');
					}
				});
			}else{
				ajaxPage.load({
					url: url
				});
			}
		}
	}


	//dla katalogu

	function setWcFilters() {
		function goUrl(obj) {
			var urlParams=[];
			var url = getNodeName();
			url += getFilterParams(obj);
			url += getOrderParam(obj);
			url += getPriceParam();
			url += ".bhtml";
			if (config.keyword !== '') {
				urlParams.push('keyword=' + encodeURIComponent(config.keyword));
			}
			if (config.promotion !== '') {
				urlParams.push('promocja=' + config.promotion);
			}
			if (urlParams.length) {
				url+='?'+urlParams.join("&");
			}
			ajaxPage.load({
				url: url
			});
		}
		function getPriceParam(){
			var resultUrl='';
			var from=parseInt($priceFrom.val()/1);
			var to=parseInt($priceTo.val()/1);
			if(from>0 || to>0){
				if(from>0 && to>0 && to<from){
					resultUrl+=',od'+to+'do'+from;
				}else{
					resultUrl+=',';
					if(from>0){
						resultUrl+='od'+from;
					}
					if(to>0){
						resultUrl+='do'+to;
					}
				}
			}
			return resultUrl;
		}
		function getOrderParam() {
			var order = '';
			if (config.sortType !== '0') {
				order += ',d' + config.sortType;
			}
			return order;
		}

		function getFilterParams(obj) {
			var tmp = "";
			$.each(obj, function(group, arr) {
				if (arr.length > 0) {
					tmp += ',';
					tmp += group;
					tmp += arr.join(':');
				}
			});

			return tmp;
		}

		function getNodeName() {
			return (config.pageUrl !== '/' ? config.pageUrl : "/search");
		}

		//$categoryFilter.attr("checked", false);
		var filterArray = [];
		var obj = {};

		$filters.each(function(i, val) {
			filterName = $(this).attr("data-name");
			obj[filterName] = [];
			$(".filter-typ-checkbox:not('.filter-hasSubOptions') input:checked", this).each(function() {
				obj[filterName].push($(this).val());
			});
		});

		goUrl(obj);
	}




	//koniec dla katalogu
	function priceFilterUrl(priceFrom, priceTo){
		priceFrom = priceFrom || $priceFrom;
		priceTo = priceTo || $priceTo;
		var url='';
		var from=parseInt(priceFrom.val()/1);
		var to=parseInt(priceTo.val()/1);
		if (from > 0 || to > 0) {
			if (from > 0 && to > 0 && to < from) {
				url += ',od' + to + 'do' + from;
			} else {
				url += ',';
				if (from > 0) {
					url += 'od' + from;
				}
				if (to > 0) {
					url += 'do' + to;
				}
			}
		}
		return url;
	}

	function priceFilter() {
		var url = config.priceUrl.split('.bhtml');
		var priceUrl = priceFilterUrl();
		if (priceUrl !== "") {
			url[0] += priceUrl;
		} else {
			modal.alert("Podana cena jest nieprawidłowa");
		}
		redirect(url.join('.bhtml'), $('.filter-price'), true);
	}

	function changeSort() {
		var url = $.trim($('#sort-' + $sort.val()).html());
		url = url.replace(/\*/g, "/").replace(/%2E/g, ".");
		redirect(url, $('#filter-sort'), true);
	}

	function getGlobalFilters() {
		return (localStorage.getItem('showed-filters') || '').split('|');
	}

	function getLocalFilters() {
		if(!cookie.isEmpty('showed-filters')) {      //'cookie' do usuniecie po 20 dniach
			localStorage.setItem('showed-local-filters', cookie.get('showed-filters'), 20);
			cookie.remove('showed-filters');
		}
		return (localStorage.getItem('showed-local-filters') || '').split('|');
	}

	function setGlobalFilters(results) {
		localStorage.setItem('showed-filters', results.join('|'));
	}

	function setLocalFilters(results) {
		localStorage.setItem('showed-local-filters', results.join('|'), 20);
	}

	function saveFilter(name, show, group) {
		var filters = [];
		var results = [];
		var filterName = '';
		if (group) {
			filters = getGlobalFilters();
			filterName = group;
		} else {
			filters = getLocalFilters();
			filterName = name;
		}
		if (filterName) {
			$(filters).each(function(i, n) {
				if (n) {
					var element = n.split(':');
					if (element[0] !== filterName) {
						results.push(n);
					}
				}
			});
			results.push(filterName + ":" + show);
			if (group) {
				setGlobalFilters(results);
			} else {
				setLocalFilters(results);
			}
		}
	}
	function checkStock(shopId){
			var $shop = $('#products .product-stocks-box .js-stocks-shop-'+shopId);
			var $input = $productStocksFilterItem.filter('.js-filter-stocks-shop-'+shopId).find('input');
			$stockCity = $("#products .js-stock-city");

			if($input.is(':checked')){
				$shop.removeClass('visible');
				$input.prop('checked',false);
				//pokaz dostepnosc w magazynach w momencie gdy zaden z inputow nie jest juz zaznaczony
				if( $productStocksFilterItem.find('input:checkbox:checked').length === 0 ){
					$stockCity.removeClass('hidden');
					$clearSelectedShops.removeClass("visible");
				}
			}
			else {
				$shop.addClass('visible');
				$input.prop('checked',true);
				$stockCity.addClass('hidden');
				$clearSelectedShops.addClass("visible");
			}
			updateRememberedShops();
		}

		function updateRememberedShops () {
			//add to localStorage - keep in listing until logout
			var rememberShops = [];

			$productStocksFilter.find("input:checked").each(function() {
				rememberShops.push($(this).val() );
			});
			localStorage.setItem('rememberShops', JSON.stringify(rememberShops));
		}

		function resetStockFilter(){
			$productStocksFilter.find('input:checked').prop('checked',false);
		}
	function checkFilterShowed(){
		var filters=[];
		$filters.each(function(i,filter){
			var $filter=$(filter);
			var name=$filter.data('group') || $filter.data('name');
			var expanded=String($filter.data('expanded'));
			if(name){
				filters[name]=expanded;
			}
		});
		var userFilters = getLocalFilters().concat(getGlobalFilters());
		$(userFilters).each(function(i, n) {
			if (n !== "") {
				var filter = n.split(':');
				var name = filter[0];
				var expanded = String(filter[1]);
				if (name) {
					filters[name] = expanded;
				}
			}
		});
		for (var name in filters) {
			var expanded = filters[name];
			var $filter = $filters.filter(filterData);
			if (expanded === "true") {
				$filter.addClass('filter-expanded');
			} else {
				$filter.removeClass('filter-expanded');
			}
		}

		function filterData() {
			return $(this).data("name") === name || $(this).data("group") === name;
		}
	}

	function toggle($item) {
		$item.toggleClass('filter-expanded');
		saveFilter($item.data('name'), $item.hasClass('filter-expanded'), $item.data('group'));
		lazyLoad.check();
	}
	var priceParams = {
		timeout: false,
		input: false,
		value: 0
	};

	function priceTimeout(input, event) {
		function clear() {
			clearTimeout(priceParams.timeout);
		}

		function set(time) {
			priceParams.timeout = setTimeout(function() {
				if (input.val() > 10) {
					priceFilter();
				}
			}, time);
			value();
		}

		function value() {
			priceParams.input = input.attr('id');
			priceParams.value = input.val();
		}

		if (event == 'blur') {
			if (input.val() != priceParams.value && input.val() > 0) {
				set(100);
			}
		} else if (event == 'focus') {
			clear();
			value();
		} else {
			clear();
			set(2000);
		}
	}

	function changeVisibleSubValues($element) {
		$element.closest('.filter-item').toggleClass('filter-show').nextUntil('.filter-hasSubOptions', '.filter-isSubOption').toggleClass('filter-show');
	}

	function clickUA($element, eventLabel) {
		var filterName = $.trim($element.closest('.filter').find('.filter-name').text());
		var filterPage = $.trim(config.title);
		UA.interaction('Listing - Filtrowanie', filterName + ' - ' + filterPage, $.trim(eventLabel));
	}

	function clickFilter($input, $filter) {
		if (front.katalog) {
			var isChecked = $input.is(':checked');
			$input.prop('checked', !isChecked);
			var $item=$input.closest('.filter-item');
			if($item.hasClass('filter-active filter-hasSubOptions')){
				$item.nextUntil('.filter-hasSubOptions','.filter-isSubOption').find('.js-filter-checkbox').prop('checked', !isChecked);
			}
			$filter.addClass('filter-clicked');
		}
	}

	function changeStatus( $filterItem ) {
		if(front.katalog){
			clickFilter($filterItem.find(":checkbox"), $filterItem.closest('.filter'));
		}else{
			redirect($filterItem.find('a:first').attr('href'), $filterItem.closest('.filter'));
		}
	}

	function setEvents() {
		$productStocksFilterCitySelect.on('change', function() {
			redirect(dataSeoUrl.replace($(this).val()), $productStocksFilter);
		});
		$buttonsShow.click(function() {
			showMore($(this).closest('.filter'));
			clickUA($(this), $.trim($(this).text()));
		});
		$subValuesIco.click(function() {
			changeVisibleSubValues($(this));
		});
		$buttonsHide.click(function() {
			hideMore($(this).closest('.filter'));
		});
		$priceFrom.keyfilter(/[\d]/).keyup(function(event) {
			if (event.keyCode == 13) priceFilter();
		});
		$priceTo.keyfilter(/[\d]/).keyup(function(event) {
			if (event.keyCode == 13) priceFilter();
		});
		$priceButton.click(function() {
			priceFilter();
		});

		checkFilterShowed();
		$priceFrom.bind('keyup blur focus', function(event) {
			priceTimeout($priceFrom, event.type);
		});
		$priceTo.bind('keyup blur focus', function(event) {
			priceTimeout($priceTo, event.type);
		});
		$productStocksFilterItem.click(function(event) {
			checkStock($(this).find('input').val());
		});
		$name.click(function(event) {
			if ($(event.target).hasClass('js-dictionary')) {
				return;
			} else {
				toggle($(this).closest('.filter'));
			}
		});
		$('.js-set-filters', $self).click(function() {
			setWcFilters();
		});
		$item.click(function(event) {
			if ($(event.target).hasClass('js-dictionary') || $(event.target).hasClass('js-filter-show-subOptions')) {
				return;
			} else {
				changeStatus( $(this) );
				clickUA($(this), $(this).find('.link').text());
			}
		});

		$links.click(function(event) {
			if(front.katalog){
				clickFilter($(this).parent().find(":checkbox"), $(this).closest('.filter'));
			}else{
				redirect($(this).attr('href'), $(this).closest('.filter'));
			}
			clickUA($(this), $(this).text());
			return false;
		});
		$checkboxes.click(function(event) {
			return false;
		});
		$('.js-filter-clear').click(function(event) {
			redirect($(this).attr('href'), $(this).closest('.filter'));
			return false;
		});
		$('.js-filter-clear-all').click(function(event) {
			redirect($(this).attr('href'), $('#filter-selected'));
			return false;
		});
		$('.js-selected-filter-clear').click(function(event) {
			redirect($(this).attr('href'), $('#filter-selected'));
			return false;
		});
		$clearSelectedShops.click(function(event){
			$productStocksFilterItem.find("input").prop( "checked", false );
			updateRememberedShops();
			$stockCity.removeClass('hidden');
			var $shop = $('#products .product-stocks-box .js-stocks-shop');
			$shop.removeClass('visible');
			$(this).removeClass("visible"); // ukryj przycisk wyczysc
		});

		$sort.change(function() {
			changeSort();
		});

		$('.dictionary-icon', $self).click(function() {
			if (UA && UA.interaction) {
				UA.interaction('Listing - Filtrowanie', 'Słownik', 'Cecha - ' + $(this).attr('title')+' - '+config.category);
			}
		});
		$('.filter-item .dictionary-icon', $self).click(function() {
			UA.interaction('Listing - Filtrowanie', 'Słownik', 'Wartość - ' + $(this).attr('title')+' - '+config.category);
		});
		lazyLoad.check();
	}

	function init() {
		setElements();
		setEvents();
	}
	return {
		init:init,
		changeSort: changeSort,
		priceFilter: priceFilter,
		changeStatus: changeStatus,
		// setLinks: setLinks
	};
});
