app.service('searchBox',function(search, localStorage, front, autocomplete, autocompleteInfo, pageConfig, loading, modal) {
	var $self, $input, $searchBtn, $searchCriteria;

	function setElements(){
		$self=$('#search-box');
		if($self.length){
			$searchBtn = $('#search-button', $self);
			$searchCriteria = $('#search-criteria', $self);
			$input = $('#keyword', $self);
		}
	}
	function setKeyword(value) {
		if($input.val()!==value) {
			$input.val(value);
		}
	}
	function setEvents(){
		$searchBtn.click(function(e) {
			autocompleteInfo.logData({type:'button'});
			search.search($input.val());
		});

		if(front.katalog){
			$searchCriteria.val(pageConfig.getData('wc').productsCriteria).change();

			$searchCriteria.on('change',function(){
				changeProductCriteria($(this).val());
			});
		}
	}
	function getKeyword(){
		var value=$input.val() || localStorage.getItem('lastKeyword');
		localStorage.removeItem('lastKeyword');
		setKeyword(value);
		return value;
	}
	function saveKeyword(value){
		localStorage.setItem('lastKeyword',value);
	}

	function changeProductCriteria(value) {
		if ($.trim($input.val()) == '') {
			const data = {
				productsCriteria: value
			};
			loading.start();
			$.ajax({
				url: '/wc-change-products-criteria.ltr',
				type: 'POST',
				data: data,
				success: function () {
					loading.reload();
				},
				error: function () {
					loading.stop();
					modal.alert('Wystąpił błąd, strona zostanie przeładowana', function () {
						loading.reload();
					});
				}
			})
		}
	}

	function initOff(){
		$input.on("keydown", function(e){
			if(e.keyCode == 13) {
				autocompleteInfo.logData({type:'enter'});
				search.search($input.val());
			}
		});
	}
	function init() {
		setElements();
		if($self.length){
			if (front.getParam('autocomplete.enabled') === true) {
				setEvents();
				autocomplete.init();
			} else  {
				initOff();
			}
		}
	}
	return {
		init:init,
		setKeyword:setKeyword,
		saveKeyword: saveKeyword,
		getKeyword:getKeyword
	};
});
