/*
	Moduł wyświetlający warstwę z dostępnością produktu w sklepie
 */
app.service("modalShops", function(loading, console, modal, userPreferences, selectCity, callback, front, log) {

	var $self, product;

	function setElements() {
		$self = $('#shops-modal');
	}

	function openSuccess() {
		setElements();
		selectCity.init();
	}

	function open(html) {
		modal.generate({
			id: 'shops-modal',
			content: html,
			cssClass: 'shops-modal',
			callbackOpen: function () {
				openSuccess();
			}
		});
	}

	function show(html) {
		if (modal.isExist('shops-modal') && modal.isOpen('shops-modal')) {
			$('.shops-box', $self).replaceWith(html);
			openSuccess();
		} else {
			open(html);
		}
	}

	function load(ajaxData) {
		return $.ajax({
			url: '/shops-with-product.ltr',
			data: ajaxData,
			beforeSend: function () {
				loading.start();
			},
			success: function (html) {
				show(html);
			},
			error: function (response) {
				console.log('load delivery info ERROR');
			},
			complete: function () {
				loading.stop();
			}
		});
	}

	function showModal(productId, params) {

		var data = $.extend({
			product_id: productId
		}, params);

		load(data);
	}
	function logEvent(productPLU){
		UA.doubleClick(productPLU, 'dc-prod-depositories-lst');
		log.event({
			id: 'znajdz_sklep_z_produktem',
			f03: 'list',
			f04: 'klik-w-link',
			f05: productPLU,
			f06: (new Date()).getTime()
		});
	}

	function init(){

		$('body').on('click', '.js-depositories', function(){
			UA.pageInteraction('Dostepność produktu', 'Obejrzyj w sklepie');
			product = $(this).data('product');
			showModal(product, {
				calledFrom: 'depository'
			});
			logEvent($(this).data('plu'));
			log.recommendationsForAWS({
				productPlu: $(this).data('plu'),
				eventCategory: 'Dostepność produktu',
				eventAction: 'Obejrzyj w sklepie'
			});
		});

		$('body').on('click', '.js-shop-delivery', function(){
			UA.pageInteraction('CTA', 'Sprawdź dostępność w sklepach');
			product = $(this).data('product');
			showModal(product, {
				calledFrom: 'delivery'
			});
			logEvent($(this).data('plu'));
		});
		$('body').on('click', '.js-shop-delivery-only-available', function(){
			UA.pageInteraction('CTA', $.trim($(this).text()));
			product = $(this).data('product');
			showModal(product, {
				calledFrom: 'delivery',
				onlyAvailableToOrder:true
			});
		});
	}

	return {
		init: init,
		showModal: showModal,
		logEvent
	};
});
