app.service('nearestShops', function(loading, modal) {

	function load(pluCode){
		loading.start();
		$.ajax({
			url:'/nearestShopStocks.ltr',
			data:{
				plu:pluCode,
				nearestShopCodes:[
					'ZY','46','45','24','A9','BX'
				]
			},
			complete:function(){
				loading.stop();
			},
			success:function(html){
				loadSuccess(pluCode, html);
			}
		});
	}
	function loadSuccess(pluCode, html){
		modal.generate({
			id:'nearest-shop-modal',
			content: html
		});
	}
	function setEvents() {
		$('body').on('click', '.js-nearest-shop', function(){
			load($(this).data('product'));
		});
	}

//	function nearestShop(pluCode){

		// alert('do zrobienia '+pluCode);
		//
		// var data = stocksUpdateData(pluCode, true);
		// var nearestStocks;
		//
		// if (data.result.error == 'true'){
		//
		// 	fancyAlert('Próba pobrania danych nie powiodła się.');
		// }
		// else{
		//
		// 	updateLocalAndM1Stocks(data.result.localQuantity, data.result.m1Quantity);
		// 	nearestStocks = nearestShopStocks(pluCode, data.result.nearestShopCodes);
		//
		// 	$.fancybox(' <div class="w550">'
		// 			+'<div class="f14 bold tcenter">'
		// 			+nearestStocks
		// 		+'</div>'
		// 		+'<div class="clear"></div>'
		// 		+'<div class="buttonA h30 mT10">'
		// 			+'<a href="javascript:void(0)" class="ok" onclick="$.fancybox.close();"></a>'
		// 			+'<div class="clear"></div>'
		// 		+'</div>'
		// 	+'</div>',{
		// 		autoDimensions:true,
		// 		onComplete:function(){
		// 			$.fancybox.resize();
		// 		}
		// 	});
		// }
//	}
	function setElements() {


	}

	function init() {
		setElements();
		setEvents();
	}
	return {
		init: init
	};
});
