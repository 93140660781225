/**
 * Moduł obsługujący głowne menu
 * @type {module}
 */
app.service('menu',function(html, sticky, front, lazyLoad) {
	var isInit=false;
	var $self, $items, $staticItems, $subitems, $submenu, $header;

	var menuTimer=null;
	var headerTimer=null;
	var activeEl=null;
	var defaultActiveEl=null;
	var mouseLocs = [];
	var mouseLocsTracked = 3;
	var menuDelay = 200;
	var unavailableTime = 100;

	function hideMenuWithDelay(){
		mouseleaveMenu(false);
	}

	// cache elementow DOM
	function setElements() {
		$self = $('#main-menu');
		$menu = $('#main-menu > ul:first-child');
		$items = $menu.children('li:not(.static-link)');
		$staticItems = $menu.children('li.static-link');
		$submenu = $('#main-menu > ul:last-child');
		$subitems = $submenu.children('li');
		$header = $("#header");
	}

	function reset() {
		$items.removeClass('active');
		$subitems.removeClass('active');
	}

	function activate(menuItem) {
		var index = $items.index($(menuItem));
		if (index == activeEl) {
			 return;
		}
		html.remove('menu-close-button menu-close-cursor-above');
		html.add('menu-open');
		sticky.rebuild('mainMenu');
		reset();
		$(menuItem).addClass('active');

		var subitem = $subitems.eq(index);
		subitem.addClass('active');
		$submenu.addClass('active');
		$self.addClass('expanded');
		activeEl = index;
		lazyLoad.check();
	}
	function unavailableRun(unActive) {
		setTimeout(function(){
			unActive.removeClass("unavailable");
		},unavailableTime);
	}
	function scrollTop(){
		mouseleaveMenu();
		$('body,html').animate({scrollTop: 0}, 500);
	}

	/**
	* Na potrzeby aktualizaji jQuery do wersji 3.1.1: metoda $.unbind() były zastąpiona metodamą $.off()
	*/
	function mouseleaveMenu(e) {
		e = e || false;
		$self.off('mousemove');
		var menuClose = false;
		clearMenuTimer(menuTimer);
		resetMouseLocs();

		if(activeEl !== null) {
			if (!e) {
				html.add('menu-close-button');
				menuClose = true;
			} else {
				html.add('menu-close-cursor-above');
				menuClose = true;
			}

			if (menuClose) {
				html.remove('menu-open');
				sticky.rebuild('mainMenu');
				$submenu.removeClass('active');
				$items.removeClass('active');
				$subitems.removeClass('active');
				$self.removeClass('expanded');
				activeEl = null;
			}
		}
	}

	 function activationDelay() {

		var loc = mouseLocs[mouseLocs.length - 1];

		if (!loc) {
			$self.mousemove(mousemoveOverMenu);
			return menuDelay;
		}

		return 0;
	}

	function mousemoveOverMenu(e) {
		 mouseLocs.push({x: e.pageX, y: e.pageY});

		 if (mouseLocs.length > mouseLocsTracked) {
			 mouseLocs.shift();
		 }
	 }

	function tryActivate(menuItem,force) {
		var delay = force ? 0 : activationDelay();

		if (delay) {
			menuTimer = setTimeout(function() {
				tryActivate(menuItem,false);
			}, delay);
		} else {
			activate(menuItem);
		}
	}

	function mouseenterItem(menuItem,force) {
		 force = force || false;
		 clearMenuTimer(menuTimer);
		 clearMenuTimer(headerTimer);

		 resetMouseLocs();
		 if (force && $items.index($(menuItem)) == activeEl) {
			 mouseleaveMenu();
		 } else {
			 tryActivate(menuItem,force);
		 }

	 }

	function clearMenuTimer(menuTimer) {
		if (menuTimer) {
			clearTimeout(menuTimer);
		}
	}

	function resetMouseLocs() {
		 mouseLocs = [];
	}

	function setEvents(){
		$items.mouseenter(function(){
			mouseenterItem(this);
		}).click(function(e) {
			e.preventDefault();
			mouseenterItem(this,true);
		});
		$subitems.mouseenter(function(){
			clearMenuTimer(menuTimer);
		});
		$staticItems.mouseover(function (e) {
			mouseleaveMenu(e);
		});
		$self.find('.js-menu-close').on('click', function(e){
			scrollTop();
			e.preventDefault();
		});
		//hack polegajacy na dodaniu dodatkowego zdarzenia obiektu nad menu, powodujacego ponowne wywolanie funkcji z opoznieniem
		$header.mouseenter(function() {
			clearMenuTimer(headerTimer);
			headerTimer = setTimeout(hideMenuWithDelay, 500);
		});
		$self.mouseleave(function(e){
			mouseleaveMenu(e);
		});
	}

	function init() {
		if(!isInit){
			setElements();
			if($self.length){
				setEvents();
			}
		}
		isInit=false;
	}
	return {
		init:init
	};
});
