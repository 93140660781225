app.service('definitionItem', function (validate, modal, localStorage, cookie, captchaService) {

	var $self, $actionsBox, $addDefinitionLink;
	var title, productCode, definitionId;

	function setElements() {
		$actionsBox = $('#dictionary-actions', $self);
		$addDefinitionLink = $('#add-dictionary-definition', $self);
		title = $.trim($('.title', $self).text());
	}

	function setEvents() {
		$('.js-vote-up', $self).click(function() {
			UA.interaction('Słownik terminów - Warstwa', title, "Przydatność +1");
			rate({
				definition: $actionsBox.data('definition-id'),
				useful: true
			});
		});

		$('.js-vote-down', $self).click(function() {
			UA.interaction('Słownik terminów - Warstwa', title, "Przydatność -1");
			rate({
				definition: $actionsBox.data('definition-id'),
				useful: false
			});
		});

		$('.dictionary-content  .js-report-bug', $self).click(function() {
			modal.close('product-dictionary-modal');
		});

		$('.more-definition', $self).click(function() {
			$self.addClass('show-long-description');
			return false;
		});

		$('#dictionary-edit-cancel').on('click', function() {
			$actionsBox.addClass('voted-down');
		});

		$addDefinitionLink.click(function() {
			$actionsBox.addClass('voted-edit');
		});
	}

	function rate(data) {

		$.ajax({
			url: '/definitionUsefulness.ltr',
			cache: false,
			data: data,
			type: 'POST',
			beforeSend: function() {
				$self.addClass('in-progress');
			},
			complete: function() {
				$self.removeClass('in-progress');
				if (data.useful) {
					$actionsBox.addClass('voted-up');
				} else {
					$actionsBox.addClass('voted-edit');
				}
				updateDefinitionVote();
			},
			error: function() {
				modal.alert('Błąd oceniania terminu. Prosimy sprobować poźniej');
			}
		});
	}

	function updateDefinitionVote() {
		var definitionVote = localStorage.getItem('euroDefinitionVote');
		if (localStorage.empty('euroDefinitionVote')) {
			definitionVote = '';
		}
		definitionVote = definitionVote.split("|");
		definitionVote.push(definitionId);
		localStorage.setItem('euroDefinitionVote', definitionVote.join('|'), 100);
	}

	function hideRateFromLocalStorage() {
		var definitionVote = localStorage.getItem('euroDefinitionVote');
		if (definitionVote !== undefined) {
			var voted = definitionVote ? definitionVote.split('|') : '';
			if ($.inArray(String(definitionId), voted) >= 0) {
				$actionsBox.addClass('voted-before');
			}
		}
	}

	function send(form) {
		var data = $(form).serializeArray();
		if(productCode){
			data.push({name:'productCode',value:productCode});
			data.push({name:'definitionId',value:definitionId});
		}
		$.ajax({
			url: '/definition-content-edit.ltr',
			type: 'POST',
			data: data,
			beforeSend: function() {
				$self.addClass('in-progress');
			},
			complete: function() {
				$actionsBox.addClass('voted-down');
				$self.removeClass('in-progress');
			}
		});
	}

	function initEditForm() {
		attachDefinitionCaptcha();
		validate.task('editDefinition', {
			submit: function(form) {
				send(form);
				$addDefinitionLink.hide();
			}
		});
	}

	function attachDefinitionCaptcha() {
		const $captcha = $self.find('#definition-content-captcha');
		if ($captcha.length) {
			captchaService.load($captcha.attr('id'), $captcha.data('place'));
		}
	}

	function init(type, html, definition, product) {
		productCode = product;
		definitionId = definition;
		if (type == 'modal') {
			$self = $('#dictionary-modal');
		} else if (type == 'page') {
			$self = $('#definition-item');
		}
		if (html) {
			$('.dictionary-box', $self).replaceWith(html);
		}
		setElements();
		setEvents();
		initEditForm();
		hideRateFromLocalStorage();
	}

	return {
		init
	};
});
