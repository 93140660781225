/**
 * Moduł otwiera informację o klasie oszczędności produktu
 * w okienku
 */
app.service('energyLabel',function(modal){
	var $self, $moreLink, $topMoreLink;
	var lastModal = false;
	function open(url){
		modal.generate({
			id:'modal-energy-img',
			content:'<em></em><img src="'+url+'" alt=""/>',
			callbackClose: function () { 
				if (lastModal) {
					if(modal.isExist(lastModal)){
						modal.open(lastModal);
					} else {
						let $modal = $('#'+lastModal).remodal();
						$modal.open();
					}
					lastModal = false;
				}
			}
		});
	}
	function setElements(){

	}
	function setEvents(){
		$('body').on('click','.js-energy-label.with-energy-table',function(event){
			let $currentModal = $('.remodal.remodal-is-opened');
			if ($currentModal.length) {
				lastModal = $currentModal.attr('id');
			} else { 
				lastModal = false;
			}
			UA.pageInteraction('Karta energetyczna', 'Emblemat');
			if($(this).attr('href') != '#'){
				open($(this).attr('href'));
			}
			event.preventDefault();
		});
		$('body').on('click','.js-energy-card',function(event){
			UA.pageInteraction('Karta energetyczna', 'Karta produktu');
		});

	}
	function init(){
		setElements();
		setEvents();
	}
	return {
		init:init
	};
});
