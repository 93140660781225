app.service('articleRotator', function (lazyLoad, viewPoint, mainPageInteraction) {

	let carouselInstance;
	let $self;
	let $box

	function init() {
		$box = $('#article-rotator-section');
		$self = $(".js-article-rotator-carousel");
		if (!$self.length) return false;

		viewPoint.set({
			element: $self,
			height: 100,
			callback: () => {
				if (carouselInstance) return false;

				initCarousel();
			}
		});
		initUA();
	}

	function initUA(){

		viewPoint.set({
			element: $box,
			height: 0.6*$box.height(),
			callback: function(){
				mainPageInteraction.set('Sekcja Artykuly i Poradniki - wyswietlenie');
			}
		});

		$box.on('click', '.article-rotator-item',function(){
			var index = $(this).index()+1;
			mainPageInteraction.set('Sekcja Artykuly i Poradniki - klikniecia', 'Rotator|'+index);
		});
		$box.on('click', '.article-rotator-item .photo',function(){
			var index = $(this).closest('.article-rotator-item').index()+1;
			mainPageInteraction.set('Sekcja Artykuly i Poradniki - klikniecia', 'Rotator|Obraz|'+index);
		});
		$box.on('click', '.article-rotator-item .article-heading',function(){
			var index = $(this).closest('.article-rotator-item').index()+1;
			mainPageInteraction.set('Sekcja Artykuly i Poradniki - klikniecia', 'Rotator|Tytul|'+index);
		});
		$box.on('click', '.article-rotator-item .author-box',function(){
			var name = $(this).find('.author-name').text().trim();
			mainPageInteraction.set('Sekcja Artykuly i Poradniki - klikniecia', 'Rotator|Autor|'+name);
		});
		$box.on('click', '.article-miniature-box',function(){
			var index = $(this).index()+1;
			mainPageInteraction.set('Sekcja Artykuly i Poradniki - klikniecia', 'Miniatura|'+index);
		});
		$box.on('click', '.article-miniature-box .photo',function(){
			var index = $(this).closest('.article-miniature-box').index()+1;
			mainPageInteraction.set('Sekcja Artykuly i Poradniki - klikniecia', 'Miniatura|Obraz|'+index);
		});
		$box.on('click', '.article-miniature-box .article-heading',function(){
			var index = $(this).closest('.article-miniature-box').index()+1;
			mainPageInteraction.set('Sekcja Artykuly i Poradniki - klikniecia', 'Miniatura|Tytul|'+index);
		});
		$box.on('click', '.article-miniature-box .author-box',function(){
			var name = $(this).find('.author-name').text().trim();
			mainPageInteraction.set('Sekcja Artykuly i Poradniki - klikniecia', 'Miniatura|Autor|'+name);
		});
		$box.on('click', '.js-carousel-next',function(){
			mainPageInteraction.set('Sekcja Artykuly i Poradniki - klikniecia', 'Strzalka|Nastepny');
		});
		$box.on('click', '.js-carousel-prev',function(){
			mainPageInteraction.set('Sekcja Artykuly i Poradniki - klikniecia', 'Strzalka|Poprzedni');
		});
		$box.on('click', '.section-header-link a',function(){
			mainPageInteraction.set('Sekcja Artykuly i Poradniki - klikniecia', 'Rotator - Zobacz wszystkie');
		});
	}

	function initCarousel() {

		carouselInstance = new PreviewGallery({
			selector: $self,
			autoPlay: true,
			swipe: true,
			navigation: $('.article-rotator-carousel-nav'),
			onInit() {
				lazyLoad.update();
			},
			onChange() {
				lazyLoad.update();
			},
			onResize() {
				lazyLoad.update();
			}
		});

		carouselInstance.addPagination(".article-rotator-pagination");
	}

	return {init};
});
