/*
	Obsługa modala wyświetlającego video EURO.
 */
app.service('modalVideo', function(modal, YouTubeEvent) {

	function openYouTube(src, product, placement){
		if (src.length > 10 ) {
			YouTubeEvent.preload();
			UA.pageInteraction('Podgląd YOUTUBE','PLU: '+product);
			modal.generate({
				id:'modal-youtube',
				modifier: 'remodal-youtube',
				content: `<div class="video-box"><iframe id="iframe-youtube" allowfullscreen="true" src="${src}"></iframe></div>`,
				callbackClose:function(){
					modal.destroy('modal-youtube');
				},
				callbackOpen: function(){
					YouTubeEvent.init("iframe-youtube", product, placement);
				}
			});
		} else {
			modal.alert('Błąd generowania podglądu youtube. Prosimy spróbować później');
		}
	}

	function setElements() {

	}

	function setEvents() {
		$('body').on('click', '.js-video-preview', function(event) {
			openYouTube($(this).data('src'), $(this).data('product'), 'wlepka');
		});

		$('body').on('click', '.js-description-video', function(event) {
			openYouTube($(this).find('iframe').attr('src'), $(this).data('product'), 'opis');
		});

	}

	function init() {
		setElements();
		setEvents();
	}
	return {
		init:init
	};
});
