app.service('lowestPrice', (productCardTest, cartManager, modal, front) => {
	const LOWEST_PRICE_SHOW_CLASS = 'lowest-price-show';
	let modalInfo;

	function toggle() {
		if (productCardTest.enabled) {
			$('.lowest-price').toggleClass(LOWEST_PRICE_SHOW_CLASS);
			return;
		}

		const $productSales =
			front.page() == 'product-card'
				? $('.product-sales') // na KP są dwa bloczki
				: $(this).closest('.product-sales');

		$productSales.toggleClass(LOWEST_PRICE_SHOW_CLASS);

		if ($productSales.hasClass(LOWEST_PRICE_SHOW_CLASS)) {
			UA.pageInteraction('GNC', 'Sprawdź ceny');
		}
	}

	function addToCart() {
		cartManager.addProduct({
			productId: $(this).data('product-id') || undefined,
			voucherCode: $(this).data('voucher-code') || undefined,
			productPLU: $(this).data('product-plu') || undefined
		});
	}

	function getMessage(productPlu) {
		return $.ajax({
			url: '/competitorPrice-message-alert.ltr',
			data: { code: productPlu},
			error:function(){
				modal.alert('Błąd wysłania żądania');
			}
		});
	}

	function showModal(content) {
		modalInfo = modal.generate({
			id: 'report-difference',
			content,
			callbackClose: () => modal.destroy()
		});
	}

	function showMessage() {
		const productPlu = $(this).data('product-plu');

		getMessage(productPlu).then(showModal);

		UA.pageInteraction('GNC', 'Zgłoś różnicę');
	}

	function setEvents() {
		$('body')
			.on('click', '.js-lowest-price-toggle', toggle)
			.on('click', '.js-buy-lowest-price', addToCart)
			.on('click', '.js-report-difference', showMessage)
			.on('click', event => {
				const $productSales = $(event.target).closest('.product-sales');

				if (!$productSales.length) {
					$(`.product-sales.${LOWEST_PRICE_SHOW_CLASS}`).removeClass(
						LOWEST_PRICE_SHOW_CLASS
					);
				}

				if (
					front.page() !== 'product-card' &&
					$productSales.hasClass(LOWEST_PRICE_SHOW_CLASS)
				) {
					$(`.product-sales.${LOWEST_PRICE_SHOW_CLASS}`)
						.not($productSales)
						.removeClass(LOWEST_PRICE_SHOW_CLASS);
				}
			});
	}

	return {
		init: setEvents
	};
});
