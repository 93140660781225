app.service('opportunityZone', function (lazyLoad, viewPoint, mainPageInteraction) {

	let carouselInstance;
	let $self;
	let $box

	function init() {
		$box = $('#opportunity-zone-section');
		$self = $(".js-opportunity-zone-carousel");
		if (!$self.length) return false;

		viewPoint.set({
			element: $self,
			height: 100,
			callback: () => {
				if (carouselInstance) return false;

				initCarousel();
			}
		});
		initUA();
	}

	function initUA(){

		viewPoint.set({
			element: $box,
			height: 0.6*$box.height(),
			callback: function(){
				mainPageInteraction.set('Sekcja Strefa okazji - wyswietlenie');
			}
		});

		$box.on('click', '.opportunity-item',function(){
			var index = $(this).index()+1;
			mainPageInteraction.set('Sekcja Strefa Okazji - klikniecia', 'Rotator|'+index);
		});
		$box.on('click', '.opportunity-box',function(){
			var index = $(this).index()+1;
			mainPageInteraction.set('Sekcja Strefa Okazji - klikniecia', 'Box reklamowy|'+index);
		});
		$box.on('click', '.js-carousel-next',function(){
			mainPageInteraction.set('Sekcja Strefa Okazji - klikniecia', 'Strzalka|Nastepny');
		});
		$box.on('click', '.js-carousel-prev',function(){
			mainPageInteraction.set('Sekcja Strefa Okazji - klikniecia', 'Strzalka|Poprzedni');
		});
		$box.on('click', '.section-header-link a',function(){
			mainPageInteraction.set('Sekcja Strefa Okazji - klikniecia', 'Rotator - Wiecej promocji');
		});
	}

	function initCarousel() {

		carouselInstance = new PreviewGallery({
			selector: $self,
			autoPlay: true,
			swipe: true,
			navigation: $('.opportunity-zone-carousel-nav'),
			onInit() {
				lazyLoad.update();
			},
			onChange() {
				lazyLoad.update();
			},
			onResize() {
				lazyLoad.update();
			}
		});

		carouselInstance.addPagination(".opportunity-zone-pagination");
	}

	return {init};
});
