app.service('popularProducts', function (lazyLoad, topSuggestion, viewPoint, mainPageInteraction, logData) {

	let $self;

	function init() {

		if (topSuggestion.hasSuggestion()) return;

		$self = $('#popular-section');

		$self.addClass('is-visible');

		fetchPopularProducts()
			.then(showPopularProducts)
			.fail(onError)
			.always(() => $self.removeClass('loading'));
	}

	function showPopularProducts(html) {

		$self.find('.section-container').html(html);

		initCarousel();
		initUA();
	}

	function initUA(){

		viewPoint.set({
			element: $self,
			height: 0.6*$self.height(),
			callback: function(){
				mainPageInteraction.set('Sekcja Popularne teraz - wyswietlenie');
			}
		});

		$self.on('click','.popular-product-list-item', function() {
			mainPageInteraction.set('Sekcja Popularne teraz - klikniecia', 'Rotator|'+($(this).index()+1)+'|Box produktowy' );
		})
		.on('click', '.popular-products-carousel-nav .js-carousel-next', function(){
			mainPageInteraction.set('Sekcja Popularne teraz - klikniecia', 'Rotator|Strzalki - Nastepny');
		})
		.on('click', '.popular-products-carousel-nav .js-carousel-prev', function(){
			mainPageInteraction.set('Sekcja Popularne teraz - klikniecia', 'Rotator|Strzalki - Poprzedni');
		})
	}

	function initCarousel() {

		new PreviewGallery({
			selector: $self.find('.popular-product-list'),
			perPage: {
				0: 4.5,
				1231: 5.5
			},
			endOffset: 20,
			navigation: $self.find('.popular-products-carousel-nav'),
			swipe: true,
			onInit: function onInit() {
				lazyLoad.update();
			},
			onResize() {
				lazyLoad.update();
			},
			onChange: function onChange() {
				lazyLoad.update();
			}
		});

	}

	function fetchPopularProducts() {
		var url = logData.showRecommendationsFromAWSonMainPage() ? '/user-personalized-popular-products.ltr' : '/popular-products.ltr';
		return $.ajax({
			url: url,
			data: {
				userId: logData.showRecommendationsFromAWSonMainPage() ? logData.customerId() : undefined
			}
		});
	}

	function onError() {

		const errorMessage = "Błąd pobierania popularnych produktów.";
		$self.find('.section-container').html(errorMessage);
		console.error(errorMessage);
	}

	return {init};
});
