app.service('searchHistory',function(localStorage){
	var name='autocompleteSaved';
	var enabled = false;
	var storage= {};

	// podmiana key in object
	function renameObjectKey(obj, oldName, newName) {
		if(obj.hasOwnProperty(newName)) {
			return false;
		}
		obj[newName] = obj[oldName];
		delete obj[oldName];
	}
	function init() {
		if (!localStorage.getItem(name)) {
			localStorage.setItem(name, JSON.stringify( {saved: []} ));
		}
		storage = JSON.parse(localStorage.getItem(name)) || {};
		enabled = true;
	}
	function set(item) {
		if (item && typeof storage.saved !== "undefined" ) {
			storage.saved.unshift(item);
		}
		localStorage.setItem(name, JSON.stringify(storage));
	}
	function get() {
		return storage.saved || [];
	}
	function add(item) {
		var historyObj = get();
		var itemKey = Object.keys(item)[0];
		var unique = true;

		//html+js escape tags
		var escapedKey = $.trim($($.parseHTML(itemKey)).text());
		renameObjectKey(item, itemKey, escapedKey);

		$.each(historyObj, function(i, obj){
			if(Object.keys(obj)[0] == escapedKey) {
				unique = false;
			}
		});

		if (unique) set(item);
	}
	function remove(q) {
		var his = get();

		$.each(his, function(i, obj) {
			var keyword = Object.keys(obj)[0];
			if (keyword === q) {
				his.splice(i, 1);
				set();
				return false;
			}
		});
	}

	function isEnabled() {
		return enabled;
	}
	init();
	return {
		add: add,
		get: get,
		remove: remove,
		enabled: isEnabled
	};
});
