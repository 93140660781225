/**
* Ładowanie modala ze statusem (np. świątecznym)
*/
app.service('holidayPromotion', function( console, modal, loading, front ) {

	function loadSuccess( html ){
		UA.interaction(front.page(), "CTA", "Dostawa Gwarantowana", "", false);
		modal.generate({
			id: 'holiday-promotion-modal',
			content: html
		});
	}

	function load( statusId ){
		loading.start();
		$.ajax({
			url: '/holiday-promotion-message.ltr',
			data:{
				status: statusId
			},
			success: function( html ){
				loadSuccess( html );
			},
			complete: function(){
				loading.stop();
			}
		});
	}

	function setEvents(){
		$('body').on('click', '.js-holiday-promotion', function() {
			load($(this).data('status'));
		});
	}

	function init() {
		setEvents();
	}

	return {
		init: init
	};

});
