app.service('agreementsRemoveModal',function(modal, pageConfig, message) {

	function init($agreementInput){
		var config = pageConfig.getData('account');

		if (config.confirmAgreementRemoveModal && $agreementInput.length) {
			$agreementInput.on('click', function(){
				if(!$(this).is(':checked')) {
					message.get('Confirmation modal - Marketing Agreement Removal').then(function(html){
						modal.generate({
							id:"modal-confirm-marketing-agreement-removal",
							content: html,
							buttons:[
								{
									text:'Chcę nadal korzystać',
									cssClass: 'confirm-marketing-agreement',
									click: function (){
										$agreementInput.prop('checked',true);
										modal.close();
									}
								},{
									text:'Rezygnuję ze zniżek',
									cssClass: 'decline-marketing-agreement',
									click: function (){
										modal.close();
									}
								}
							]
						});
					})
				}
			});

		} 
	}

	return {
		init
	}
});
