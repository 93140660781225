app.service('sticky', function (cookie, html, front, _, productCardTest, broker) {
	var enabled;			//wyłączamy sticky podczas testów atr
	var $self, $window, $body, $header, $mainMenu, $topBar, $promotionBar, $servicesBar, $ears, $topMessage, $productUpselling;
	var headerHeight, mainMenuHeight, productUpsellingHeight = 0, topBarHeight, promotionBarHeight, servicesBarHeight, topMessageHeight;

	var saveHeight=0;						// ostatnia wysokość dla której sprawdzano scrollTop strony
	var breakpointHeight=1000;	// wyliczona punkt zmiany stranu sticky
	var isSticky=false;					// czy aktualnie jest wersja sticky
	var topPadding=0;
	var collapseMenuPoint;

	function scroll(currentScrollPosition = $window.scrollTop()) {
		if(currentScrollPosition!=saveHeight){
			if(currentScrollPosition>breakpointHeight && !isSticky){
				//if ((front.euro || front.katalog) && html.has('menu-open')) return;
				//włączamy sticky
				html.add('sticky');
				//if(front.ole){
				    //$header.trigger('sticky');
				//}
				$body.css('padding-top',topPadding);
				isSticky=true;
				$ears.css('top',$self.height());
			}else if(currentScrollPosition<breakpointHeight && isSticky){
				//wyłączamy sticky
				$body.css('padding-top',0);
				html.remove('sticky');
				isSticky=false;
			}
			saveHeight=currentScrollPosition;
		}

		if (currentScrollPosition > collapseMenuPoint) {
			html.add('sticky-menu-collapsed');
		} else {
			html.remove('sticky-menu-collapsed');
		}
		broker.trigger('PAGE_SCROLL');
		//console.log(currentScrollPosition, saveHeight, collapseMenuPoint, breakpointHeight)
	}

	function brakepoint(){
		breakpointHeight = getBreakpointTriggerHeight();
		topPadding=headerHeight+topBarHeight+servicesBarHeight+promotionBarHeight+topMessageHeight;
		collapseMenuPoint = breakpointHeight + $mainMenu.height();
		return;
	}
	function getBreakpointTriggerHeight() {
		if(productCardTest.enabled) {
			return productUpsellingHeight;
		} else {
			var $helpline = front.katalog ? $('#top-menu') : $('#helpline');
			return $helpline.length ? $helpline.offset().top + $helpline.height() : 40;
		}
	}

	function setElements(){
		$self=$('#top');
		$window=$(window);
		$body = $('body');
		$mainMenu = $('#main-menu');
		$header = $('#header, #order-header');
		$topBar=$('#top-bar');
		$topMessage=$('#top-message');
		$promotionBar=$('#promotion-bar');
		$productUpselling = $('#product-upselling');
		headerHeight = getHeight($header);
		$servicesBar = $('#services-bar');
		$ears=$('#ears');
		servicesBarHeight = getHeight($servicesBar);
		topMessageHeight = getHeight($topMessage);
	}
	function height(){
		return $self.height();
	}
	function rebuild(box, force){
		if(!box || (box==='mainMenu' && force)){
			mainMenuHeight=$mainMenu.height() || 0;
			$window.trigger('product-menu-scroll');
		}else if(box==='mainMenu'){
			setTimeout(function(){
				rebuild(box,true);
			},400);
		}
		if(!box || box==='promotionBar'){
			promotionBarHeight = getHeight($promotionBar);
		}
		if(!box || box==='topBar'){
			topBarHeight = getHeight($topBar);
		}
		if((!box || box==='productUpselling')){
			if($productUpselling.length) {
				productUpsellingHeight = getHeight($productUpselling) + ($productUpselling && $productUpselling.offset().top);
			} else {
				productUpsellingHeight = 0;
			}
		}
		brakepoint();
		if(!box || (box==='mainMenu' && force)){
			$window.trigger('product-menu-scroll');
		}
	}
	function setEvents(){
		_.onScroll(scroll);
	}

	function getHeight($elem) {
		return $elem.height() || 0;
	}

	function init() {
		enabled=cookie.isEmpty('sticky-off') && !(front.katalog && front.group()==='order');

		setElements();
		if(enabled){
			setEvents();
			rebuild();
		}
		if(!cookie.isEmpty('sticky-off')){
			html.add('sticky-off');
		}
	}
	return {
		init : init,
		rebuild : rebuild,
		height: height,
		topPadding: topPadding
	};
});
