app.service('confirmCartRecalculateModal', function (modal){

	function show(confirm, decline = function(){}){
		modal.generate({
			id: 'modal-confirm-cart-recalculate',
			content: '<h2>Zmiana wariantu Ochrony spowoduje Rezygnację z wybranej oferty ratalnej.</h2><p>Czy chcesz zrezygnować z Rat z Ochroną sprzetu?</p>',
			buttons: [{
				text: 'TAK',
				cssClass: 'button-confirm',
				click: function(){
					modal.close();
					confirm();
				}
			},{
				text: 'NIE',
				cssClass: 'button-decline',
				click: function(){
					modal.close();
					decline();
				}
			}]
		});
	}

	return {
		show
	};
});
