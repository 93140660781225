app.service('lastVisitedProducts',function(){
	var $deleteButton;

	function setElements(){
		$self = $('#watched-list');
		$deleteButton = $('.js-delete-from-lastVisited');
	}

	function setEvents(){
		$deleteButton.on('click', function (event) {
			deleteItemFromStash($(this).data('product'));
			event.preventDefault();
		});

		$('.product-attributes .attribute-name a', $self).click(function () {
			UA.interaction('Schowek / Ostatnio Oglądane', 'Słownik', 'Cecha - '+$(this).attr('title'));
		});
		$('.product-attributes .attribute-value a', $self).click(function () {
			UA.interaction('Schowek / Ostatnio Oglądane', 'Słownik', 'Wartość - '+$(this).attr('title'));
		});
	}

	function deleteItemFromStash(id) {
		$.ajax({
			url: "/last-viewed-products-updater.ltr",
			method:'POST',
			data: {
				action:'remove',
				ids:id
			},
			success: function() {
				location.reload();
			}
		});
	}

	function init() {
		setElements();
		setEvents();
	}

	return {
		init:init
	};
});
