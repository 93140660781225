app.service('productInteractionListener', function (productInteractionSaver, productInteractionStorage) {

	function onProductCardView(product) {
		productInteractionSaver.saveVisit(product);
	}

	function onProductAddedToCart(productId) {
		productInteractionSaver.saveEvent('ADDED_TO_CART', {
			date: $.now(),
			productId,
		});
	}

	function onProductAddToCompare(productId) {
		productInteractionSaver.saveEvent('ADDED_TO_COMPARE', {
			date: $.now(),
			productId,
		});
	}

	function onProductAddToClipboard(productId) {
		productInteractionSaver.saveEvent('ADDED_TO_CLIPBOARD', {
			date: $.now(),
			productId
		});
	}

	function onOrderFinalizeView(productIds) {
		productIds.forEach(productId => {
			productInteractionStorage.removeEventsForProduct(productId);
		});
	}

	return {
		onProductCardView,
		onProductAddedToCart,
		onProductAddToCompare,
		onProductAddToClipboard,
		onOrderFinalizeView
	};
});