const PreviewGallery = (function () {
	let defaultConfig = {
		selector: null,
		asNavFor: null,
		perPage: 1,
		startIndex: 0,
		autoPlay: false,
		timeout: 8000,
		endOffset: false,
		navigation: null,
		swipe: false,
		onInit: () => {
		},
		onChange: () => {
		},
		onResize: () => {
		}
	};

	return class {
		constructor(props) {
			this.timer = null;
			this.pagination = null;
			this.config = $.extend(true, {}, defaultConfig, props);
			this.innerElements = this.config.selector.children();
			this.selector = this.config.selector;
			this.selectorWidth = this.selector.width();

			this.resolveSlidesNumber();

			this.currentSlide = Math.max(
				0,
				Math.min(
					this.config.startIndex,
					this.innerElements.length - this.perPage
				)
			);

			this.init();
			this.attachEvents();

			this.next = this.next.bind(this);
			this.prev = this.prev.bind(this);
		}

		init() {
			if (this.config.asNavFor) {
				this.asNavFor = new PreviewGallery({
					selector: this.config.asNavFor,
					startIndex: this.config.startIndex
				});
			}

			if (this.config.navigation) {

				this.config.navigation.find('.next').attr('hidden', this.innerElements.length <= this.perPage);
				this.selector.addClass('first-slide');
			}

			if (this.config.autoPlay) {
				this.autoPlay();

				this.selector.on('mouseenter', () => {
					clearInterval(this.timer);
					this.timer = null;
				});

				this.selector.on('mouseleave', () => {
					if (!this.timer) {
						this.autoPlay();
					}
				});
			}

			this.buildSliderFrame();

			this.config.onInit.call(this);

			this.slideToCurrent();
		}

		autoPlay() {
			this.timer = setInterval(() => {
				if ((this.currentSlide + 1) % this.innerElements.length === 0) {
					this.goTo(0);
				} else {
					this.next();
				}
			}, this.config.timeout);
		}

		getSlide(index = this.currentSlide) {
			return this.sliderFrame.children().eq(index);
		}

		attachEvents() {
			if (this.config.asNavFor) {
				this.sliderFrame.on('click', '> *', e => {
					const position = $(e.currentTarget).index();
					this.asNavFor.goTo(position);
				});
			}

			if (this.config.navigation) {

				this.config.navigation
					.on('click', '.js-carousel-next', () => {
						const scrollBy = Math.floor(this.perPage);
						this.next(scrollBy);
					})
					.on('click', '.js-carousel-prev', () => {
						const scrollBy = Math.floor(this.perPage);
						this.prev(scrollBy);
					});
			}

			if (this.config.swipe) {

				this.selector.swipe({
					swipe: (event, direction) => {
						const scrollBy = Math.floor(this.perPage);

						if (direction === 'left') {
							this.next(scrollBy);
						} else if (direction === 'right') {
							this.prev(scrollBy);
						}
					}
				});
			}

			let windowX = window.outerWidth;
			let windowY = $(window).height();

			$(window).on('resize', () => {
				let height = $(window).height();
				let width = window.outerWidth;
				if (height !== windowY || width !== windowX) {
					this.onWindowResize();
					windowY = height;
					windowX = width;
				}
			});
		}

		buildSliderFrame() {
			if (!this.selectorWidth) return;

			const widthItem = this.selectorWidth / this.perPage;

			this.sliderFrame = $('<div />');
			this.sliderFrame.width(Math.ceil(widthItem * this.innerElements.length));

			const slides = this.innerElements
				.clone()
				.width(`${100 / this.innerElements.length}%`);

			this.sliderFrame.append(slides);
			this.selector.html(this.sliderFrame);

			this.sliderFrame.on('transitionend webkitTransitionEnd oTransitionEnd', () => {
				this.config.onChange.call(this);
			});

			this.slideToCurrent();
		}

		slideToCurrent() {
			let offset =
				parseInt(-1 * this.currentSlide * (this.selectorWidth / this.perPage));

			if (this.config.endOffset) {
				const lastSlide = this.currentSlide === this.innerElements.length - this.perPage;

				if (lastSlide) {
					offset = offset - this.config.endOffset;
				}
			}

			this.sliderFrame.css('transform', `translate3d(${offset}px, 0, 0)`);

			if (this.pagination) {

				this.updatePagination();
			}

			if (this.config.navigation) {

				const firstSlide = this.currentSlide === 0;
				this.config.navigation.find('.prev').attr('hidden', firstSlide);

				const lastSlide = this.currentSlide === this.innerElements.length - this.perPage;
				this.config.navigation.find('.next').attr('hidden', this.innerElements.length <= this.perPage || lastSlide);

				this.selector
					.toggleClass('last-slide', lastSlide)
					.toggleClass('first-slide', firstSlide);
			}
		}

		updatePagination() {
			this.pagination.children()
				.eq(this.currentSlide)
				.addClass('is-active')
				.siblings()
				.removeClass('is-active');
		}

		goTo(index) {
			if (this.innerElements.length <= this.perPage) {
				return;
			}

			const beforeChange = this.currentSlide;
			this.currentSlide = Math.min(
				Math.max(index, 0),
				this.innerElements.length - this.perPage
			);

			if (beforeChange !== this.currentSlide) {
				this.slideToCurrent();
			}
		}

		next(slidesToMove = 1) {
			// early return when there is nothing to slide
			if (this.innerElements.length <= this.perPage) {
				return;
			}

			this.currentSlide = Math.min(
				this.currentSlide + slidesToMove,
				this.innerElements.length - this.perPage
			);

			this.slideToCurrent();
		}

		prev(slidesToMove = 1) {
			// early return when there is nothing to slide
			if (this.innerElements.length <= this.perPage) {
				return;
			}

			this.currentSlide = Math.max(this.currentSlide - slidesToMove, 0);

			this.slideToCurrent();
		}

		addPagination(selector) {
			for (let i = 0; i < this.innerElements.length; i++) {
				const btn = $('<span />');
				this.pagination = $(selector);
				this.pagination = $(selector).append(btn);
			}

			this.pagination.on('click', 'span', (e) => {
				const index = $(e.target).index();
				this.goTo(index);
			});

			this.updatePagination();
		}

		resolveSlidesNumber() {
			if (typeof this.config.perPage === 'number') {
				this.perPage = this.config.perPage;
			} else if (typeof this.config.perPage === 'object') {
				this.perPage = 1;
				for (const viewport in this.config.perPage) {
					if (window.innerWidth >= viewport) {
						this.perPage = this.config.perPage[viewport];
					}
				}
			}
		}

		onWindowResize() {
			this.resolveSlidesNumber();

			if (this.currentSlide + this.perPage > this.innerElements.length) {
				this.currentSlide = this.innerElements.length <= this.perPage ? 0 : this.innerElements.length - this.perPage;
			}

			this.selectorWidth = this.selector.get(0).offsetWidth;

			this.buildSliderFrame();

			this.config.onResize.call(this);
		}
	};
})();
