/**
 * Zbiera i wysyła dane o użytkowniku oraz transakcjach do serwera, w przypadku wykrycia blokady GTM lub GA
 * @module
 */
app.service('GMP', function (cookie, front, sessionStorage, localStorage) {

	function generateUUID() {
		return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
			var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
			return v.toString(16);
		});
	}

	function getProductsData(products) {
		var data = {};
		$(products).each(function (i, product) {
			i++;
			data['pr' + i + 'id'] = product.id;
			data['pr' + i + 'nm'] = product.name;
			data['pr' + i + 'ca'] = product.category;
			data['pr' + i + 'br'] = product.brand;
			data['pr' + i + 'va'] = product.variant;
			data['pr' + i + 'ps'] = String(i);
			data['pr' + i + 'pr'] = product.price;
			data['pr' + i + 'qt'] = product.quantity;
		});
		return data;
	}

	function getPurchaseData(purchase) {
		var data = {
			ti: purchase.actionField.id,
			tr: purchase.actionField.revenue,
			tt: purchase.actionField.tax,
			ts: purchase.actionField.shipping,
			tcc: purchase.actionField.coupon,
			pa: 'purchase'
		};
		var products = getProductsData(purchase.products);
		return $.extend(data, products);
	}

	function send(data) {
		data = prepareData(data);
		$.ajax({
			type: 'POST',
			url: '/ua-to-mp.ltr',
			data: data
		})
			.done(function () {
				console.log('[GMP Info] Data sent successfully.');
			})
			.fail(function () {
				console.log('[GMP Error] Data sending failed.');
			});
	}

	function isNotExcluded(referrer) {
		return ["secure.payu.com", "www.euro.com.pl", "m.euro.com.pl", "euro.com.pl", "cards.tpay.com", "citibankonline.pl", "online.mbank.pl", "ipko.pl", "inteligo.pl", "pekao24.pl", "platnosci.pl", "online.ingbank.pl", "secure.tpay.com", "bankmillennium.pl", "secure.getinbank.pl", "m.mbank.pl", "online.t-mobilebankowe.pl", "online.eurobank.pl", "login.ingbank.pl", "aliorbank.pl", "ebank.db-pbc.pl", "e-bank.credit-agricole.pl", "online-fuzja.aliorbank.pl", "sbe.pbsbank.pl", "secure.ideabank.pl", "centrum24.pl", "bph.pl", "cloud.ideabank.pl", "planet.bgzbnpparibas.pl", "pekaobiznes24.pl", "orangefinanse.com.pl", "mbank.com.pl", "m.online.t-mobilebankowe.pl", "bosbank24.pl", "e-gotowka.pl", "secure.noblebank.pl", "m.bosbank24.pl", "secure.tpay.com", "vpos.polcard.com.pl", "payment.blue.pl", "3dsecure.mbank.pl", "ronline.raiffeisenpolbank.com", "dbpolska.3dsecure.itcard.pl"].indexOf(referrer) === -1;
	}

	function setAttributionData(type, value) {
		const data = { type, value };
		sessionStorage.setItem('GMPSession', JSON.stringify(data));
	}

	function getAttributionData() {
		const GMPSession = sessionStorage.getItem('GMPSession');
		const queryParams = $.parseQuery();

		// ruch pochodzi z medium
		if (queryParams['utm_medium']) {
			const medium = prepareMedium(queryParams['utm_medium']);

			setAttributionData('utm_medium', medium);

			return {
				medium,
				direct: undefined
			};
		}

		// powtórna odsłona - pobierz dane z sesji
		if (GMPSession) {
			const sessionData = JSON.parse(GMPSession);

			switch (sessionData.type) {
				case 'utm_medium':
					return {
						medium: sessionData.value,
						source: undefined
					};
				case 'utm_source':
					return {
						medium: undefined,
						source: sessionData.value
					};
			}
		}

		// brak medium, brak danych w sesji
		const referrer = queryParams['utm_source'] || document.referrer.split('/')[2];

		const source = (referrer && isNotExcluded(referrer)) ?
			referrer.replace("www.", '') : // domena z której pochodzi odsłona (poza wykluczonymi w tablicy excludedList[])
			"(direct) none"; // odsłona bezpośrednia

		setAttributionData('utm_source', source);

		return {
			medium: undefined,
			source
		};
	}

	function prepareData(params) {

		if (!cookie.isEmpty('userUUID')) {        //'cookies' do usuniecia po 365 dniach od wdrozenia
			localStorage.setItem('userUUID', cookie.get('userUUID'), 365);
			cookie.remove('userUUID');
		}

		// prepare UUID
		var UUID = localStorage.getItem('userUUID');

		if (!UUID) {
			UUID = generateUUID();
			localStorage.setItem('userUUID', UUID, 365);
		}

		// prepare userId
		var userId = +cookie.get('userId') || undefined;

		var queryParams = $.parseQuery();

		var attribution = getAttributionData();

		var gclid = queryParams['gclid'];

		var viewport = getViewport();

		var resolution = getResolution();

		var language = getLanguage();

		var documentPath = params.virtualPageURL || document.location.pathname;

		var data = {
			v: "1",
			tid: front.getParam('UA'),
			cid: UUID,
			t: 'pageview',
			dh: document.location.hostname,
			dp: documentPath,
			dt: document.title,
			cd1: params.cd1 || undefined,
			cd2: params.cd2 || undefined,
			cd3: userId,
			cs: attribution.source,
			cm: attribution.medium,
			gclid: gclid,
			sr: resolution,
			vp: viewport,
			ul: language,
			ds: 'fallback',
			cd7: 'AdBlock',
			userIncognito: params.userIncognito,
			userAdblock: params.userAdblock
		};
		if (params.ecommerce) {
			var purchaseData = getPurchaseData(params.ecommerce.purchase);
			$.extend(data, purchaseData);
		}
		return data;
	}

	function prepareMedium(utmMedium) {
		var queryParams = $.parseQuery();
		//jeśli medium pochodzi z poniższych domen, dodaj do niego 'cpc-pc'
		var sources = ['m.ceneo.pl', 'ceneo.pl', 'm.skapiec.pl', 'skapiec.pl', 'm.nokaut.pl', 'nokaut.pl'];
		var matches = sources.filter(function (item) {
			return utmMedium.indexOf(item) > -1;
		});
		if (matches.length > 0) {
			utmMedium += ' cpc-pc';
		}

		//jeśli w URL występuje parametr 'gclid', do medium dodajemy 'cpc'
		if (queryParams['gclid']) {
			utmMedium += ' cpc';
		}

		//jeśli medium pochodzi z google.pl i NIE ma parametru 'gclib' lub pochodzi on z bing, dodaj do medium 'organic'
		var containsGoogle = utmMedium.indexOf('google.pl') > -1 ? true : false;
		var containsParam = queryParams['gclid'] ? true : false;
		var containsBing = utmMedium.indexOf('bing') > -1 ? true : false;
		if (containsGoogle && !containsParam || containsBing) {
			utmMedium += ' organic';
		}
		return utmMedium;
	}

	function getResolution() {
		return window.screen.width + 'x' + window.screen.height;
	}

	function getViewport() {
		var viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
		var viewportHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
		return viewportWidth + 'x' + viewportHeight;
	}

	function getLanguage() {
		return window.navigator.userLanguage || window.navigator.language;
	}

	return {
		send,
		prepareData
	};
});