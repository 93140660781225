app.service('newDeals', function (lazyLoad, viewPoint, mainPageInteraction) {

	let $self;

	function init() {
		$self = $('#new-deals-section');

		if (!$self.find('.preview-gallery').length && !$self.find('.new-deal-boxes').length) {
			return;
		}

		$self.addClass('is-visible');
		if ($self.find('.preview-gallery').length) {
			initCarousel();
		}
		initUA();
	}

	function initUA(){

		viewPoint.set({
			element: $self,
			height: 0.6*$self.height(),
			callback: function(){
				mainPageInteraction.set('Sekcja Wszystko co nowe - wyswietlenie');
			}
		});

		$self.on('click','.new-deal-box', function() {
			mainPageInteraction.set('Sekcja Wszystko co nowe - klikniecia',  'Box reklamowy|'+($(this).index()+1) );
		})
		.on('click','.new-deal-item', function() {
			mainPageInteraction.set('Sekcja Wszystko co nowe - klikniecia',  'Box produktowy|'+($(this).index()+1) );
		})
		.on('click','.js-carousel-next', function() {
			mainPageInteraction.set('Sekcja Wszystko co nowe - klikniecia',  'Strzalka|Nastepny' );
		})
		.on('click','.js-carousel-prev', function() {
			mainPageInteraction.set('Sekcja Wszystko co nowe - klikniecia',  'Strzalka|Poprzedni' );
		})
	}

	function initCarousel() {

		let newDealBoxesExist = $(".new-deal-boxes").length > 0;

		let perPage =  {
			0: newDealBoxesExist ? 2.25 : 4.5,
			1231: newDealBoxesExist ? 2.75 : 5.5
		};
		new PreviewGallery({
			selector: $self.find('.new-deals-products'),
			perPage,
			endOffset: 20,
			navigation: $self.find('.new-deals-products-nav'),
			swipe: true,
			onInit: function onInit() {
				lazyLoad.update();
			},
			onResize: function onChange() {
				lazyLoad.update();
			},
			onChange: function onChange() {
				lazyLoad.update();
			}
		});
	}

	return {init}
});
