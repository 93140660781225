//obsługa bloczka ostatnio wyszukiwane
app.service('lastSearchBox',function(console, searchHistory) {
	var $self, $ul;
	var list=[];
	var max=10;
	function generateList(){
		var his = searchHistory.get();
		$.each(his, function(i, obj) {
			if(Object.keys(obj).length === 0){
				return false;
			}
			var keyword = Object.keys(obj)[0];
			var keywordLower = keyword.toLowerCase();
			if(list.length<max){
				list.push({name: keyword, url: obj[keyword].link});
			}
		});
	}
	function generateBox(){
		if(list.length){
			$(list).each(function(i,n){
				var href=n.url || '/search.bhtml?keyword='+n.name;
				var $li=$('<li/>');
				var $link=$('<a href="#"/>').text(n.name).attr('href',href).wrap('<li></li>').appendTo($li);
				$li.appendTo($ul);
			});
			$self.addClass('showed');
		}
	}
	function build(){
		$ul=$('ul',$self);
		generateList();
		generateBox();
	}

	function init(){
		$self=$('#last-search-box');
		if($self.length){
			//build();		czasowo wyłączamy bloczek do czasu ustalenia EURTM1-1901
		}
	}

	return {
		init:init
	};
});
