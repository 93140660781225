app.service('log', function ($global, front, localStorage, sessionStorage, callback, searchBox, account, GMP, console, loading, logData) {
	var pageInfo = {};
	var GMPdata = {};
	var pageVisitCallback = null;
	var pageVisitInit = false;
	let onEventCallbacks = [];
	let logReady = false;

	function logCustomerVisit() {
		UA.push({ event: 'customerData', visitorId: logData.customerId(), visitId: logData.visitId() });
		logReady = true;
		for (var i = 0; i < onEventCallbacks.length; i++) {
			event(onEventCallbacks[i]);
		}
		onEventCallbacks = [];
	}
	function logCustomerVisitResponse(json) {
		if (json.facebookEventId) {
			UA.push({ event: 'facebookData', eventId: json.facebookEventId });
		}
	}
	function pageVisit() {
		$.ajax({
			url: '/visits-analytics.ltr',
			type: 'POST',
			cache: false,
			data: pageInfo,
			dataType: 'json',
			error: function () {
				logCustomerVisit();
			},
			success: function (json) {
				logCustomerVisit();
				logCustomerVisitResponse(json);
				callback.run('afterPageVisitLog', json);
				sendMarketingParameters(json);
				UA.sendFallbackData(sendFallbackData);
				if (pageVisitCallback) {
					pageVisitCallback();
				}
			}
		});
	}

	function sendFallbackData() {
		GMPdata = $.extend(GMPdata, {
			cd1: logData.customerId(),
			cd2: logData.visitId()
		});
		GMP.send(GMPdata);
		pageVisitInit = true;
	}

	function setFallbackData(params) {
		GMPdata = $.extend(GMPdata, params);
		if (pageVisitInit) {
			GMP.send(GMPdata);
		}
	}

	function sendMarketingParameters(data) {
		$.ajax({
			url: '/marketingParameters.ltr',
			type: 'POST',
			cache: false,
			data: JSON.stringify(data.marketingParameters),
			dataType: 'json',
			contentType: 'application/json',
			success: function (json) {
				callback.run('afterMarketingParametersLog', json);
			}
		});
	}
	function successPageVisit() {
		setUserId(pageInfo.frontUserId);							//globalna, do posprzątania
		pageVisit();
		if (pageInfo.keyword) {				//TODO przenieś poza modół brzez brokera lub triggera
			searchBox.setKeyword(pageInfo.keyword);
		}
	}
	function visitOnLoad(data, callbackFunction) {
		if (callbackFunction) {
			pageVisitCallback = callbackFunction;
		}
		account.onLoad(function () {
			visit(data);
		});
	}
	function visit(data) {
		$.extend(true, pageInfo, {
			front: 'desktop',
			keyword: localStorage.getItem('lastKeyword'),
			referrer: document.referrer,
			currentUrl: encodeURIComponent(document.location.href),
			productId: 0,
			productCode: '',
			productsGroupId: 0,
			nodeName: '',
			pageType: front.page(),
			t_page: 0,								//do ustalenia czy nadal jest potrzebne
			t_done: 0
		});
		$.extend(true, pageInfo, data);
		$.ajax({
			url: '/visitData.ltr',
			dataType: 'json',
			cache: false,
			success: function (json) {
				$.extend(true, pageInfo, json);
				callback.run('afterVisitLog', json);
				successPageVisit();
			}
		});
	}

	function eventOnLoad(data) {
		if (logReady || logData.customerId()) {
			logReady = true;
			event(data);
		} else {
			onEventCallbacks.push(data);
		}
	}
	function event(data, options) {
		if (typeof data == "undefined") {
			return false;
		}
		var params = {
			id: '',
			front: 'desktop',
			f01: logData.customerId(),
			f02: logData.visitId()
		};

		var ajaxOptions = {
			type: 'POST',
			async: true,
			cache: false,
			url: '/shopEvent.ltr',
			data: $.extend(true, params, data)
		};
		$.extend(true, ajaxOptions, options);
		if (ajaxOptions.data.f03) {
			ajaxOptions.data.f03 = $('<div>' + ajaxOptions.data.f03 + '</div>').text()
		}
		if(ajaxOptions.data.id == 'to_cart') {
			//nowe zdarzenie 'to_cart'
			// delete ajaxOptions.data.front;
			ajaxOptions.data.technology = 'SI'; // istniejąca już kolumna w shopeventlogu : EventSource - niech parametr ten przyjmuje analogiczne wartości jak obecnie: HEADLESS/SI/SI_RWD zamiast monolith/headless
			// f01 : USER_ID
			// f02 : VISIT_ID
			// f03 : Skąd dodano produkt
					// product_card - Karta produktu
					// product_list - Listing
					// comparison 	- Dodanie do koszyka z porównania
					// cart_step_0 	- K0 (akcesoria)
					// cart_step_1 	- K1 (ubezpieczenia, akcesoria/towary, usługi)
					// cms 			- Dodanie ze stron promocyjnych
					// kme 			- Dodanie produktów do koszyka z KME – konto moje euro
					// articles 	- Aktykuły
			// f04 : PLU produktu dodanego do koszyka 
			// f05 : Kategoria produktu/towaru, usługi, gwarancji 
					// product 		- produkt
					// service 		- usługa
					// warranty 	- ubezpieczenie
					// subscription 	- subskrypcja
					// gift_card 	- Karta podarunkowa
					// akc 			- akcesorium
			// f06 : PLU produktu , do którego zostalo dodane akcesorium/ubezpieczenie/usługa
			// f07 : Szczegółowe miejsce dodania, placement
					// accessory_card 	- Dodanie produktu akcesoryjnego z karty produktu dla wersji
					// warranty_details - Szczegóły ubezpieczenia
					// warranty_box 	- Box ochrony ubezpieczeniowej
					// services_details - Szczegóły usług
					// product_details 	- Szczegóły produktu na KP
					// others_watched 	- Inni oglądali również
					// recently_watched - Ostatnio oglądane
					// leaf				- Listing, ranking ,cms
					// watched_products - Oglądane produkty
					// services_box 	- Box usług
			// f08 : Szczegółowe miejsce dodania
					// fancybox - Warstwa
					// button - Przycisk
			// f09 : Stan produktu
					// new 	  - Towar nowy
					// outlet - Outlet
			// f10 : Typ płatności
					// installment - Raty
					// cash - Gotówka
					// subscription - Płatność miesięczna
			// f12 : ciasteczka (tak jak w evencie 'new_visit') - do ustalenia formatowanie
			// f23 : breakpoint (desktop, mobile, tablet)
			// f24 : 'SI/TO/EK'
			if (!ajaxOptions.data.f09) {
				ajaxOptions.data.f09 = '';
			}
			ajaxOptions.data.f23 = window.innerWidth >= 1024 ? 'desktop' : 'tablet';
			ajaxOptions.data.f24 = front.katalog ? 'EK' : sessionStorage.getItem('operator-logged')==='true' ? 'TO' : 'SI';

		}

		if (ajaxOptions.data.id == 'cart_step') {
			// nowe zdarzenie 'cart_step'
			// f03 : pełny link
			ajaxOptions.data.f03 = document.location.pathname;
		}

		if(ajaxOptions.data.id == 'cart_step' || ajaxOptions.data.id == 'to_cart') {
			// części wspólne dla cart_step oraz to_cart
			delete ajaxOptions.data.brakepoint;
			ajaxOptions.data.technology = 'SI'; // istniejąca już kolumna w shopeventlogu : EventSource - niech parametr ten przyjmuje analogiczne wartości jak obecnie: HEADLESS/SI/SI_RWD zamiast monolith/headless
			ajaxOptions.data.f23 = window.innerWidth >= 1024 ? 'desktop' : 'tablet';
			ajaxOptions.data.f24 = front.katalog ? 'EK' : sessionStorage.getItem('operator-logged')==='true' ? 'TO' : 'SI';
			ajaxOptions.async = false;
		}

		if (data.id !== '') {
			console.log('shopEvent', JSON.stringify(ajaxOptions.data));
			if (ajaxOptions.async === false || ajaxOptions.data.id == 'to_cart') {
				loading.start();
				ajaxOptions.success = function () {
					loading.stop();
				}
				ajaxOptions.error = function (xhr) {
					loading.stop();
					console.log('Błąd shopEvent', xhr);
				}
				$.ajax(ajaxOptions);
			} else {
				let fData = new FormData();
				for (var key in ajaxOptions.data) {
					if (typeof ajaxOptions.data[key] !== "function") {
						fData.append(key, ajaxOptions.data[key]);
					}
				}
				navigator.sendBeacon(ajaxOptions.url, fData);
			}
		}
	}
	function getEventCategory(location) {
		if (location == 'PRODUCT_CARD' || location == 'ACCESS_CARD') {
			return 'KP';
		} else if (location == 'TO_CART') {
			return 'K0'
		} else if (location == 'PRODUCT_LIST') {
			return 'Listing';
		}

		return location;
	}
	function recommendationsForAWS({
		productPlu,
		eventCategory = '',
		eventAction = '',
		eventLabel = ''
	}) {
		var userId = logData.customerId();
		var visit = logData.visitId();

		if (!front.katalog && userId && visit && productPlu) {
			$.ajax({
				url: '/recommendation-event.ltr',
				type: 'POST',
				data: JSON.stringify({
					userId,
					visitId: visit,
					productPlu,
					eventCategory: getEventCategory(eventCategory),
					eventAction,
					eventLabel,
					"region": navigator.language,
					"operatingSystem": navigator.platform,
					"source": front.getReferrer(),
					"medium": $.parseQuery().utm_medium || ""
				}),
				dataType: 'json',
				contentType: 'application/json'
			});
		}
	}

	$global('logEvent', eventOnLoad);
	$global('setFallbackData', setFallbackData);
	return {
		visit: visitOnLoad,
		event: eventOnLoad,
		recommendationsForAWS
	};
});
