app.service('promotionListingView', function (){
	let $self, $listingItems;

	function setElements(){
		$self = $('#promotion-listing');
		$listingItems = $('.promotion-listing-tab', $self);
	}

	function setEvents(){
		$self.on('click', '.js-promotion-tab-toggle', function(){
			const $parent = $(this).parent().toggleClass('open');
			if($parent.hasClass('open')){
				$.scrollTo($(this));
			}
		}).on('click', '.js-scroll-to-section', function(){
			$.scrollTo($('#products'));
		});
	}

	function init(){
		setElements();
		setEvents();
	}

	return{
		init
	};
});