var lowQuality = false;
var cart = false;
var wwwCatalog=false;
var recommendation = [];
var recommendationType = 'QUATRIC';
var permissionToProcessing = "";
var permissionToAccessForCooperatingEntities = "";
var permissionToElectronicChannel = "";
var loginsCounter="";
var urlWhichEnforcesLogin="";
var ipAddress="";
var instalment=false;
var pageType = "";

// funckje nadpisywana w live chat script
function drawInstalmentOptionDisplayMode(currentMode) {}
function afterCustomerVisitLog(json) {}

function goBack() {
	app.goBack();
}
const passwordInvalid = (function () {
	let $self, $input, $box, $row, $email, $passwords, $password1, $password2, $password3, $password4, $password5, $password6, $password7, $password8;
	let value;
	let enabledValidate = false;
	let errorsList = [];
    function init(input, box, row, email=false) {
		$input = input;
		$box = box;
		$row = row;
		$email = email;
		$passwords = $('input[type="password"][name!="passwordOld"]', $box.closest('form')); //stare haslo niebrane pod uwage
		setElements();
		setEvents();
	}
	function setElements() {
		$self = $input.closest('form').parents();
		$password1 = $box.find('.password-rule-1');
		$password2 = $box.find('.password-rule-2');
		$password3 = $box.find('.password-rule-3');
		$password4 = $box.find('.password-rule-4');
		$password5 = $box.find('.password-rule-5');
		$password6 = $box.find('.password-rule-6');
		$password7 = $box.find('.password-rule-7');
		$password8 = $box.find('.password-rule-8');
		$passwordsWithout = $box.find('.password-without');
		$row.addClass('row-register-password');
	}
	function setEvents() {
		$input.on('change keyup paste', changeInput)
		.on('blur', function () {
			if ($input.val() != '') {
				enabledValidate = true;
				changeInput();
			}
		})
		$box.on('click', '.js-password-toogle', function () {
			let newType = $input.attr('type') == 'password' ? 'text' : 'password';
			$passwords.attr('type', newType);
			$row.attr('type', newType);
		});
	}
	
	function changeInput() {
		[$password2, $password3, $password4, $password5, $password7].forEach($element => {
			$element.removeClass('ok error');
		});
		[$password1, $password6, $password8].forEach($element => {
			if(!$element.hasClass('error')) {
				$passwordsWithout.removeClass('show-without');
			}
		});
		errorsList = [];
		$box.removeClass('show-without');
		value = typeof $input.val() === 'string' ? $input.val() : String($input.val());
		password1();
		password2();
		password3();
		password4();
		password5();
		if ($email) {
			password6();
		};
		password7();
		password8();

		if (errorsList.length === 0 && value.length) {
			$row.removeClass('error-box');
		} else if (errorsList.length > 0) {
			$row.addClass('error-box');
		}

		function password1() {
			$password1.removeClass('error');
			if (/[\s]/.test(value)) {
				$passwordsWithout.addClass('show-without');
				$password1.addClass('error');
				errorsList.push('password1');
			}
		}

		function password2() {
			if (/[A-ZŻĄĘĆŹŃŁÓ]/.test(value)) {
				$password2.addClass('ok');
			} else if (enabledValidate) {
				$password2.addClass('error');
				errorsList.push('password2');
			}
		}
		function password3() {
			if (/[a-zżąęźśćńłó]/.test(value)) {
				$password3.addClass('ok');
			} else if (enabledValidate) {
				$password3.addClass('error');
				errorsList.push('password3');
			}
		}
		function password4() {
			if (/[0-9]/.test(value)) {
				$password4.addClass('ok');
			} else if (enabledValidate) {
				$password4.addClass('error');
				errorsList.push('password4');
			}
		}
		function password5() {
			if (/[!"#$%&'()*+,-./:;<=>?@`_{|}~\^\[\]\\]/.test(value)) {
				$password5.addClass('ok');
			} else if (enabledValidate) {
				$password5.addClass('error');
				errorsList.push('password5');
			}
		}
		function password6() {
			$password6.removeClass('error');
			var email = ($email.val() || "").toLowerCase();
			if (email && value.toLowerCase().indexOf(email) != -1) {
				$passwordsWithout.addClass('show-without');
				$password6.addClass('error');
				errorsList.push('password6');
			}
		}
		function password7() {
			if (value.length >= 8) {
				$password7.addClass('ok');
			} else if (enabledValidate) {
				$password7.addClass('error');
				errorsList.push('password7');
			}
		}
		function password8() {
			$password8.removeClass('error');
			if (value.length > 30) {
				$passwordsWithout.addClass('show-without');
				$password8.addClass('error');
				errorsList.push('password8');
			}
		}
	}

	function checkValid() {
		changeInput();
		if (errorsList.length === 0) {
			return true;
		}
		return false;
	}
	function check() {
		enabledValidate = true;
		return checkValid();
	}
	return {
		init: init,
		check: check
	}
})();

const emailInvalid = (function() {
	var emailRulesList, $box, $email, $suggest;
	function init(rules) {
		emailRulesList = rules;
		$box = $('#emailRow');
		$email = $('#email');
		$suggest = $('#email-invalid-text');
		$email.on('change keyup paste blur', function () {
			checkEmail();
		})
		$suggest.click(function () {
			$email.val($(this).text()).change();
		});
		checkEmail();
	}
	function checkEmail() {
		var value = $email.val();
		$box.removeClass('email-invalid');
		if (validateEmail(value)) {
			var result = rules();
			if (result && result.length) {
				$suggest.text(result[0]);
				$box.addClass('email-invalid');
			}
		}
	}
	function rules() {
		var result = [];
		var value = $email.val()
		var email = value.split('@');
		emailRulesList.forEach(function (element) {
			if (email[1] == element.bad) {
				result.push(email[0] + '@' + element.correct);
			}
		});
		return result;
	}
	function check(callback) {
		var result = rules();
		if (result && result.length) {
			app.showModal({
				content: `<h2>Czy Twój e-mail jest poprawny?</h2><h3>${$email.val()}</h3>`,
                id:'modal-email-rules',
                buttons: [
                    {
                        text: 'Tak, poprawny',
                        click: function () {
                            app.closeModal();
                            callback()
                        },
                        cssClass: 'yes'
                    }, {
                        text: 'Nie, zmień',
                        click: function () {
                            app.closeModal();
                            $.scrollTo( $box );
                        },
                        cssClass: 'no'
                    }
                ]
			});
			return false;
		} else {
			callback()
		}
	}
	
	
	return {
		init: init,
		check: check
	}
})();

function addToCartManager(obj){
	app.addProduct(obj);		//przeniesione do serwisu
}
function checkCityForCode(zipCode, cityName) {
	//nadpisywane przez Euro
}
function chooseRequestType(recommendationSource){
	return 'GET';
}

var hideTimer = null;
var loadOption = [];
var hideDelay = 200;
var startDelay = 500;

function showNotify(prodTitle, msg, toCart) {		//co to za funkcja??
	$('#hiddenFancyBody').html(prodTitle+msg);
	$('#hiddenFancyLink').append('<a/>');
	$('#hiddenFancyLink a:last').attr('href', '#hiddenFancyBody').fancybox({autoDimensions:	true}).click();
}

function fancyPrint(ie) {
	if ($.browser.opera) {
		$("a.fancyPrint").attr('href', serializeUrl('print=1')).click(function() {
			window.print();
			return false;
		});
	}else{
		if(isPrint == 'preview'){
			$("a.fancyPrint").click(function(){
				window.print();
			});
		}
		else{
			$("a.fancyPrint").click(function(){
				$.fancybox({
					'href':serializeUrl('print=1')
				},{
					type: 'iframe',
					width: 980,
					height: 480
				});
			});
		}
	}
}

function privacyFancybox(url){
	$.fancybox({
		'width': 980,
		'height': "95%",
		'type': 'iframe',
		'href': url
	});
}

$(document).on('click','.js-privacy-fancybox',function(event){
	var $url = this.getAttribute("href");
	event.preventDefault();
	privacyFancybox($url);
});

function validateEmail(mail) {
	return app.getParam('emailReg').test(mail);
}

function closeToolBox() {
	clearTimeout(toolPgr);
	toolPgr = setTimeout(function() {
		if (!$('#toolPgr').hasClass('hover')) {
			hideToolBox();
		}
	}, 500);
}
function hideToolBox() {
	$('#toolPgr').css('visibility', 'hidden');
	$('#toolPgr').removeClass('hover');
}

var toolPgr = false;
function showToolBox(that, html, width, options) {
	clearTimeout(toolPgr);
	var position=$(that).offset();
	var y=getPageScroll();
	$('#toolPgr').html('<div class="container"><div class="top"></div><div class="top-right-border"></div><div class="bottom"></div><div class="ico"></div></div>');
	$('#toolPgr .top').html(html);
	var option={
		left:0,
		top:-$('#toolPgr').height()-2,
		bottom:false,
		smallClass:false
	};
	$.extend(true, option, options);
	var top = position.top + option.top;
	$('#toolPgr').removeClass('t');
	if(option.smallClass){
		$('#toolPgr').addClass('tooltip-small');
		if($('#toolPgr .top').width()>370)
			$('#toolPgr').addClass('limit-width');
	}else{
		$('#toolPgr').removeClass('tooltip-small limit-width');
	}
	if(y[1]>top || option.bottom>0){
		top=position.top+20;
		if(option.bottom>0)top+=option.bottom;
		$('#toolPgr').addClass('t');
	}
	$('#toolPgr').css({
		top : top,
		left : position.left - width + option.left,
		visibility : 'visible'
	});
	$('#toolPgr .ico').css('left', width + 2);
}

function fancyOk(body, v) {
	var onclick;
	if (v == 1 || v == null) {
		onclick = function() {$.fancybox.close();};
	} else if (v == 2) {
		onclick = function() {
			$('#F_ACTION').val('CREATE_ORDER');
			$('#cartForm').submit();
		};
	}
	fancyOkCallback(body, onclick);
}
function fancyOkCallback(body, onclick) {
	var t = ' <div class="w350">'
				+ '<div class="f14 bold tcenter">'
					+ body
				+ '</div>'
				+ '<div class="clear"></div>'
				+ '<div class="buttonA h30 mT10">'
					+ '<a href="#" class="ok btn btn-primary">OK</a>'
					+ '<div class="clear"></div>'
				+ '</div>'
			+ '</div>';
	var option = {
		autoDimensions : true,
		onComplete : function() {
			$.fancybox.resize();
			$('#fancybox-content .ok').click(function() {
				onclick();
			});

		}
	};
	$.fancybox(t, option);
}

function removeFromCard(prodId, prodTitle) { // pgrzech

}

function setField(ieldId, fieldValue) {
	$('#' + formId).val(fieldValue);
}

function actionSubmit(formId, action) {
	$('#' + formId + ' input[name=F_ACTION]').val(action);
	$('#' + formId).submit();
}

function showHide(tag) {
	var tagi = $("." + tag);
	var f = tagi.length != 0 ? $("." + tag) : $("#" + tag);
	f.each(function() {
		if ($(this).hasClass('hide')) {
			$(this).removeClass('hide').addClass('block');
		} else if ($(this).hasClass('block')) {
			$(this).removeClass('block').addClass('hide');
		}
	});
}

function hideBadWordAlerts() {
	for ( var i = 1; i <= 4; i++) {
		document.getElementById('badWordAlert' + i).style.display = 'none';
	}
}

function get_cookie(cookie_name) {
	var results = document.cookie.match('(^|;) ?' + cookie_name + '=([^;]*)(;|$)');
	if (results)
		return (unescape(results[2]));
	else
		return null;
}

function createJQueryCookie(cookieName, cookieValue, cookieDomain, cookieMaxAge) {

	var date = new Date();
	date.setTime(date.getTime() + cookieMaxAge);
	$.cookie(cookieName, cookieValue, {
		path : '/',
		domain : cookieDomain,
		expires : date
	});
}

function switchToOldVersion(url, cookieName, cookieDomain, cookieMaxAge) {

	UA.pageview('/outgoing/stary-serwis');
	createJQueryCookie(cookieName, 'old', cookieDomain, cookieMaxAge);
	document.location.href = url + document.location.pathname + document.location.search;
}

function addToCardAccessories(id, name) {
	addToCard(id, name, 'ACCESS_CARD');
	$('#acc' + id).attr('checked', false);
}

function logAddToCardSuccessEvent(){
	//nadpisywana przez euro
}

function addToCard(prodId, prodTitle, addedFrom, toCart, refresh) { // pgrzech
	UA.addToCart(prodId);

	var data={
		productId: prodId,
		addedFrom: addedFrom,
		toCart: toCart
	};
	$.ajax({
		url:'/add-product-to-cart.ltr',
		method:'POST',
		async:false,
		cache:false,
		data:data,
		success: function(html){
			$.fancybox.hideActivity();
			var objToLog = {prodId:prodId,prodTitle:prodTitle,addedFrom:addedFrom,toCart:toCart,refresh:refresh};
			logAddToCardSuccessEvent(objToLog);
			if(addedFrom!='ACCESS_CARD' && refresh=='true'){
				app.cartRefresh('');
			}else {
				if($('#cart-box').length){
					app.cartRefresh(prodTitle);
				}else{
					window.location.reload();
				}
			}
		}
	});
	return false;
}
function countNotEmpty(array) {

	result = 0;

	for ( var i = 0; i < array.length; i++) {
		if (array[i] != '') {
			result++;
		}
	}
	return result;

}

function serializeUrl(b) {
	var href = document.location.protocol + '//' + document.location.hostname;
	if (document.location.port != '')
		href += ':' + document.location.port;
	href += document.location.pathname;
	s = document.location.search;
	if (s != '' && b != '')
		s = s + '&' + b;
	if (s == '' && b != '')
		s = s + '?' + b;
	if (document.location.hash != '')
		s += document.location.hash;
	return href + s;
}

function errorBox() {
	var y = $('.errorBox');
	if (y.length > 0)
		y.errorBox();
}

function fancybox() {
	fancyErrorLink('');
	fancyPrint();

	$("a.fancyInfo").fancybox({
		autoDimensions : true
	});
	$("a.fancyInfoI").fancybox({
		autoDimensions : true,
		type : 'iframe'
	});
	$("a.fancy200").fancybox({
		autoDimensions : true
	});
	$("a.fancybox").fancybox({
		autoDimensions : true
	});
	$("a.fancyMail").fancybox({
		autoDimensions : true
	});
}

function autocompleteDelivery(zipCode, cityName, streetName){

	$('#'+zipCode).keyup(function(){
		$('#'+zipCode+'H').val($('#'+zipCode).val());
		if($('#'+zipCode).val().length==6 && $('#'+zipCode).val().indexOf('_')==-1){
			$('#'+cityName).setOptionsOld({
				minChars: 0,
				extraParams:{
					type:'city',
					zipCode: function(){return $('#'+zipCode).val();}
				}
			}).flushCacheOld();
			$('#'+cityName).focus().searchOld();
			$('#'+cityName)[0].click();
			checkCityForCode($('#'+zipCode), $('#'+cityName));
		}else {

			$('#'+cityName).setOptionsOld({
				minChars: 0,
				extraParams:{
					type:'city',
					zipCode: function(){
						var w=$('#'+zipCode).val();
						if($('#'+zipCode).val().indexOf('_')>-1)w='';
						return w;
					}
				}
			}).flushCacheOld();
		}
	});

	$('#'+cityName).focus(function(){
		$(this)[0].click();
	}).autocompleteOld('/citiesWithZipCode.ltr', {
		minChars: 0,
		selectFirst:false,
		dataType: 'text',
		extraParams:{
			type:'city',
			zipCode: function() { return $('#'+zipCode).val(); }
		},
		width:200,
		cacheLength:0,
		scroll:true,
		scrollHeight: 220,
		formatItem: function(data, i, total) {
			if(data){
				return data[0];
			}else{
				$('#'+zipCode)[0].click();
			}
		}
	});
	$('#'+cityName).resultOld(function(event, data, formatted) {
		if(data){
			if(data[1]!=''){
				$('#'+zipCode).val(data[1]);
				$('#'+zipCode+'H').val(data[1])[0].focus();
			}else {
				//$('#'+zipCode).focus();
			}
		}
	});

	if(streetName){
		$('#'+streetName).autocompleteOld('/streetAutocomplete.ltr', {
			minChars: 3,
			selectFirst:false,
			dataType: 'text',
			extraParams:{
				city: function() { return $('#'+cityName).val(); },
				zipCode: function() { return $('#'+zipCode).val(); }
			},
			width:200,
			cacheLength:0,
			scroll:true,
			scrollHeight: 220,
			formatItem: function(data, i, total) {
				return data[0];
			}
		});
	}
}

function replaceLink(){
	$('a').attr('href', 'javascript:void(0)');
}

function checkbox(p){
	var pID = '';
	var checkbox = $(p+' input:checkbox').not(".noSafari"),
		radio = $(p+' input:radio').not(".noSafari");

	$.each(radio, function(){
		if($(this).attr("id")) {
			pID = $(this).attr("id") + "_label";
		}
		if(!$(this).closest('.radio-css').length) {
		    $(this).wrap('<label class="radio-css" id="' + pID +'">').after('<i/>');
		}
	});
	$.each(checkbox, function(){
		if($(this).attr("id")) {
			pID = $(this).attr("id") + "_label";
		}
		if(!$(this).closest('.checkbox-css').length) {
			$(this).wrap('<label class="checkbox-css" id="' + pID +'">').after('<i/>');
		}
	});
}

function readCookie(name) {
	var nameEQ = name + "=";
	var ca = document.cookie.split(';');
	for ( var i = 0; i < ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) == ' ')
			c = c.substring(1, c.length);
		if (c.indexOf(nameEQ) == 0)
			return c.substring(nameEQ.length, c.length);
	}
	return null;
}

function isAnyUserLogged() {
	loadingStart();
	var returnValue = false;
	$.ajax({
		url : '/any-user-logged.ltr',
		dataType : 'json',
		async : false,
		success: function (data) {
			loadingStop();
			if (data.logged) {
				returnValue = true;
			}
		},
		error: function () {
			loadingStop();
		}
	});
	return returnValue;
}

function isFrontUserLogged() {

	var returnValue = false;
	loadingStart();
	$.ajax({
		url : '/front-user-logged.ltr',
		dataType : 'json',
		async : false,
		success: function (data) {
			loadingStop();
			if (data.logged) {
				returnValue = true;
			}
		},
		error: function () {
			loadingStop();
		}
	});
	return returnValue;
}

function ajaxForm(url, target) {
	if (showLoginViewIfLogout()) {
		return false;
	}
	loadingStart();
	$.ajax({
		url : url,
		cache : false,
		success: function (html) {
			loadingStop();
			$('#' + target).html(html);
		}, srror: function () { 
			loadingStop();
			console.log('Błąd wysyłania formularza')
		}
	});
}

function showLoginViewIfLogout(fancybox) {

	if (!isAnyUserLogged()) {

		if (fancybox) {
			self.parent.document.location.href = self.parent.document.location.pathname + self.parent.document.location.search;
		}
		else {
			document.location.href = document.location.pathname + window.location.search;
		}
		return true;
	}

	return false;
}

function fancyAlert(data) {
	$.fancybox('<div class="pT15 w350 f15 bold tcenter">' + data + '</div>', {
		autoDimensions : true
	});
}

function createCookie(name, value, days) {
	if (days) {
		var date = new Date();
		date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
		var expires = "; expires=" + date.toGMTString();
	} else
		var expires = "";
	document.cookie = name + "=" + value + expires + "; path=/";
}
function eraseCookie(name) {
	createCookie(name, "", -1);
}

function loadingStop(){
	$(window).trigger('loadingStop');
}

function loadingReload() {
	loadingStart();
	document.location.reload(true);
}

function loadingStart(){
	try {
		$.fancybox.close();
	}
	catch(err) {
		console.log(err);
	}
	$('#fancybox-wrap').hide();
	$(window).trigger('loadingStart');
}

function platnosciSubmit(orderNumber, redirectUrl) {
	loadingStart();
	UA.pageview('/order-finalize-platnosci-button-clicked.bhtml');
	$.ajax({
		url : '/changePaymentStatus.ltr',
		method:'POST',
		dataType : 'json',
		async : true,
		data : {
			action : 'markPlatnosciAsProcessed',
            orderNumber : orderNumber
		},
		success : function() {
			window.location.href = redirectUrl;
		}
	});
}

function getPlatnosciForm(orderNumber) {

	loadingStart();

	$.ajax({
		url : '/orderpayment/payu/transactions.ltr',
		method: 'POST',
		async : false,
		dataType: 'json',
		contentType: 'application/json',
		data : JSON.stringify({
			orderNumber : orderNumber
		}),
		success : function(data) {
			if (data.error){
				setTimeout(function(){
					fancyOk(data.errorMessage);
				},300);
			}else{
				platnosciSubmit(orderNumber, data.redirectUrl);
			}
		},
		error : function() {
			fancyAlert('błąd');
		}
	});
}

function goToPaymentModule(orderNumber) {
	loadingStart();
	$.ajax({
		url : '/orderpayment/paymentmodule/transactions.ltr',
		method:'POST',
		dataType: 'json',
		data: {
			orderNumber : orderNumber
		},
		success : function(json) {
			if (json.error){
				fancyAlert('błąd płatności');
				console.log(json.errorMessage)
			}
			else {
				window.location.href = '/konto-platnosc.bhtml?orderNumber=' + orderNumber;
			}
		},
		error : function() {
			fancyAlert('błąd komunikacji płatności');
		}
	});
}

function getAdditionalPageContent() {
	$.ajax({
			url: '/additional-page-content.ltr',
			success: function(html) {
				$('head').append(html);
			}
	});
}

function fancyCms(linkName, width, height) {
	var href = '/cmsPageFancy.ltr?linkName=' + linkName;
	if (width && height) {
		$.fancybox({
			href : href
		}, {
			width : width,
			height : height
		});
	} else {
		$.fancybox({
			href : href
		}, {
			autoDimensions : true
		});
	}
}

function fancyCmsLayer() {
	$("a.fancyCms").each(function() {
		var href = $(this).attr('href').substr(1);
		$(this).attr('href', 'javascript:void(0)').attr('rel', href).click(function() {
			var href = '/cmsPageFancy.ltr?linkName=' + $(this).attr('rel');
			$.fancybox({
				href : href
			}, {
				autoDimensions : true
			});
		})
	});
}

var cookieEvtLog = {
	save: function(c) {
		console.log(c);
	}
}

function fancyboxEvent(options){
	$(document).trigger('fancybox-opened');
	app.logEvent({
		id:'fancybox',
		f03: document.location.href,
		f04: (options.href?options.href.replace(/\s/g, ''):'inline'),
		f05: (!options.href?$.trim($(options.content).text()).substr(0,50):'')
	});
}

function logError(element) {
	try{
		console.log(element);
	}catch(e){}
}

function logFancyDictEvent(element,t){
	//nadpisywana przez Euro
}
function loginOrRegisterFormError(loginForm){
	//nadpisywane przez Euro, loginForm=true - formularz logowania, loginForm = false - formularz Rejestracji
}

function tooltipAutoresize() {
	setTimeout(function() {
		$('.conformity-tip h3').text('');
		var div = '<div class="auto"></div>';
		if($('.conformity-tip h3 div').length < 1) {
			$('.conformity-tip h3').append(div);
		}
		if($('.conformity-tip .body div').length < 1) {
			$('.conformity-tip .body').append(div);
		}
	}, 200);
}

function logCartstepCookie(){
	//przeniesione z baneru MSI - cartstep, widok logowania - odczyt cookisa i post do shopEvent.ltr

	if(www.pageType == "accountLogin" && $.cookie('cartstepCookie')!== null && $.cookie('cartstepCookie')!== undefined ){
		var _cartNumber = $.cookie('euroCart') ? $.cookie('euroCart') : false;
		var cartStep = $.cookie('cartstepCookie').split(':');
		app.logEvent({
			id: 'cartstep',
			f03: document.location.href,
			f04: '',
			f05: cartStep[0],
			f06: cartStep[1],
			f07: cartStep[2],
			f08: cartStep[3],
			f09: cartStep[4],
			f10: cartStep[5],
			f11: _cartNumber
		});

		$.cookie('cartstepCookie',null,{path:'/login.bhtml'});
	}
}

//funkcja nadpisywana w ab_v1.js (upload module)
function executeWhen () {}

function getPageScroll() {
	var xScroll, yScroll;
	if (self.pageYOffset) {
		yScroll = self.pageYOffset;
		xScroll = self.pageXOffset;
	} else if (document.documentElement && document.documentElement.scrollTop) { // Explorer
		// 6
		// Strict
		yScroll = document.documentElement.scrollTop;
		xScroll = document.documentElement.scrollLeft;
	} else if (document.body) {// all other Explorers
		yScroll = document.body.scrollTop;
		xScroll = document.body.scrollLeft;
	}
	arrayPageScroll = new Array(xScroll, yScroll);
	return arrayPageScroll;
};

function fancyAlertForUrl(url){
	$.fancybox({href:url},{
		autoDimensions:	true
	});
}

function checkEmailNewsletter(email) {
	if (!validateEmail(email)) {
		return false;
	}
	return true;
}

function noSelect(name) {
	if ($.browser.mozilla) {
		$(name).each(function() {
			$(this).css({
				'MozUserSelect' : 'none'
			});
		});
	} else if ($.browser.msie) {
		$(name).each(function() {
			$(this).bind('selectstart.disableTextSelect', function() {
				return false;
			});
		});
	} else {
		$(name).each(function() {
			$(this).bind('mousedown.disableTextSelect', function() {
				return false;
			});
		});
	}
}

function ankietaStart(url) {
	var width = 900;
	var height = 400;
	var option = 'left=' + (screen.width - width) / 2 + ',top=' + (screen.height - height) / 2 + ',width=' + width + ',height=' + height
			+ ',toolbar=no,menubar=no,resizable=no,location=no,status=yes';
	var t = window.open(url, 'ooo', option);
	$.fancybox.close();
	return false;
}

function fancyErrorLink(name) {
	pageType=window.pageType;
	if($("#pageType").length){
		pageType=$("#pageType").val();
	}
	$(name+" a.errorLink").data('type', pageType);
}

function messageAlert(code,theme){
	$.ajax({
		url:'/message-alert.ltr',
		data:{
			code:encodeURI(code),
			theme:theme
		},
		success:function(html){
			fancyOk(html);
		}
	});
}

// pobiera parametr URL o podanej nazwie, zwraca jego wartość lub pusty string
// gdy parametr nie istnieje
function getUrlParameter(name) {
	name = name.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
	var regexS = "[\\?&]" + name + "=([^&#]*)";
	var regex = new RegExp(regexS);
	var results = regex.exec(window.location.href);
	if (results == null)
		return "";
	else
		return results[1];
}

function showInternetPrice(show, hide) {
	$('#' + hide).hide();
	$('#' + show).show();
}
