app.service('relatedKeywordsSection', function (searchBox, searchRelatedKeywordsApi, viewPoint) {

	let $self, $content;

	function init() {
		setElements();
		const keyword = searchBox.getKeyword();
		loadRelatedKeywords(keyword);
	}

	function setElements() {
		$self = $('#related-keywords');
		$content = $self.find('.section-content');
	}

	function loadRelatedKeywords(keyword) {

		searchRelatedKeywordsApi.get(keyword)
			.then(response => {
				if (response.trim() === "") {
					$self.addClass('is-hidden');
				} else {
					$content.html(response);
					setUA();
				}
			})
			.fail(() => {
				$content.text("[relatedKeywords] Błąd inicjalizacji modułu. Odśwież stronę aby spróbować ponownie.");
			})
			.always(() => {
				$self.removeClass('loading');
			});
	}

	function setUA() {
		viewPoint.set({
			element: $self,
			height: 0.6 * $self.height(),
			callback() {
				UA.searchInteraction('Zerowe wyniki wyszukiwan', 'Inni klienci wyszukiwali rowniez', 'Wyswietlenie');
			}
		});

		$self.on('click', 'a', function () {
			UA.searchInteraction('Zerowe wyniki wyszukiwan', 'Inni klienci wyszukiwali rowniez', 'Klikniecie', $(this).text().trim());
		});
	}

	return {
		init
	};
});