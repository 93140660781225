app.service('callButton', function (productCardTest, pageConfig) {

	let active = false;

	function handleClick() {

		active = !active;

		if (!productCardTest.enabled) {
			let $button = $(this);
			let $productBox = $button.closest('.product-box');

			$productBox.toggleClass('call-and-order');

			if ($productBox.hasClass('call-and-order')) {
				var data = $button.data('event').split("|");
				logEvents(data);
			}
		} else {
			let $product = $('#product-main');
			if (active && $(window).scrollTop() > 400) {
				$.scrollTo($product);
			}

			$product.toggleClass('call-and-order');

			if (active) {
				logEvents(getData());
			}
		}
	}

	function getData() {
		const product = pageConfig.getData('productCard');
		return [
			product.groupName,
			product.brand,
			product.plu,
			product.title,
			product.price,
			product.category,
			product.photo,
		];
	}

	function logEvents(data) {
		UA.pageInteraction('Zadzwoń i zamów', 'Standard');
		UA.push({
			event: 'ZadzwonZamow',
			ZZ_category: data[0],
			ZZ_brand: data[1],
			ZZ_PLU: data[2],
			ZZ_name: data[3],
			ZZ_price: data[4],
			ZZ_rootCategory: data[5],
			ZZ_foto: data[6]
		});
	}

	function setEvents() {
		$('body').on('click', '.js-call', handleClick);
	}

	function init() {
		setEvents();
	}

	return {
		init: init
	};
});