app.service('oldToCartView', function (pageConfig, cartManager, front, modal) {
    var $self, $tiles;
    var config;

    function init() {
        setElements();
        setEvents();
        showAccessories();
    }

    function setElements() {
        $self = $('#to-cart');
        $tiles = $('#suggested-accessories', $self);
        config = pageConfig.getData('precart');
    }

    function setEvents() {
        $self.on('click', '.js-add-accessory-to-cart-checkbox', function () {
            addToCartClick($(this).val(), $(this).is(':checked'));
        });
        $(document).on('cartReady', function () {
            checkCart();
        });
        $('.js-to-cart-back', $self).on('click', function () {
            history.back();
        });
        $self.on('click', '.js-more-suggested-accessories', function () {
            var id = $(this).data('id');
            modal.open('to-cart-accessories-' + id);
        });
    }

    function showAccessories() {
        var data = $.extend({
            destination: 'TO_CART',
            source: 'RECOMMENDATIONS'
        }, config);
        $.ajax({
            url: '/to-cart-accessories-list.ltr',
            data: data,
            success: function (html) {
                showAccessoriesSuccess(html);
            },
            complete: function () {
                $tiles.removeClass('loading');
            },
            error: function () {
                $tiles.remove();
            }
        });
    }

    function checkCart() {
        if (front.katalog) {
            cartManager.checkRelatedProducts(config.productId / 1);
        } else {
            cartManager.checkRelatedProducts();
        }
    }

    function addToCartClick(id, checked) {
        if (front.katalog) {
            cartManager.clickRelatedProducts(id, checked, 'TO_CART', config.productPlu, config.productId);
        } else {
            cartManager.clickRelatedProducts(id, checked, 'TO_CART', config.productPlu);
        }
    }

    function showAccessoriesSuccess(html) {
        const $groups = $('<div />').html(html).find('.group');
        $tiles.append($groups);
    }

    return {
        init: init
    };
});
