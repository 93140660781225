/**
 * Obsługa podmiany linków pod SEO
 * By uniknąć marnowania budżetu crawlowania na adresy nieindeksowalnych zasobów, 
 * URL dla atrybutu HREF ustawiany jest z parametru data-seo-url poprzez JS
 */
app.service('dataSeoUrl',function(){
	var parentsList = [];

	function replaceSeoUrl($link){
		if($link.attr('data-seo-url')){
			$link.attr('href', replace($link.data('seo-url')));
			$link.removeAttr('data-seo-url');
		}
	}
	function replace(text){
		return text.replace(/\*/g, "/").replace(/%2E/g, ".")
	}
	
	function setEvents(parent){
		if (parentsList.indexOf(parent) == -1){
			parentsList.push(parent);
			$('body').on('focus hover mouseenter', parent, function(){
				replaceSeoUrl($(this).find('a[data-seo-url]'));
			});
		}
	}

	function init() {
		$('body').on('focus hover mouseenter', 'a[data-seo-url]', function(){
			replaceSeoUrl($(this));
		});
	}
	return {
		init,
		setEvents,
		replace
	};
});
