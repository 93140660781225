app.service('nodeTree',function(front){
	var $self, $moreLink, $topMoreLink, $header;

	function setElements(){
		$self=$('#node-tree');
		$moreLink=$('.js-category-show-more');
		$header=$self.find('.js-node-tree-header');
	}
	function setEvents(){
		$moreLink.click(function(event){
			$(this).closest('ul').children('.category-hidden').removeClass('category-hidden');
			$(this).parent().remove();
			event.preventDefault();
		});
		if (front.euro) {
			$header.click(function(){
				toggle();
			});
		}
		$self.on('click', '.js-collapsed-category', function(e) {
			if($(this).hasClass('collapsed-category')){
				$(this).removeClass('collapsed-category');
				e.preventDefault();
			}
		});
	}
	function toggle(){
		$self.toggleClass('node-uncollapsed');
	}
	function init(){
		setElements();
		setEvents();
	}
	return {
		init:init
	};
});
