app.service('recommendedProductsSection', function (developSearchModeTest, lazyLoad, searchBox, viewPoint) {

	let $self, $content, $recommendedProductsList;

	function init() {
		setElements();
		loadRecommendedProducts().then(response => {

			if (response.isEmpty) {
				$self.addClass('is-hidden');
				return;
			}

			$recommendedProductsList.html(response.html);

			new PreviewGallery({
				selector: $recommendedProductsList,
				perPage: {
					0: 4.5,
					1231: 6
				},
				navigation: $content.find(".recommended-products-list-nav"),
				onInit() {
					lazyLoad.update();
				},
				onResize() {
					lazyLoad.update();
				},
				onChange() {
					lazyLoad.update();
				}
			});

			setUA();
		});
	}

	function setElements() {
		$self = $('#recommended-products');
		$content = $self.find('.section-content');
		$recommendedProductsList = $self.find('.recommended-products-list');
	}

	function loadRecommendedProducts() {
		return $.ajax({
			url: '/web-cached/search-suggestions/recommended-products.ltr',
			data: {
				developSearchMode: developSearchModeTest.isEnabled(),
				keyword: searchBox.getKeyword()
			}
		}).then(response => ({
			isEmpty: response.trim() === "",
			html: response
		})).fail(() => {
			$content.text("[recommendedProductsSection] Błąd inicjalizacji modułu. Odśwież stronę aby spróbować ponownie.");
		}).always(() => {
			$self.removeClass('loading');
		});
	}

	function setUA() {
		viewPoint.set({
			element: $self,
			height: 0.6 * $self.height(),
			callback() {
				UA.searchInteraction('Zerowe wyniki wyszukiwan', 'Polecane produkty', 'Wyswietlenie');
			}
		});

		$recommendedProductsList.on('click', 'a', function () {
			const productCode = $(this).closest('.product-carousel-item').find('.productPlu').val();
			UA.searchInteraction('Zerowe wyniki wyszukiwan', 'Polecane produkty', 'Klikniecie', productCode);
		});
	}

	return {
		init
	};
});