app.service('socialBox',function(){
	var $self, $fb, $pin, $tw, $gplus;
	function setElements(){
		$self = $('.social-box');	//może być kilka na stronie
		$fb = $('.social-fb', $self);
		$pin = $('.social-pin', $self);
		$tw = $('.social-tw', $self);
		$gplus = $('.social-gplus', $self);
	}
	function clickUA(name){
		UA.interaction('Social','Facebook', document.location.href);
	}
	function setEvents(){
		$fb.click(function(){
			clickUA('Facebook');
		});
		$pin.click(function(){
			clickUA('Pinterest');
		});
		$tw.click(function(){
			clickUA('Twitter');
		});
		$gplus.click(function(){
			clickUA('Google+');
		});
	}

	function prepareLinks(type){
		var imgUrl, dscr;
		if(type=='product'){
			imgUrl = $('#big-photo img').attr('src');
			dscr   = $('.product-header h1').text();
		}else if(type=='compare'){
			dscr = $('.products-photos img').attr('alt');
			imgUrl = $('.products-photos img').attr('src');
		}
		var fbUrl  = 'http://www.facebook.com/sharer.php?utm_content=www&u=' + encodedUrl('utm_source=share&utm_medium=facebook&utm_content=www');
		$fb.attr('href',fbUrl);
		var pinUrl = 'http://www.pinterest.com/pin/create/button/?media='+encodeURI(imgUrl)+'&description='+dscr+'&url=' + encodedUrl('utm_source=share&utm_medium=pinterest&utm_content=www');
		$pin.attr('href',pinUrl);
		var twUrl  = 'https://twitter.com/intent/tweet?text='+dscr+'&url=' + encodedUrl('utm_source=share&utm_medium=twitter&utm_content=www');
		$tw.attr('href',twUrl);
		var gpUrl  = 'https://plusone.google.com/_/+1/confirm?hl=pl&url=' + encodedUrl('utm_source=share&utm_medium=google&utm_content=www');
		$gplus.attr('href',gpUrl);
	}

	function encodedUrl(params) {

		var url = window.location.href;
		if (params) {
			if (url.indexOf('?') > -1) {
				url = url + '&' + params;
			} else {
				url = url + '?' + params;
			}
		}

		return encodeURIComponent(url);
	}

	function init(type) {
		setElements();
		setEvents();
		prepareLinks(type);
	}
	return {
		init:init
	};
});
