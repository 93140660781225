app.service('ePaymentBlikModal', function (console, loading, modal, front) {
	let config = {}, isBlikModalInit = false, isBlikModalOpen = false;
	let isTransactionsStatusChecking = false, confirmationAwaitTimeout, statusTimeout; 
	let forceGoToPaymentModule = false;
	let confirmationAwaitTimer = 3; // Przekroczona standardowa "żywotność" kodu Blik (standardowo do 2 minut, więc ustawione 3 minuty z zapasem na zwłokę komunikacyjną)
	let $self, $blikInput, $blikInputs, $blikForm, $blikPaymentStartButton, $blikPaymentModalOpenButton;
	// modul wykorzystywany w widokach 'order finalize view' oraz 'customer'
	// widoki powinny posiadac modale o nazwie modal-EPayment-blik dostepny w pliku modal-epayment-blik.jsp (include)

	function setConfig(options){
		if(options.paymentType === 'Platnosci' || options.paymentType === 'payu'){
			config.paymentOperator = 'payu';
		}else if(options.paymentType === 'MPC'){
			config.paymentOperator = 'MPC';
		} else {
			config.paymentOperator = 'tpay';
		}
		config.orderNumber = options.orderNumber;
		if (options.callback) {
			config.callback = options.callback;
		}
	}

	function resetBlikForm() {
		$blikInput.val('');
		$blikInputs.val('');
		$blikInputs.first().focus();
		$blikPaymentStartButton.addClass('button-disabled');
		$blikForm.removeClass('show-blik-error');
	}

	function clearAllTimeouts(){
		isTransactionsStatusChecking = false;
		clearTimeout(confirmationAwaitTimeout);
		clearTimeout(statusTimeout);
	}

	function openBlikModal() {
		const $modalBlik = modal.open('EPayment-blik',{
			closeOnEscape: false,
			closeOnOutsideClick: false,
		});
		$blikInputs.first().focus();
		if(!isBlikModalOpen) {
			isBlikModalOpen = true;
			modal.callbackClose($modalBlik, function(){
				resetBlikForm();
			});
		}
	}
	function openBlikConfirmationModal(totalPaymentValue) {
		const $blikConfirmationPrice = $('#blik-confirmation-price');
		if($blikConfirmationPrice.length) {
			totalPaymentValue ? $blikConfirmationPrice.text(`Do zapłaty: ${totalPaymentValue}`) : $blikConfirmationPrice.text('');
		}
		modal.open('EPayment-blik-confirm',{
			closeOnEscape: false,
			closeOnOutsideClick: false,
		});
	}
	function openBlikSuccessModal() {
		const $modalBlikSuccess = modal.open('EPayment-blik-success',{
			closeOnEscape: false,
			closeOnOutsideClick: false,
		});
		modal.callbackClose($modalBlikSuccess, function(){
			loading.reload();
		});
	}

	function load(paymentType, orderNumber, callback) {
		setConfig({
			paymentType,
			orderNumber,
			callback,
		});
		if (forceGoToPaymentModule) {
			paymentModuleRedirect();
		} else {
			openBlikModal();
		}
	}

	function createBlikTransaction(paymentOperator, orderNumber, blikCode) {
		loading.start();
		$.ajax({
			url: '/orderpayment/'+paymentOperator+'/transactions.ltr',
			method: 'POST',
			dataType: 'json',
			contentType: 'application/json',
			data: JSON.stringify({
				orderNumber : orderNumber,
				blikCode : blikCode
			}),
			success: function (json) {
				if (json.error) {
					if (json.repeatedPaymentInPaymentModule) {
						paymentModuleErrorModal(json.errorMessage);
					} else {
						onBlikError(json.errorMessage);
					}
				} else {
					loading.stop();
					const totalPaymentValue = json.worth ? (parseFloat(json.worth).toFixed(2) + ' zł').replace('.',',') : false;
					openBlikConfirmationModal(totalPaymentValue) // otwarcie modala z informacja o wymaganym potwierdzeniu
					if (!isTransactionsStatusChecking) {
						isTransactionsStatusChecking = true;
						processBlikTransactionCreated(paymentOperator, orderNumber); // rozpoczęcie odpytywania
						confirmationAwaitTimeout = setTimeout(function(){ // brak potwierdzenia w czasie standardowej "żywotności" kodu Blik 
							clearTimeout(statusTimeout);
							modal.alert('Transakcja nie została potwierdzona przez operatora płatności. Prosimy o sprawdzenie statusu płatności w koncie klienta.', function(){
								loading.reload();
							});
						}, confirmationAwaitTimer*60*1000);
					}
				}
			},
			error: function () {
				modal.alert('Błąd pobierania danych');
			}
		});
	}

	function processBlikTransactionCreated(paymentOperator, orderNumber) {
		if (isTransactionsStatusChecking) {
			$.ajax({
				url: '/orderpayment/'+paymentOperator+'/transactions/status.ltr',
				method: 'GET',
				dataType: 'json',
				data: {
					orderNumber: orderNumber
				},
				success: function (json) {
					if(json.closed) { //potwierdzony status platnosci
						modal.close();
						clearAllTimeouts();
						openBlikSuccessModal();
					} else if (json.failed) { // błąd podczas platnosci - restart platnosci
						resetBlikTransaction(paymentOperator, orderNumber, json.errorMessage, json.repeatedPaymentInPaymentModule);
					} else {
						statusTimeout = setTimeout(function(){
							processBlikTransactionCreated(paymentOperator, orderNumber);
						}, 2000);
					}
				},
				error: function () {
					modal.alert('Błąd pobierania danych podczas sprawdzania statusu transakcji');
				}
			});
		}
	}

	function paymentModuleErrorModal(errorMessage) {
		loading.stop();
		forceGoToPaymentModule = true;
		clearTimeout(confirmationAwaitTimeout);
		$('.blik-error-message', $blikForm).text(errorMessage);
		$('.js-blik-payment').remove();
		$blikInputs.prop('disabled', true);
		$('.js-go-to-payment-module').removeClass('hide');
		openBlikModal();
	}

	function resetBlikTransaction(paymentOperator, orderNumber, errorMessage, repeatedPaymentInPaymentModule){
		clearAllTimeouts();
		$.ajax({
			url: '/orderpayment/'+paymentOperator+'/transactions/reset.ltr',
			method: 'POST',
			dataType: 'json',
			data: {
				orderNumber : orderNumber
			},
			success: function () {
				if (repeatedPaymentInPaymentModule) {
					paymentModuleErrorModal(errorMessage);
				} else {
					onBlikError(errorMessage);
				}
			},
			error: function () {
				modal.alert('Błąd pobierania danych podczas resetowania procesu transakcji');
			}
		});
	}

	function onBlikError(message) {
		loading.stop();
		if (message) {
			$('.blik-error-message', $blikForm).text($.trim(message));
			$blikForm.addClass('show-blik-error');
		} 
		openBlikModal();
	}

	function paymentModuleRedirect() {
		loading.redirect(front.page() == 'finalize' ? '/order-payment-module.bhtml' : '/konto-platnosc.bhtml?orderNumber=' + config.orderNumber);
	}

	function goToPaymentModule() {
		loading.start();
		$.ajax({
			url: '/orderpayment/paymentmodule/transactions.ltr',
			method: 'POST',
			dataType: 'json',
			data: {
				orderNumber: config.orderNumber
			},
			success: function (json) {
				if (json.error) {
					modal.alert('błąd płatności');
					console.log(json.errorMessage);
				}
				else {
					paymentModuleRedirect();
				}
			},
			error: function () {
				loading.stop();
				modal.alert("Nieudana próba. Spróbuj ponownie później");
			}
		});
	}

	function setElements(){
		$self = $('#modal-EPayment-blik');
		$blikForm = $('.blik-form', $self);
		$blikInput = $('#blik-code-input', $self);
		$blikInputs = $('#blik-code-inputs input', $self);
		$blikPaymentStartButton = $('.js-blik-payment', $self);
		$blikPaymentModalOpenButton = $('.js-blik-payment-modal-open');
	}

	function setEvents(){
		if(!isBlikModalInit && $self.length){
			isBlikModalInit = true;

			$blikInputs.keyfilter(/[\d]/).on('focus', function(){
				const $this = $(this);
				if ($this.val() != '') { // zaznaczanie zawartosci jeśli pole nie jest puste
					$this.select();
				}
			}).on('input', function(){
				const $input = $(this);
				const $nextInput = $input.next();
				if ($nextInput.length && $input.val()) {
					$nextInput.focus();
				}
			}).on('paste', function(event){
				event.stopPropagation();
				event.preventDefault();
				const pasteData = event.originalEvent.clipboardData.getData('text/plain').replace(/\D/g,''); // kopiuje tylko cyfry
				if (pasteData.length == 6) {
					$blikInputs.each(function(i, input) { 
						$(input).val(pasteData[i]); // rozpoczyna uzupełnianie zawsze od pierwszego pola
						$(input).trigger('change'); // przy wklejaniu treści z menu kontekstowego
					});
				} else {
					$('.blik-error-message', $blikForm).text('Wstawiany kod BLIK jest niepoprawny.');
					$blikForm.addClass('show-blik-error');
				}
			}).on('keypress keyup change', function(){
				let blikValue = '';
				$blikInputs.each(function(i,input){
					blikValue += input.value;
				});
				$blikInput.val(blikValue);
				if(blikValue.length > 0 && $blikForm.hasClass('show-blik-error')) {
					$blikForm.removeClass('show-blik-error');
				}
				// odblokowanie przycisku jeśli wszystkie 6 cyfr jest wypełnionych
				blikValue.length == 6 ? $blikPaymentStartButton.removeClass('button-disabled') : $blikPaymentStartButton.addClass('button-disabled');
			}).on('keydown', function(e) { // obsługa backspace'a
				if ((e.keyCode == 8 || e.keyCode == 46) && $(this).val() =='') {
					$(this).prev('input').focus();
				}
			});

			$('.js-go-to-payment-module').on('click', function () {
				goToPaymentModule();
			});

			$blikPaymentStartButton.on('click', function() {
				if($blikInput.val().length !== 6){
					$('.blik-error-message', $blikForm).text('Wpisany kod BLIK jest za krótki.');
					$blikForm.addClass('show-blik-error');
				}else{
					config.blikCode = $blikInput.val();
					modal.close('EPayment-blik');
					resetBlikForm();
					if (config.paymentOperator === 'MPC' && config.callback){
						config.callback(config.blikCode);
					} else {
						createBlikTransaction(config.paymentOperator, config.orderNumber, config.blikCode);
					}
				}
			});
		}

		if($blikPaymentModalOpenButton.length) {
			$blikPaymentModalOpenButton.on('click', function(){
				load($(this).data('paymentOperator'), $(this).data('orderNumber')*1);
			})
		}
	}
	function init(){
		console.log('INIT ePaymentBlikModal');
		setElements();
		setEvents();
	}

	return {
		init,
		load
	};
});
