app.service('autocompleteInfo', function (callback, localStorage, console, logData){
	var data={};
	function setData(params){
		if(params){
			$.extend(data, params);
		}
		data.keyword = $('#keyword').val();
		data.productCategory = $('#search-category').val();
		data.productCategoryText = $('#search-box .select3-selected-item').text().toLowerCase();
	}
	function logClick(){
		if(data.type == 'more-products'){
			UA.interaction('Wyszukiwarka | Wiecej produktow', data.category.fullText+'|'+ data.category.position, data.keyword);
		}else if(data.type == 'product') {
			UA.interaction('Wyszukiwarka | Popularne produkty', data.category.fullText+'|'+ data.category.position, data.keyword);
			UA.push({
				event: 'productClick',
				ecommerce: {
					click: {
						actionField: {
							'list': 'Wyszukiwarka/Polecane produkty'
						},
						products: [data.product]
					}
				}
			});
		}else if(data.type == 'category') {
			UA.interaction('Wyszukiwarka | Lista podpowiedzi', data.category.fullText+'|'+ data.category.position, data.keyword);
		}else if(data.type == 'suggestion') {
			UA.interaction('Wyszukiwarka | Popularne hasla', data.category.fullText+'|'+ data.category.position, data.keyword);
		}
		localStorage.setItem('autocompleteInfo', JSON.stringify(data));
	}
	function logDataFunction(params){
		setData(params);
		logClick();
		UA.getPush();
		callback.run('getAutocompleteInfo', $.extend({}, data));
	}
	function logResult(page, config) {
		var localData = localStorage.getItem('autocompleteInfo');
		var f10 = $('#search-box .select3-selected-item').text();
		if (localData && localData.length) {
			localData = JSON.parse(localStorage.getItem('autocompleteInfo')) || {};
			let data = {
				id: 'new-search',
				f03: localData.keyword.toLowerCase(),
				f04: $('#keyword').val(),
				f05: '',
				f06: '',
				f07: localData.count ? localData.count : '',
				f08: '',
				f09: 0,
				f10: f10 && f10 !== 'wszystkie' ? f10 : '',
				f11: document.location.href,
				f12: ''
			}
			if (page == 'listing') {
				data.f09 = config.count;
				if (config.keyword && config.keyword.length) {
					data.f04 = config.keyword;
				}
			} else if (page == 'product') {
				data.f09 = 1;
				data.f04 = config.title;
			} else if (page == 'brand') {
				data.f09 = config.count
			}
			if (localData.type == 'button') {
				data.f05 = 'SAMODZIELNIE';
				data.f08 = 'lupka';
			} else if (localData.type == 'enter') {
				data.f05 = 'SAMODZIELNIE';
				data.f08 = 'Enter';
			} else if (localData.type == 'suggestion') {
				data.f05 = 'ACF';
				data.f06 = 'standard: ' + localData.category.position + '/' + localData.suggestionsLength;
				//data.f06 = localData.category.position + localData.categoryLength;
				//data.f07 = localData.suggestionsLength;
				data.f08 = "Click";
				data.f12 = localData.category.text;
			} else if (localData.type == 'history') {
				data.f05 = 'ACH';
				data.f06 = 'history: ' + localData.category.position + '/' + localData.historyLength;
				//data.f06 = localData.category.position;
				data.f07 = localData.historyLength;
				data.f08 = "Click";
				data.f12 = localData.category.text;
			} else if (localData.type == 'product') {
				data.f05 = 'ACF_P';
				data.f06 = 'recommended: ' + localData.product.position + '/' + 5;
				//data.f06 = localData.product.position;
				data.f08 = "Click";
			} else if (localData.type == 'category') {
				data.f05 = 'ACFC';
				data.f06 = 'popular: ' + localData.category.position + '/' + localData.categoryLength;
				//data.f06 = localData.category.position;
				//data.f07 = localData.categoryLength;
				data.f08 = "Click";
				data.f12 = localData.category.text;
			} else if (localData.type == 'more-products') {
				data.f05 = 'MORE';
				data.f08 = "Click";
			}
			console.log(localData);
			app.logEvent(data);
			UA.push({
				event: "new_search",
				search_F01: logData.customerId(),
				search_F02: logData.visitId(),
				search_F03: data.f03,
				search_F04: data.f04,
				search_F05: data.f05,
				search_F06: data.f06,
				search_F07: data.f07,
				search_F08: data.f08,
				search_F09: data.f09,
				search_F10: data.f10,
				search_F11: data.f11,
				search_F12: data.f12
			});
		}
		localStorage.removeItem('autocompleteInfo');
	}
	
	return {
		setData,
		logData: logDataFunction,
		logResult
	};
});
