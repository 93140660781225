/**
 * Moduł obsługujący modal do zgłaszania błędu m.in. na karcie produktu
 */

app.service('reportBug',function(modal, front, cookie, loading, validate, account, console, html, captchaService) {
	var $self, $modal;
	var params, type;

	function closeCallback(){
		html.remove('remodal-bottom-view');
	}

	function loadSuccess(){
		$self=$('#modal-error');
		validate.task('errorForm',function(form){
			send(form);
		});
		var userInfo=account.user();
		if(userInfo.email){
			$('#error-email').val(userInfo.email);
		}
		$('.select3-container input', $self).filter('.select3-input').select3();
		addErrorWarningCaptcha();
	}
	function addErrorWarningCaptcha() {
		let $captcha = $self.find('[data-toggle="captcha"]');
		if ($captcha.length) {
			captchaService.load($captcha.attr('id'), $captcha.data('place'));
		}
	}
	function load(type){
		loading.start();
		$.ajax({
			url:'/errorWarning.ltr',
			data:{
				type:type
			},
			success:function(content){
				$modal=modal.generate({
					id:'modal-error',
					content: content,
					modifier: 'remodal-bottom',
					callbackOpen:function(){
						loadSuccess();
					},
					callbackClose:function(){
						closeCallback();
					}
				});
			},
			complete:function(){
				loading.stop();
			},
			error:function(){
				modal.alert('Błąd ładowania warstwy do zgłaszania błędów');
			}
		});
	}
	function send($form){
		$form.addClass('loading');
		$form.serializeArray().forEach(element => {
			params[element.name] = element.value;
		});
		$.ajax({
			type: 'POST',
			data: params,
			url: '/errorWarningLogger.ltr',
			success:function(json){
				if(json.status == 'SUCCESS') {
					modal.alert('Dziękujemy za Twoje zgłoszenie');
				}else {
					modal.alert(json.messages, function(){
						$modal.open();
					});
				}
			},error:function(){
				modal.alert('Wystapił błąd wysyłania formularza. Prosimy spróbować później');
			},complete:function(){
				$form.removeClass('loading');
			}
		});
	}
	function prepareData(type){
		var data={
			currentUrl:encodeURIComponent(document.location.href),
			userAgent:navigator.userAgent
		};
		if(type=='PRODUCT_CARD'){
			$.extend(true,data,{
				subject:$.trim($('h1:first').text()),
				code:$('#product-top').data('plu')
			});
		}else if(type=='CART'){
			$.extend(true,data,{
				type:'CART',
				subject:'Ścieżka zakupowa'
			});
		}else if(type=='CUSTOMER_ACCOUNT'){
			$.extend(true,data,{
				subject:'Konto Klienta'
			});
		}else{
			$.extend(true,data,{
				subject:$.trim($('h1:first').text())
			});
		}
		params = data;
	}
	function show(type){
		prepareData(type);
		if(type){
			load(type);
		}else{
			modal.alert('Brak możliwości raportowania błędu na tej stronie');
		}
	}
	function setEvents(){
		$('body').on('click','.js-report-bug, .errorLink',function(){
			show($(this).data('type') || undefined);
		});
	}
	function init(){
		setEvents();
	}
	return {
		init:init
	};
});
